import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ExportIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 17, height: 18, ...sx }}
      component="svg"
      viewBox="0 0 17 18"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M16.346 3.667V2.333c0-.608-.222-1.189-.609-1.618A2.107 2.107 0 0 0 14.167 0H2.18C1.59 0 1.025.252.61.715.221 1.145 0 1.725 0 2.333v13.334c0 .608.222 1.189.61 1.618.415.463.981.715 1.57.715h11.987c.588 0 1.154-.252 1.57-.715.387-.43.61-1.01.61-1.618v-1.334c0-.552-.44-1-.981-1a.991.991 0 0 0-.981 1v1.334c0 .1-.03.197-.093.266-.034.039-.077.067-.125.067H2.18c-.049 0-.092-.028-.126-.067a.396.396 0 0 1-.092-.266V2.333c0-.1.03-.197.092-.266.034-.039.078-.067.126-.067h11.987c.048 0 .091.028.125.067.063.069.093.166.093.266v1.334c0 .552.44 1 .98 1 .542 0 .981-.448.981-1z" />
        <path d="M15.366 8h-8.5a.991.991 0 0 0-.981 1c0 .552.44 1 .98 1h8.5c.542 0 .981-.448.981-1s-.439-1-.98-1z" />
        <path d="m14.097 12.373 2.616-2.666a1.013 1.013 0 0 0 0-1.414l-2.616-2.666a.966.966 0 0 0-1.386 0 1.013 1.013 0 0 0 0 1.413L14.632 9l-1.921 1.96a1.013 1.013 0 0 0 0 1.413.966.966 0 0 0 1.386 0z" />
      </g>
    </Box>
  );
}

ExportIcon.defaultProps = {
  sx: undefined,
};
