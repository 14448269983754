/* eslint-disable no-console */
type ClientLogLevel = "INFO" | "WARN" | "ERROR" | "DEBUG";

export default class Logger {
  static verbose = false;

  static error = (message: unknown, ...optionalParams: unknown[]): void => {
    Logger.log("ERROR", message, optionalParams);
  };

  static warn = (message: unknown, ...optionalParams: unknown[]): void => {
    Logger.log("WARN", message, optionalParams);
  };

  static info = (message: unknown, ...optionalParams: unknown[]): void => {
    Logger.log("INFO", message, optionalParams);
  };

  static debug = (message: unknown, ...optionalParams: unknown[]): void => {
    Logger.log("DEBUG", message, optionalParams);
  };

  private static log = (
    level: ClientLogLevel,
    message: unknown,
    optionalParams: unknown[]
  ): void => {
    switch (level) {
      case "ERROR":
        console.error(message, optionalParams);
        break;
      case "WARN":
        console.warn(message, optionalParams);
        break;
      case "DEBUG":
        console.debug(message, optionalParams);
        break;
      case "INFO":
      default:
        console.log(message, optionalParams);
        break;
    }
  };
}
