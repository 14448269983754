import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ZensusRasterSmallIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 23, height: 23, ...sx }}
      component="svg"
      viewBox="0 0 23 23"
    >
      <path
        d="M21.4 7.1a1.1 1.1 0 0 0 0-2.2h-3.3V1.6a1.1 1.1 0 0 0-2.2 0v3.3h-3.3V1.6a1.1 1.1 0 1 0-2.2 0v3.3H7.1V1.6a1.1 1.1 0 0 0-2.2 0v3.3H1.6a1.1 1.1 0 0 0 0 2.2h3.3v3.3H1.6a1.1 1.1 0 1 0 0 2.2h3.3v3.3H1.6a1.1 1.1 0 0 0 0 2.2h3.3v3.3a1.1 1.1 0 0 0 2.2 0v-3.3h3.3v3.3a1.1 1.1 0 1 0 2.2 0v-3.3h3.3v3.3a1.1 1.1 0 0 0 2.2 0v-3.3h3.3a1.1 1.1 0 0 0 0-2.2h-3.3v-3.3h3.3a1.1 1.1 0 1 0 0-2.2h-3.3V7.1h3.3zm-11 8.8H7.1v-3.3h3.3v3.3zm0-5.5H7.1V7.1h3.3v3.3zm5.5 5.5h-3.3v-3.3h3.3v3.3zm0-5.5h-3.3V7.1h3.3v3.3z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

ZensusRasterSmallIcon.defaultProps = {
  sx: undefined,
};
