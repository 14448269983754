import { LayerId } from "../../../../store/slices/layers/types";
import HeatmapController from "./HeatmapController";
import PoiController from "./PoiController";

export default class ControllerFactory {
  static createController(layerId: LayerId, id: string): React.ReactElement {
    switch (id) {
      case "HeatmapController":
        return <HeatmapController layerId={layerId} />;
      case "PoiController":
        return <PoiController layerId={layerId} />;
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  }
}
