import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { MapConfigId } from "../../../../store/slices/maps/types";
import Toggle3DMode from "./Toggle3DMode";
import LocationSearch from "./LocationSearch";
import LocationCenterButton from "./LocationCenterButton";
import ZoomControls from "./ZoomControls";

interface NavigationControlsProps {
  sx?: SxProps<Theme>;
  mapConfigId: MapConfigId;
}

export default function NavigationControls({
  sx,
  mapConfigId,
}: NavigationControlsProps): React.ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(2),
        ...sx,
      }}
    >
      <LocationSearch mapConfigId={mapConfigId} />
      <Toggle3DMode mapConfigId={mapConfigId} />
      <LocationCenterButton mapConfigId={mapConfigId} />
      <ZoomControls mapConfigId={mapConfigId} />
    </Box>
  );
}

NavigationControls.defaultProps = {
  sx: undefined,
};
