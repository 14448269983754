import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import SelectInput from "../../components/custom/SelectInput";
import FileUploadButton from "../../components/custom/buttons/FileUploadButton";
import ApiContext from "../../tech/context/ApiContext";
import BackdropContext from "../../tech/context/BackdropContext";
import BasicLayout from "../../components/layout/BaseLayout";
import Logger from "../../tech/utils/Logger";
import { CommitCreationDTO, DataSource } from "../../api/versioning/types";
import { postCreateNewCommit } from "../../api/versioning/versioning-api";

export default function ChangeAddPage(): React.ReactElement {
  const { fetchDatabaseData, dataSources } = useContext(ApiContext);
  const { setBackdropActive } = useContext(BackdropContext);
  const [dataSourcesCreatedBy, setDataSourcesCreatedBy] = useState<string[]>(
    []
  );
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [format, setFormat] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");
  const [latitude, setLatitude] = useState<number>(50.110365);
  const [longitude, setLongitude] = useState<number>(8.676411);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    const listOfCreatedBy = dataSources
      ? dataSources.map((dataSource) => dataSource.createdBy)
      : [];
    setDataSourcesCreatedBy(listOfCreatedBy);
  }, [dataSources]);

  const handleChangeTitle = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setTitle(String(event.target.value));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setDescription(String(event.target.value));
  };

  const handleChangeType = (event: SelectChangeEvent<string>): void => {
    setType(event.target.value);
  };

  const handleChangeCreatedBy = (event: SelectChangeEvent<string>): void => {
    setCreatedBy(event.target.value);
    const dataSource: DataSource | undefined = dataSources.find(
      (element) => element.createdBy === String(event.target.value)
    );
    if (dataSource) {
      setFormat(dataSource.format);
    } else {
      Logger.error("Error: DataSource is undefinded, null or empty");
    }
  };

  const handleChangeLatitude = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setLatitude(Number(event.target.value));
  };

  const handleChangeLongitude = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setLongitude(Number(event.target.value));
  };

  const createNewCommit = (): void => {
    setBackdropActive(true);
    const commitCreationDTO: CommitCreationDTO = {
      title,
      description,
      type,
      format,
      createdBy,
      latitude,
      longitude,
    };

    if (file) {
      postCreateNewCommit(commitCreationDTO, file)
        .then(() => fetchDatabaseData())
        .then(() => {
          setBackdropActive(false);
        })
        .catch((error) => {
          Logger.error(error);
        });
    } else {
      Logger.error("File is undefinded, null or empty");
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1">
            Änderung hinzufügen
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ minWidth: "100%" }}
            label="Titel"
            value={title}
            onChange={handleChangeTitle}
            required
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            sx={{ minWidth: "100%" }}
            label="Kategorie *"
            value={type}
            menuItems={["Verkehrsdaten", "Radwege", "Schnellstraßen"]}
            handleChange={handleChangeType}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            sx={{ minWidth: "100%" }}
            label="Quelle *"
            value={createdBy}
            menuItems={dataSourcesCreatedBy}
            handleChange={handleChangeCreatedBy}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ minWidth: "100%" }}
            label="Format"
            value={format}
            required
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ minWidth: "100%" }}
            label="Latitude"
            value={latitude}
            onChange={handleChangeLatitude}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ minWidth: "100%" }}
            label="Longitude"
            value={longitude}
            onChange={handleChangeLongitude}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ minWidth: "100%" }}
            label="Beschreibung"
            value={description}
            onChange={handleChangeDescription}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton file={file} setFile={setFile} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          item
          xs={12}
        >
          <Button
            disabled={
              !title ||
              !description ||
              !type ||
              !format ||
              !createdBy ||
              !latitude ||
              !longitude ||
              !file
            }
            onClick={createNewCommit}
          >
            Änderung hinzufügen
          </Button>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}
