import React, { useEffect, useState } from "react";
import ApiContext from "./ApiContext";
import { ContextProviderProps } from "./types";
import {
  getCommits,
  getMerges,
  getBranches,
  getRules,
  getDataSources,
} from "../../api/versioning/versioning-api";
import {
  Branch,
  Commit,
  Merge,
  Rule,
  DataSource,
} from "../../api/versioning/types";

const dataFetchIntervalMS = Number(
  process.env.REACT_APP_DATA_FETCH_INTERVAL_MS || 60000
);

export default function ApiContextProvider({
  children,
}: ContextProviderProps): React.ReactElement {
  const [commits, setCommits] = useState<Commit[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [merges, setMerges] = useState<Merge[]>([]);
  const [masterBranch, setMasterBranch] = useState<Branch>();
  const [exportCommits, setExportCommits] = useState<Commit[]>([]);
  const [rules, setRules] = useState<Rule[]>([]);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);

  const updateCommits = (updatedCommits: Commit[]): void => {
    setCommits(updatedCommits);
    const filteredCommits = updatedCommits
      .filter((commit) => commit.pushedAt !== null)
      .map((item) => ({ ...item, activated: true }));
    setExportCommits(filteredCommits);
  };

  const updateMerges = (updatedMerges: Merge[]): void => {
    setMerges(updatedMerges);
  };

  const updateBranches = (updatedBranches: Branch[]): void => {
    setBranches(updatedBranches);
    const filteredBranches = updatedBranches.filter(
      (branch: Branch) => branch.name === "master"
    );
    if (filteredBranches.length > 0) {
      setMasterBranch(filteredBranches[0]);
    }
  };

  const updateRules = (updatedRules: Rule[]): void => {
    setRules(updatedRules);
  };

  const updateDataSources = (updatedDataSources: DataSource[]): void => {
    setDataSources(updatedDataSources);
  };

  const fetchDatabaseData = (): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      Promise.all([
        getCommits(),
        getMerges(),
        getBranches(),
        getRules(),
        getDataSources(),
      ])
        .then((responses) => {
          updateCommits(responses[0].data.commits);
          updateMerges(responses[1].data.merges);
          updateBranches(responses[2].data.branches);
          updateRules(responses[3].data.rules);
          updateDataSources(responses[4].data.dataSources);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

  useEffect(() => {
    const fetchDataPeriodically = (): void => {
      fetchDatabaseData();
      setTimeout(fetchDataPeriodically, dataFetchIntervalMS);
    };

    fetchDataPeriodically();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApiContext.Provider
      // TODO: memoize context
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        commits,
        setCommits,
        branches,
        setBranches,
        merges,
        setMerges,
        masterBranch,
        setMasterBranch,
        exportCommits,
        setExportCommits,
        rules,
        setRules,
        dataSources,
        setDataSources,
        fetchDatabaseData,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
