import { Box } from "@mui/material";
import { IconProps } from "./types";

export function TrafficOccupancyDailyIcon({
  sx,
}: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 22, height: 20, ...sx }}
      component="svg"
      viewBox="0 0 22 20"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M7.318 13.21v-.397c0-1.007.81-1.826 1.806-1.826h.093l.231-.726H2.964c-.092 0-.185-.094-.139-.187l.672-2.107a.82.82 0 0 1 .787-.586h5.072c.347 0 .671.235.787.586l.208.655a3.734 3.734 0 0 1 1.205-.796l-.093-.28A2.192 2.192 0 0 0 9.356 6H4.26a2.22 2.22 0 0 0-2.107 1.545l-.58 1.803h-.88A.686.686 0 0 0 0 10.05v.398c0 .398.301.703.695.703H.95a9.517 9.517 0 0 0-.209 1.99v3.418c0 .398.301.702.695.702h1.158a.686.686 0 0 0 .695-.702v-1.1h4.77c0-.258.023-.492.046-.75a1.776 1.776 0 0 1-.787-1.498zm-3.034.328H2.872a.442.442 0 0 1-.44-.445v-.678c0-.258.231-.469.51-.422l1.32.21a.396.396 0 0 1 .346.329l.093.468a.43.43 0 0 1-.417.538z" />
        <path d="M21.305 12.11h-.88l-.579-1.802a2.192 2.192 0 0 0-2.107-1.546h-5.072a2.22 2.22 0 0 0-2.107 1.546L9.98 12.11h-.88a.686.686 0 0 0-.695.703v.398c0 .398.301.702.695.702h.255a9.517 9.517 0 0 0-.209 1.99v3.395c0 .398.301.702.695.702H11a.686.686 0 0 0 .695-.702V18.22h7.017v1.077c0 .398.3.702.694.702h1.158a.686.686 0 0 0 .695-.702v-3.395c0-.679-.07-1.335-.209-1.99h.255A.686.686 0 0 0 22 13.21v-.398a.686.686 0 0 0-.695-.703zm-10.073.726.671-2.107a.82.82 0 0 1 .787-.585h5.072c.347 0 .672.234.787.585l.672 2.107c.023.093-.047.187-.14.187h-7.71c-.116 0-.186-.093-.14-.187zm1.458 3.441h-1.412a.442.442 0 0 1-.44-.445v-.678c0-.258.231-.469.486-.422l1.32.211a.396.396 0 0 1 .348.328l.092.468a.406.406 0 0 1-.394.538zm6.878-.421a.442.442 0 0 1-.44.445h-1.412c-.278 0-.487-.258-.417-.515l.092-.468c.047-.164.186-.305.348-.328l1.32-.21c.255-.048.486.163.486.42v.656h.023zM21.526.885C21.41.372 20.93 0 20.384 0a1.23 1.23 0 0 0-.246.025c-.63.129-1.033.724-.898 1.328.05.23.176.43.346.582l-.988 1.923a1.22 1.22 0 0 0-.457.09l-1.66-1.469c.093-.21.117-.44.066-.668-.114-.513-.595-.885-1.142-.885-.082 0-.165.008-.246.025-.63.129-1.033.725-.898 1.328.05.225.17.422.336.574l-.896 1.91a1.163 1.163 0 0 0-.96.51c-.17.25-.228.55-.163.842.115.513.595.885 1.143.885.082 0 .164-.008.245-.025.305-.062.567-.235.737-.486.17-.25.227-.55.162-.842a1.1 1.1 0 0 0-.317-.554l.907-1.932c.065-.003.13-.01.193-.022.06-.012.118-.03.175-.05l1.684 1.49c-.08.2-.1.417-.051.632.114.512.595.884 1.142.884a1.164 1.164 0 0 0 .982-.51 1.072 1.072 0 0 0-.14-1.382l1.012-1.969a1.23 1.23 0 0 0 .175-.021c.63-.13 1.034-.725.899-1.328z" />
      </g>
    </Box>
  );
}

TrafficOccupancyDailyIcon.defaultProps = {
  sx: undefined,
};
