import { FlowValue } from "../../../api/layers/types";
import { PoiKey } from "../../../api/pois/types";

export enum HighlightType {
  LOCATION_AREA = "locationArea",
  LOCATION = "location",
  FLOW = "flow",
}

export interface LocationHighlight {
  type: HighlightType.LOCATION | HighlightType.LOCATION_AREA;
  locationId: string;
}

export interface FlowHighlight {
  type: HighlightType.FLOW;
  flow: FlowValue[];
}

export type Highlight = LocationHighlight | FlowHighlight;

export type WmsTileDataFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tile: any
) => Promise<ImageBitmap[] | null>;

export interface HeatmapSettings {
  intensity: number;
  radiusPixels: number;
  threshold: number;
}

export interface PoiSettings {
  poiKey: PoiKey;
}

export interface TimeseriesSettings {
  dataRef: string;
}
