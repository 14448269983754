import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./header/BaseHeader";
import Footer from "./footer/BaseFooter";
import Content from "./Content";

interface BaseLayoutProps {
  children: React.ReactNode;
}

const fullScreenRoutes = ["/map"];
const legalRoutes = ["/agb", "/licenses"];
const onboardingRoutes = ["/bbox-selection"];

export default function BaseLayout({
  children,
}: BaseLayoutProps): React.ReactElement {
  const location = useLocation();
  const fullScreenMode = fullScreenRoutes.includes(location.pathname);
  const disableMenu =
    legalRoutes.includes(location.pathname) ||
    onboardingRoutes.includes(location.pathname);
  const isOnboarding = onboardingRoutes.includes(location.pathname);

  return (
    <>
      <Header disableMenu={disableMenu} isOnboarding={isOnboarding} />
      <Content disablePadding={isOnboarding} fullScreenMode={fullScreenMode}>
        {children}
      </Content>
      <Footer />
    </>
  );
}
