import React from "react";
import { shallowEqual } from "react-redux";
import { Typography, Box } from "@mui/material";
import { LayerId } from "../../../../store/slices/layers/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { toggleSelectedLayerById } from "../../../../store/slices/layers/slice";
import ControllerFactory from "../controller/ControllerFactory";
import {
  selectLayerById,
  selectLayerStatusById,
} from "../../../../store/slices/layers/selectors";
import LayerSubMenuItemIcon from "./LayerSubMenuItemIcon";
import LayerSubMenuItemEditButton from "./LayerSubMenuItemEditButton";
import LayerSubMenuItemStatusIndicator from "./LayerSubMenuItemStatusIndicator";
import { dispatchFetchLayerData } from "../../../../store/slices/layers/factory";
import LayerBasedChartDialog from "../../../dialog/LayerBasedChartDialog";
import Separator from "../../../custom/Separator";

interface LayerSubMenuItemProps {
  layerId: LayerId;
  addSeparator?: boolean;
}

function createAdditionalComponent(
  layerId: LayerId,
  componentId: string
): React.ReactElement {
  switch (componentId) {
    case "LayerBasedChartDialog":
      return <LayerBasedChartDialog layerId={layerId} />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
}

function LayerSubMenuItem({
  layerId,
  addSeparator,
}: LayerSubMenuItemProps): React.ReactElement {
  const dispatch = useAppDispatch();

  const layer = useAppSelector(
    (state) => selectLayerById(state, layerId),
    shallowEqual
  );

  const layerStatus = useAppSelector((state) =>
    selectLayerStatusById(state, layerId)
  );

  const handleClickItem = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(toggleSelectedLayerById(layerId));
    dispatchFetchLayerData(layerId);
  };

  const showEditButton =
    layer.selected &&
    layer.editComponentId &&
    layerStatus.status !== "loading" &&
    layerStatus.status !== "failed";

  return (
    <>
      <Box
        sx={{
          width: 247,
          py: "4px",
          px: (theme) => theme.spacing(1),
          color: (theme) => theme.palette.blk.main,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: (theme) => theme.palette.grey[300],
          },
        }}
        onClick={handleClickItem}
      >
        <LayerSubMenuItemIcon layer={layer} />
        <Typography
          sx={{
            mx: (theme) => theme.spacing(2),
            flexGrow: 1,
            lineHeight: 1.07,
          }}
          component="p"
          variant={layer.selected ? "h3" : "body1"}
        >
          {layer.label}
        </Typography>
        {showEditButton ? (
          <LayerSubMenuItemEditButton layerId={layer.id} />
        ) : (
          <LayerSubMenuItemStatusIndicator requestStatus={layerStatus} />
        )}
      </Box>
      {layer.additionalComponentId &&
        createAdditionalComponent(layer.id, layer.additionalComponentId)}
      {layer.editing && layer.editComponentId && (
        <Box
          sx={{
            position: "absolute",
            left: 270,
            transform: "translateY(-50px)",
          }}
        >
          {ControllerFactory.createController(layer.id, layer.editComponentId)}
        </Box>
      )}
      {addSeparator && <Separator />}
    </>
  );
}

export default React.memo(LayerSubMenuItem);

LayerSubMenuItem.defaultProps = {
  addSeparator: false,
};
