import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box/Box";
import { Typography } from "@mui/material";

interface FooterItemProps {
  id: string;
  onClick: (event: React.MouseEvent) => void;
  children: React.ReactNode;
}

interface FooterLinkProps {
  href: string;
  children: React.ReactNode;
}

const routesByFooterItems: { [key: string]: string } = {
  "footer-item-agb": "/agb",
  "footer-item-licenses": "/licenses",
};

function FooterItem({
  id,
  onClick,
  children,
}: FooterItemProps): React.ReactElement {
  return (
    <Typography
      sx={{
        fontSize: 18,
        fontWeight: 300,
        fontFamily: "Montserrat, sans-serif",
        color: (theme) => theme.palette.grey[200],
        "&:hover": {
          cursor: "pointer",
          color: (theme) => theme.palette.secondary.main,
        },
      }}
      component="p"
      id={id}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}

function FooterLink({ href, children }: FooterLinkProps): React.ReactElement {
  return (
    <Box
      sx={{
        fontSize: 18,
        fontWeight: 300,
        fontFamily: "Montserrat, sans-serif",
        textDecoration: "none",
        color: (theme) => theme.palette.grey[200],
        "&:hover": {
          cursor: "pointer",
          color: (theme) => theme.palette.secondary.main,
        },
      }}
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Box>
  );
}

export default function BaseFooter(): React.ReactElement {
  const navigate = useNavigate();

  const handleFooterItemClick = (event: React.MouseEvent): void => {
    const { id } = event.currentTarget;
    const route = routesByFooterItems[id];
    if (route) {
      navigate(route);
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: (theme) => theme.footer.height,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        py: (theme) => theme.spacing(2),
        pl: (theme) => theme.spacing(4),
        pr: (theme) => theme.spacing(6),
        color: (theme) => theme.palette.wht.main,
        backgroundColor: (theme) => theme.palette.grey[800],
      }}
      component="footer"
    >
      <Box
        sx={{
          px: (theme) => theme.spacing(4),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "flex-end",
        }}
      >
        <FooterLink href="https://strazoon.io/impressum/">Impressum</FooterLink>
        <FooterItem id="footer-item-agb" onClick={handleFooterItemClick}>
          AGB
        </FooterItem>
      </Box>
      <Box
        sx={{
          px: (theme) => theme.spacing(4),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "flex-end",
        }}
      >
        <FooterLink href="https://strazoon.io/datenschutz/">
          Datenschutzhinweise
        </FooterLink>
        <FooterItem id="footer-item-licenses" onClick={handleFooterItemClick}>
          Open Source Lizenzen
        </FooterItem>
      </Box>
    </Box>
  );
}
