import { Box } from "@mui/material";
import { IconProps } from "./types";

export function TrafficInformationIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 22, height: 20, ...sx }}
      component="svg"
      viewBox="0 0 22 20"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="m21.843 17.79-9.84-16.72A1.148 1.148 0 0 0 11 .5c-.42 0-.796.213-1.005.57L.157 17.79a1.116 1.116 0 0 0 0 1.14c.211.357.586.57 1.007.57h19.672c.42 0 .796-.213 1.007-.57a1.116 1.116 0 0 0 0-1.14zm-2.344-.187a.323.323 0 0 1-.278.155H2.778a.322.322 0 0 1-.279-.155.316.316 0 0 1 0-.316l8.225-13.972c.115-.198.436-.198.551 0L19.5 17.287c.056.1.056.216 0 .316z" />
        <path d="M11.985 14.591a.98.98 0 0 1-.985.97.98.98 0 0 1-.986-.97c0-.533.442-.967.986-.967.543 0 .985.434.985.967zM12.213 8.563l-.198 3.5c-.035.607-.48 1.083-1.015 1.083-.536 0-.98-.476-1.015-1.083l-.198-3.5a1.239 1.239 0 0 1 .329-.93.951.951 0 0 1 .686-.303h.396c.254 0 .498.108.686.302.23.237.348.579.329.93z" />
      </g>
    </Box>
  );
}

TrafficInformationIcon.defaultProps = {
  sx: undefined,
};
