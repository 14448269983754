import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import {
  Commit,
  Commits,
  CommitCreationDTO,
  Branch,
  Branches,
  Merges,
  Rules,
  DataSources,
} from "./types";

function download(filename: string, response: AxiosResponse): void {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export const getCommits = (): Promise<AxiosResponse<Commits>> =>
  AxiosClient.get<Commits>("/commits");

export const getCommitById = (id: string): Promise<AxiosResponse<Commit>> =>
  AxiosClient.get<Commit>(`/commits/${id}`);

export const getBranches = (): Promise<AxiosResponse<Branches>> =>
  AxiosClient.get<Branches>("/branches");

export const getBranchById = (id: string): Promise<AxiosResponse<Branch>> =>
  AxiosClient.get<Branch>(`/branches/${id}`);

export const getBranchExportById = (
  id: string,
  fileName: string
): Promise<void> =>
  AxiosClient.get<Branch>(`/branches/${id}/export`).then((response) =>
    download(fileName, response)
  );

export const getMerges = (): Promise<AxiosResponse<Merges>> =>
  AxiosClient.get<Merges>("/merges");

export const getRules = (): Promise<AxiosResponse<Rules>> =>
  AxiosClient.get<Rules>("/core/rules/");

export const getDataSources = (): Promise<AxiosResponse<DataSources>> =>
  AxiosClient.get<DataSources>("/core/data-sources/");

export const postCreateRule = (
  action: string,
  createdBy: string,
  region: string,
  type: string
): Promise<AxiosResponse<void>> =>
  AxiosClient.post<void>(
    "/core/rules/",
    {
      action,
      created_by: createdBy,
      region,
      type,
    },
    { headers: { "Content-Type": "application/json" } }
  );

export const deleteRuleById = (ruleId: string): Promise<AxiosResponse<void>> =>
  AxiosClient.delete<void>(`/core/rules/${ruleId}`);

export const putUpdateRule = (
  ruleId: string,
  action: string,
  createdBy: string,
  region: string,
  type: string
): Promise<AxiosResponse<void>> =>
  AxiosClient.put<void>(
    `/core/rules/${ruleId}`,
    {
      action,
      created_by: createdBy,
      region,
      type,
    },
    { headers: { "Content-Type": "application/json" } }
  );

export const postCreateMerge = (
  sourceBranchId: string,
  targetBranchId: string
): Promise<AxiosResponse<void>> =>
  AxiosClient.post<void>(
    "/merges",
    { sourceBranchId, targetBranchId },
    { headers: { "Content-Type": "application/json" } }
  );

export const postCreateBranch = (
  id: string,
  name: number
): Promise<AxiosResponse<Branch>> =>
  AxiosClient.post<Branch>(
    "/branches",
    { commitId: id, branchName: name },
    { headers: { "Content-Type": "application/json" } }
  );

export const postPushCommitToBranch = (
  commitId: string,
  targetBranchId: string
): Promise<AxiosResponse<Commit>> =>
  AxiosClient.post<Commit>(
    `/commits/${commitId}/push`,
    { targetBranchId },
    { headers: { "Content-Type": "application/json" } }
  );

export const postExportMaster = (): Promise<void> =>
  AxiosClient.post(
    "/actions/export",
    { responseType: "document" },
    { headers: { "Content-Type": "application/xml" } }
  ).then((response) => download("master.osm", response));

export const postCreateNewCommit = (
  commitCreationDTO: CommitCreationDTO,
  file: File
): Promise<AxiosResponse<Commits>> => {
  const formData = new FormData();
  formData.append("file", file);

  const json = JSON.stringify(commitCreationDTO);
  const blob = new Blob([json], {
    type: "application/json",
  });
  formData.append("commitCreationDTO", blob);

  return AxiosClient.post("/commits", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
