import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Timeseries } from "../../api/timeseries/types";
import { getTimeseriesByUrl } from "../../api/timeseries/timeseries-api";
import CustomLineChart from "../custom/CustomLineChart";
import { getDataLabelDisplayName } from "../../tech/utils/DisplayNameHandler";
import Logger from "../../tech/utils/Logger";
import { LayerId } from "../../store/slices/layers/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectTimeseriesSettingsById } from "../../store/slices/layers/selectors";
import { RequestStatus } from "../../store/types";
import { updateTimeseriesSettings } from "../../store/slices/layers/slice";
import DatePicker from "../custom/date-picker/DatePicker";
import {
  getStartOfDayMilliseconds,
  toUTC,
  getEndOfDayMilliseconds,
} from "../../tech/utils/TimestampFormatter";
import { BaseDialog } from "./base/BaseDialog";

interface LayerBasedChartDialogProps {
  layerId: LayerId;
}

const TIMESTAMP_LABEL = "timestamp";

export default function LayerBasedChartDialog({
  layerId,
}: LayerBasedChartDialogProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [timeseries, setTimeseries] = useState<Timeseries>();
  const [status, setStatus] = useState<RequestStatus["status"]>("idle");

  const timeseriesSettings = useAppSelector((state) =>
    selectTimeseriesSettingsById(state, layerId)
  );
  const open = timeseriesSettings !== null;

  useEffect(() => {
    const getAllMdmTrafficStationMeasurements = (): void => {
      if (timeseriesSettings?.dataRef && selectedDate) {
        setStatus("loading");
        const fromMilliseconds = getStartOfDayMilliseconds(toUTC(selectedDate));
        const toMilliseconds = getEndOfDayMilliseconds(toUTC(selectedDate));
        const url = `${timeseriesSettings.dataRef}&from_ms=${fromMilliseconds}&to_ms=${toMilliseconds}`;
        getTimeseriesByUrl(url)
          .then((response) => {
            setStatus("succeeded");
            setTimeseries(response.data);
          })
          .catch((error) => {
            setStatus("failed");
            Logger.error(error);
          });
      }
    };

    if (open) {
      getAllMdmTrafficStationMeasurements();
    }
  }, [open, selectedDate, timeseriesSettings]);

  const handleClose = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(updateTimeseriesSettings({ id: layerId, dataRef: null }));
  };

  return (
    <BaseDialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleClose}
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: (theme) => theme.spacing(2),
          }}
        >
          <Typography component="p" variant="h2">
            Messdaten vom
          </Typography>
          <DatePicker
            variant="outlined"
            color="black"
            date={selectedDate}
            onChange={setSelectedDate}
          />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: (theme) => theme.spacing(2),
        }}
      >
        {status === "loading" && <Typography>Lade Daten...</Typography>}
        {status === "failed" && (
          <Typography>Laden der Daten fehlgeschlagen!</Typography>
        )}
        {status === "succeeded" &&
          timeseries &&
          timeseries.labels
            .filter((label) => label !== TIMESTAMP_LABEL)
            .map((label) => (
              <CustomLineChart
                key={label}
                data={timeseries.timeseries}
                xDataKey={TIMESTAMP_LABEL}
                yDataKey={label}
                dataLabel={getDataLabelDisplayName(label)}
                xLabel={getDataLabelDisplayName(TIMESTAMP_LABEL)}
                yLabel={getDataLabelDisplayName(label)}
              />
            ))}
      </Box>
    </BaseDialog>
  );
}
