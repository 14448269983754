import React from "react";
import Paper from "@mui/material/Paper";
import { SxProps, Theme, Typography } from "@mui/material";
import { getTooltipKeyDisplayName } from "../../../tech/utils/DisplayNameHandler";
import { TooltipData } from "../../../store/slices/maps/types";

export interface HoverTooltipProps {
  sx?: SxProps<Theme>;
  data: TooltipData;
}

export default function HoverTooltip({
  sx,
  data,
}: HoverTooltipProps): React.ReactElement {
  const { x, y } = data;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const object = data.object as any;

  const tooltipContent = (): React.ReactElement => {
    if (object && object.cluster && object.point_count) {
      return (
        <Typography
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          component="p"
        >
          {`Anzahl: ${object.point_count}`}
        </Typography>
      );
    }
    if (object && object.properties && object.properties.tags) {
      return (
        <>
          {Object.keys(object.properties.tags).map((key) => (
            <Typography
              key={key}
              sx={{
                mb: (theme) => theme.spacing(1),
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              component="p"
            >{`${getTooltipKeyDisplayName(key)}: ${
              object.properties.tags[key]
            }`}</Typography>
          ))}
        </>
      );
    }
    return (
      <Typography component="p">Keine Informationen vorhanden.</Typography>
    );
  };

  return (
    <Paper
      sx={{
        px: (theme) => theme.spacing(2),
        py: (theme) => theme.spacing(1),
        width: "max-content",
        maxWidth: 300,
        height: "max-content",
        position: "absolute",
        top: y,
        left: x,
        transform: "translate(10px, 0px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        ...sx,
      }}
    >
      {tooltipContent()}
    </Paper>
  );
}

HoverTooltip.defaultProps = {
  sx: undefined,
};
