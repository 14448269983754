import React from "react";
import { shallowEqual } from "react-redux";
import { Paper, Typography } from "@mui/material";
import { ChevronClosedIcon } from "../../icons/ChevronClosedIcon";
import { ChevronOpenIcon } from "../../icons/ChevronOpenIcon";
import {
  LayerCategoryId,
  DebugLayerCategoryId,
} from "../../../store/slices/layer-categories/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectDebugCategory,
  selectLayerCategoryById,
  toggleDebugCategory,
  toggleLayerCategoryById,
} from "../../../store/slices/layer-categories/slice";
import LayerSubMenuItems from "./LayerSubMenuItems";
import WhtButton from "../../custom/buttons/WhtButton";

interface LayerSubMenuProps {
  categoryId: LayerCategoryId | DebugLayerCategoryId;
}

export default function LayerSubMenu({
  categoryId,
}: LayerSubMenuProps): React.ReactElement {
  const dispatch = useAppDispatch();

  const layerCategory = useAppSelector(
    (state) =>
      categoryId === "debug"
        ? selectDebugCategory(state)
        : selectLayerCategoryById(state, categoryId),
    shallowEqual
  );

  const handleClickToggle = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    if (categoryId === "debug") {
      dispatch(toggleDebugCategory());
    } else {
      dispatch(toggleLayerCategoryById(categoryId));
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
      }}
    >
      <WhtButton
        sx={{
          width: 247,
          height: 43,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottomLeftRadius: layerCategory.expanded ? 0 : 2,
          borderBottomRightRadius: layerCategory.expanded ? 0 : 2,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        }}
        variant="contained"
        onClick={handleClickToggle}
      >
        <Typography sx={{ textTransform: "none" }} component="p" variant="h2">
          {layerCategory.label}
        </Typography>
        {layerCategory.expanded ? <ChevronOpenIcon /> : <ChevronClosedIcon />}
      </WhtButton>
      {layerCategory.expanded && (
        <LayerSubMenuItems categoryId={layerCategory.id} />
      )}
    </Paper>
  );
}
