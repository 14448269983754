import { Box } from "@mui/material";
import { IconProps } from "./types";

export function InductionLoopIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 23, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 23 16"
    >
      <path
        d="M21.981 4.08a1.113 1.113 0 0 0-1.425-.686l-1.755.628V1.128a1.116 1.116 0 1 0-2.23 0v3.689l-2.036.725V1.128a1.116 1.116 0 1 0-2.23 0v5.219l-2.036.726V1.128a1.116 1.116 0 1 0-2.23 0v6.75l-2.035.725V1.128a1.116 1.116 0 1 0-2.23 0v8.27l-2.987 1.07a1.133 1.133 0 0 0-.678 1.441c.165.461.591.756 1.047.756.126 0 .252-.02.368-.069l2.24-.804v3.07c0 .628.494 1.128 1.115 1.128.62 0 1.125-.5 1.125-1.128v-3.865l2.035-.726v4.591a1.116 1.116 0 1 0 2.23 0V9.467l2.036-.726v6.121a1.116 1.116 0 1 0 2.23 0V7.946l2.036-.726v7.652c0 .628.495 1.128 1.115 1.128s1.115-.51 1.115-1.138V6.416l2.502-.893c.581-.206.882-.854.678-1.442z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

InductionLoopIcon.defaultProps = {
  sx: undefined,
};
