import { Box } from "@mui/material";
import { IconProps } from "./types";

export function VisibilityInactiveIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 24, height: 22, ...sx }}
      component="svg"
      viewBox="0 0 24 22"
    >
      <path
        d="m16.064 12.25-1.2-1.209c.472-1.3.227-2.38-.737-3.24-.963-.861-2.009-1.081-3.136-.66l-1.2-1.208c.309-.202.654-.348 1.036-.44A5.012 5.012 0 0 1 12 5.356c1.29 0 2.386.453 3.286 1.36.9.906 1.35 2.009 1.35 3.309 0 .403-.05.801-.15 1.195-.1.393-.24.737-.422 1.03zm3.518 3.543-1.091-1.099c.89-.659 1.668-1.396 2.332-2.21.663-.816 1.15-1.635 1.459-2.459-.91-2.032-2.273-3.64-4.091-4.82-1.818-1.181-3.791-1.772-5.918-1.772-.764 0-1.546.073-2.346.22-.8.146-1.427.32-1.882.522L6.791 2.884c.636-.293 1.45-.55 2.44-.77.992-.219 1.96-.329 2.905-.329 2.6 0 4.978.746 7.132 2.239C21.423 5.516 23 7.516 24 10.025a13.593 13.593 0 0 1-1.827 3.213c-.746.97-1.61 1.822-2.591 2.555zM21.164 22l-4.582-4.532c-.637.257-1.355.453-2.155.59-.8.138-1.609.207-2.427.207-2.655 0-5.064-.746-7.227-2.239C2.609 14.534 1.018 12.533 0 10.025a12.49 12.49 0 0 1 1.514-2.788 15.573 15.573 0 0 1 2.359-2.595L.436 1.18 1.582 0l20.645 20.792L21.164 22zM4.99 5.795c-.673.495-1.323 1.145-1.95 1.95-.627.806-1.077 1.566-1.35 2.28.927 2.032 2.323 3.64 4.186 4.82 1.864 1.181 3.978 1.772 6.341 1.772.6 0 1.191-.037 1.773-.11.582-.073 1.018-.183 1.309-.33l-1.745-1.758c-.2.092-.446.16-.737.206-.29.046-.563.07-.818.07-1.273 0-2.364-.45-3.273-1.347-.909-.897-1.363-2.005-1.363-3.323 0-.275.022-.55.068-.824.045-.275.113-.522.204-.742L4.991 5.795z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

VisibilityInactiveIcon.defaultProps = {
  sx: undefined,
};
