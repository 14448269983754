import { Box } from "@mui/material";
import { IconProps } from "./types";

export function VisibilityIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 24, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 24 16"
    >
      <path
        d="M12 12.533c1.29 0 2.386-.44 3.286-1.32.9-.88 1.35-1.95 1.35-3.213 0-1.262-.45-2.333-1.35-3.213-.9-.88-1.995-1.32-3.286-1.32-1.29 0-2.386.44-3.286 1.32-.9.88-1.35 1.95-1.35 3.213 0 1.262.45 2.333 1.35 3.213.9.88 1.995 1.32 3.286 1.32zm0-1.546c-.855 0-1.577-.29-2.168-.867A2.851 2.851 0 0 1 8.945 8c0-.836.296-1.542.887-2.12.59-.578 1.313-.867 2.168-.867.855 0 1.577.29 2.168.867.591.578.887 1.284.887 2.12 0 .836-.296 1.542-.887 2.12-.59.578-1.313.867-2.168.867zM12 16c-2.655 0-5.055-.738-7.2-2.213A13.549 13.549 0 0 1 0 8a13.549 13.549 0 0 1 4.8-5.787C6.945.738 9.345 0 12 0c2.655 0 5.055.738 7.2 2.213A13.549 13.549 0 0 1 24 8a13.549 13.549 0 0 1-4.8 5.787C17.055 15.262 14.655 16 12 16zm0-1.6c2.2 0 4.223-.582 6.068-1.747A11.421 11.421 0 0 0 22.282 8a11.421 11.421 0 0 0-4.214-4.653A11.149 11.149 0 0 0 12 1.6c-2.2 0-4.223.582-6.068 1.747A11.615 11.615 0 0 0 1.69 8a11.615 11.615 0 0 0 4.24 4.653A11.149 11.149 0 0 0 12 14.4z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

VisibilityIcon.defaultProps = {
  sx: undefined,
};
