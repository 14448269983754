import React from "react";
import Box from "@mui/material/Box/Box";

interface ContentProps {
  children: React.ReactNode;
  disablePadding?: boolean;
  fullScreenMode?: boolean;
}

export default function Content({
  children,
  disablePadding,
  fullScreenMode,
}: ContentProps): React.ReactElement {
  return (
    <Box
      sx={{
        mt: (theme) => (fullScreenMode ? 0 : theme.header.height),
        py: (theme) =>
          disablePadding || fullScreenMode ? 0 : theme.spacing(8),
        pl: (theme) =>
          disablePadding || fullScreenMode ? 0 : theme.spacing(6),
        pr: (theme) =>
          disablePadding || fullScreenMode ? 0 : theme.spacing(8),
        width: "100vw",
        minHeight: (theme) =>
          fullScreenMode
            ? `calc(100vh - (${theme.footer.height} / 5))`
            : `calc(100vh - ${theme.header.height} - (${theme.footer.height} / 5))`,
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.wht.main,
      }}
    >
      {children}
    </Box>
  );
}

Content.defaultProps = {
  disablePadding: false,
  fullScreenMode: false,
};
