import { Box } from "@mui/material";
import { IconProps } from "./types";

export function BikeNetworkIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 24, height: 24, ...sx }}
      component="svg"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M12 0C5.387 0 0 5.387 0 12s5.387 12 12 12 12-5.387 12-12S18.613 0 12 0zm0 21.52c-5.227 0-9.52-4.267-9.52-9.52 0-5.227 4.267-9.52 9.52-9.52 5.227 0 9.52 4.267 9.52 9.52 0 5.227-4.293 9.52-9.52 9.52z" />
        <path d="M16.23 10.998c-.103 0-.19 0-.292.015l-.948-3.194a.437.437 0 0 0-.423-.319H13.24a.453.453 0 0 0-.452.45c0 .247.204.45.452.45h.991l.35 1.176h-4.477l.248-.668a.447.447 0 0 0-.044-.407.457.457 0 0 0-.364-.188H8.442a.453.453 0 0 0-.452.45c0 .247.204.45.452.45h.845l-.247.653-.57 1.248a2.804 2.804 0 0 0-.7-.087A2.72 2.72 0 0 0 5 13.757 2.748 2.748 0 0 0 7.756 16.5a2.77 2.77 0 0 0 2.757-2.526h1.006a.437.437 0 0 0 .335-.16l2.96-3.44.263.871c-.948.436-1.619 1.394-1.619 2.497A2.782 2.782 0 0 0 16.23 16.5c1.517 0 2.771-1.234 2.771-2.744 0-1.51-1.24-2.758-2.77-2.758zm-8.474 4.617a1.866 1.866 0 0 1-1.866-1.858 1.866 1.866 0 0 1 2.187-1.83l-.656 1.423c-.058.13-.058.305.03.421a.44.44 0 0 0 .378.203h1.78a1.86 1.86 0 0 1-1.853 1.64zm1.75-2.526H8.53l.365-.799c.277.19.496.48.612.799zm1.809 0h-.875a2.753 2.753 0 0 0-1.152-1.626l.452-1.002h3.835l-2.26 2.628zm4.914 2.526a1.866 1.866 0 0 1-1.866-1.858c0-.712.393-1.321.977-1.64l.54 1.843a.45.45 0 0 0 .422.32c.044 0 .088 0 .131-.015a.461.461 0 0 0 .307-.552l-.54-1.815h.03c1.035 0 1.866.842 1.866 1.859a1.866 1.866 0 0 1-1.867 1.858z" />
      </g>
    </Box>
  );
}

BikeNetworkIcon.defaultProps = {
  sx: undefined,
};
