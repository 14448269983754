import React from "react";
import { SxProps, Theme } from "@mui/material";
import { MagnifyingGlassIcon } from "../../../icons/MagnifyingGlassIcon";
import { MapConfigId } from "../../../../store/slices/maps/types";
import CircleButton from "../../../custom/buttons/CircleButton";

interface LocationSearchProps {
  sx?: SxProps<Theme>;
  mapConfigId: MapConfigId;
}

export default function LocationSearch({
  sx,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapConfigId,
}: LocationSearchProps): React.ReactElement {
  return (
    <CircleButton
      sx={sx}
      size={40}
      label={<MagnifyingGlassIcon />}
      color="white"
    />
  );
}

LocationSearch.defaultProps = {
  sx: undefined,
};
