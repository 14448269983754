import { alpha, Button, ButtonProps } from "@mui/material";

export default function WhtButton(
  props: Omit<ButtonProps, "color">
): React.ReactElement {
  const { sx, children, variant } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sxAsAny = sx as any;
  const hoverSx = sxAsAny ? sxAsAny["&:hover"] : undefined;

  switch (variant) {
    case "text":
      return (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          sx={{
            ...sx,
            color: (theme) => theme.palette.wht.main,
            "&:hover": {
              ...hoverSx,
              backgroundColor: (theme) => alpha(theme.palette.wht.main, 0.1),
            },
          }}
        >
          {children}
        </Button>
      );
    case "outlined":
      return (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          sx={{
            ...sx,
            color: (theme) => theme.palette.wht.main,
            borderColor: (theme) => theme.palette.wht.main,
            "&:hover": {
              ...hoverSx,
              backgroundColor: (theme) => alpha(theme.palette.wht.main, 0.1),
              borderColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          {children}
        </Button>
      );
    case "contained":
    default:
      return (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          sx={{
            ...sx,
            color: (theme) => theme.palette.blk.main,
            backgroundColor: (theme) => theme.palette.wht.main,
            "&:hover": {
              ...hoverSx,
              backgroundColor: (theme) => theme.palette.grey[300],
            },
          }}
        >
          {children}
        </Button>
      );
  }
}
