import { useState } from "react";
import { Avatar, Box, Button, Menu, Typography } from "@mui/material";
import HeaderButton from "./HeaderButton";
import { ProfileIcon } from "../../icons/ProfileIcon";
import { doLogout, getFullName } from "../../../api/AuthService";
import Separator from "../../custom/Separator";

export default function ProfileMenu(): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const fullName = getFullName();

  return (
    <>
      <HeaderButton
        label="Profil"
        icon={<ProfileIcon />}
        onClick={handleClick}
      />
      <Menu
        elevation={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { transform: "translateY(8px)" },
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: 190,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: (theme) => theme.spacing(2),
            pt: (theme) => theme.spacing(3),
            pb: (theme) => theme.spacing(1),
          }}
        >
          <Avatar sx={{ width: 76, height: 76 }}>
            {fullName ? fullName[0] : "?"}
          </Avatar>
          <Typography
            sx={{ my: (theme) => theme.spacing(2), fontSize: 18 }}
            component="p"
            variant="h4"
          >
            {fullName || "Unbekannt"}
          </Typography>
          <Separator sx={{ width: "100%" }} />
          <Button sx={{ width: "100%" }} variant="text">
            Profil wechseln
          </Button>
          <Separator sx={{ width: "100%" }} />
          <Button sx={{ width: "100%" }} variant="text">
            Profil bearbeiten
          </Button>
          <Separator sx={{ width: "100%" }} />
          <Button
            sx={{ width: "100%" }}
            variant="text"
            onClick={() => doLogout()}
          >
            Logout
          </Button>
        </Box>
      </Menu>
    </>
  );
}
