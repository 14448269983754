import axios, { AxiosRequestConfig } from "axios";
import camelcaseKeys from "camelcase-keys";
import geobuf from "geobuf";
import Pbf from "pbf";
import { getToken, updateToken } from "./AuthService";

const baseURL = process.env.REACT_APP_BASE_URL || "/";
const apiKey = process.env.REACT_APP_API_KEY || "";

const AxiosClient = axios.create({
  baseURL,
  headers: {
    "X-API-Key": apiKey,
  },
  transformResponse: [
    (data, headers) => {
      let contentType = "";
      if (headers) {
        contentType =
          String(headers["content-type"]) || String(headers.ContentType) || "";
      }
      if (contentType.toLowerCase().includes("application/json")) {
        return camelcaseKeys(JSON.parse(data), {
          deep: true,
          exclude: ["vehicle_flow_rate"],
        });
      }
      if (contentType.toLowerCase().includes("application/x-protobuf")) {
        const geojson = geobuf.decode(new Pbf(data));
        return geojson;
      }
      return data;
    },
  ],
});

AxiosClient.interceptors.request.use((config) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAuthHeader = async (): Promise<AxiosRequestConfig<any>> => {
    const updatedConfig = { ...config };
    if (updatedConfig.headers) {
      updatedConfig.headers.Authorization = `Bearer ${getToken()}`;
    }
    return Promise.resolve(updatedConfig);
  };
  return updateToken(setAuthHeader);
});

export { AxiosClient };
