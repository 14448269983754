import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MapConfigId } from "../../store/slices/maps/types";
import Logger from "../../tech/utils/Logger";
import { BaseDialog } from "./base/BaseDialog";

interface ExportMapLayoutDialogProps {
  mapConfigId: MapConfigId;
  open: boolean;
  onClose: React.MouseEventHandler;
}

const graphicIds = ["ppt", "pdf"] as const;
type GraphicId = typeof graphicIds[number];

const modelIds = ["sumo", "qgis", "matsim"] as const;
type ModelId = typeof modelIds[number];

export default function ExportMapLayoutDialog({
  mapConfigId,
  open,
  onClose,
}: ExportMapLayoutDialogProps): React.ReactElement {
  const [checkedGraphicIds, setCheckedGraphicIds] = useState<Set<GraphicId>>(
    new Set()
  );
  const [checkedModelIds, setCheckedModelIds] = useState<Set<ModelId>>(
    new Set()
  );

  useEffect(() => {
    if (open) {
      setCheckedGraphicIds(new Set());
      setCheckedModelIds(new Set());
    }
  }, [open]);

  const handleChangeGraphicId = (id: GraphicId): void => {
    const checkedGraphicIdsCopy = new Set(checkedGraphicIds);
    if (checkedGraphicIdsCopy.has(id)) {
      checkedGraphicIdsCopy.delete(id);
    } else {
      checkedGraphicIdsCopy.add(id);
    }
    setCheckedGraphicIds(checkedGraphicIdsCopy);
  };

  const handleChangeAllGraphics = (): void => {
    if (graphicIds.length === checkedGraphicIds.size) {
      setCheckedGraphicIds(new Set());
    } else {
      setCheckedGraphicIds(new Set(graphicIds));
    }
  };

  const handleChangeModelId = (id: ModelId): void => {
    const checkedModelIdsCopy = new Set(checkedModelIds);
    if (checkedModelIdsCopy.has(id)) {
      checkedModelIdsCopy.delete(id);
    } else {
      checkedModelIdsCopy.add(id);
    }
    setCheckedModelIds(checkedModelIdsCopy);
  };

  const handleChangeAllModels = (): void => {
    if (modelIds.length === checkedModelIds.size) {
      setCheckedModelIds(new Set());
    } else {
      setCheckedModelIds(new Set(modelIds));
    }
  };

  const handleExportLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. MapConfigId = ", mapConfigId);
  };

  const graphics = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ml: (theme) => theme.spacing(3),
      }}
    >
      <FormControlLabel
        label="PPT"
        control={
          <Checkbox
            checked={checkedGraphicIds.has("ppt")}
            onChange={() => handleChangeGraphicId("ppt")}
          />
        }
      />
      <FormControlLabel
        label="PDF"
        control={
          <Checkbox
            checked={checkedGraphicIds.has("pdf")}
            onChange={() => handleChangeGraphicId("pdf")}
          />
        }
      />
    </Box>
  );

  const model = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ml: (theme) => theme.spacing(3),
      }}
    >
      <FormControlLabel
        label="SUMO"
        control={
          <Checkbox
            checked={checkedModelIds.has("sumo")}
            onChange={() => handleChangeModelId("sumo")}
          />
        }
      />
      <FormControlLabel
        label="QGIS"
        control={
          <Checkbox
            checked={checkedModelIds.has("qgis")}
            onChange={() => handleChangeModelId("qgis")}
          />
        }
      />
      <FormControlLabel
        label="MATSim"
        control={
          <Checkbox
            checked={checkedModelIds.has("matsim")}
            onChange={() => handleChangeModelId("matsim")}
          />
        }
      />
    </Box>
  );

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Exportieren?"
      primaryButton={{
        label: "Exportieren",
        onClick: handleExportLayout,
      }}
    >
      <Typography component="p" variant="body1">
        Wählen Sie Ihr gewünschtes Dateiformat.
      </Typography>
      <Box
        sx={{
          pt: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <FormControlLabel
            label="Grafik"
            control={
              <Checkbox
                checked={graphicIds.length === checkedGraphicIds.size}
                indeterminate={
                  checkedGraphicIds.size > 0 &&
                  graphicIds.length !== checkedGraphicIds.size
                }
                onChange={handleChangeAllGraphics}
              />
            }
          />
          {graphics}
        </Box>
        <Box sx={{ width: "50%" }}>
          <FormControlLabel
            label="Modell"
            control={
              <Checkbox
                checked={modelIds.length === checkedModelIds.size}
                indeterminate={
                  checkedModelIds.size > 0 &&
                  modelIds.length !== checkedModelIds.size
                }
                onChange={handleChangeAllModels}
              />
            }
          />
          {model}
        </Box>
      </Box>
    </BaseDialog>
  );
}
