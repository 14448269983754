import { useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  IconButton,
} from "@mui/material";
import { MapLayout } from "./types";
import { VisibilityIcon } from "../../icons/VisibilityIcon";
import { VisibilityInactiveIcon } from "../../icons/VisibilityInactiveIcon";
import Logger from "../../../tech/utils/Logger";
import { EditOutlineIcon } from "../../icons/EditOutlineIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

interface LoadMapLayoutContentProps {
  entries: MapLayout[];
  allowShare?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

export default function LoadMapLayoutContent({
  entries,
  allowShare,
  allowEdit,
  allowDelete,
}: LoadMapLayoutContentProps): React.ReactElement {
  const [selectedEntryId, setSelectedEntryId] = useState(entries[0]?.id);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEntryId((event.target as HTMLInputElement).value);
  };

  const handleClickChangePublicStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. Entry ID = ", id);
    // TODO: implement
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. Entry ID = ", id);
    // TODO: implement
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. Entry ID = ", id);
    // TODO: implement
  };

  return (
    <RadioGroup value={selectedEntryId} onChange={handleChange}>
      {entries.map((entry) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          key={entry.id}
        >
          <FormControlLabel
            value={entry.id}
            control={<Radio />}
            label={entry.label}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: (theme) => theme.spacing(1),
            }}
          >
            {allowShare && (
              <IconButton
                color="primary"
                onClick={(event) =>
                  handleClickChangePublicStatus(event, entry.id)
                }
              >
                {entry.public ? <VisibilityIcon /> : <VisibilityInactiveIcon />}
              </IconButton>
            )}
            {allowEdit && (
              <IconButton
                color="primary"
                onClick={(event) => handleClickEdit(event, entry.id)}
              >
                <EditOutlineIcon />
              </IconButton>
            )}
            {allowDelete && (
              <IconButton
                color="primary"
                onClick={(event) => handleClickDelete(event, entry.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ))}
    </RadioGroup>
  );
}

LoadMapLayoutContent.defaultProps = {
  allowShare: false,
  allowEdit: false,
  allowDelete: false,
};
