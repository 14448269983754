import React from "react";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface BaseDialogTitleProps {
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  hideCloseButton?: boolean;
}

export function BaseDialogTitle({
  children,
  onClose,
  hideCloseButton,
}: BaseDialogTitleProps): React.ReactElement {
  return (
    <DialogTitle
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: (theme) => theme.spacing(1),
      }}
    >
      <>
        {typeof children === "string" ? (
          <Typography sx={{ flexGrox: 1 }} component="p" variant="h2">
            {children}
          </Typography>
        ) : (
          children
        )}
        {!hideCloseButton && (
          <IconButton
            sx={{
              mr: (theme) => theme.spacing(-1),
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
      </>
    </DialogTitle>
  );
}

BaseDialogTitle.defaultProps = {
  hideCloseButton: false,
};
