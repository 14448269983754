import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { ChevronLeftIcon } from "../../icons/ChevronLeftIcon";
import MoreOptionsMenu from "./MoreOptionsMenu";

interface BaseHeaderProps {
  disableMenu?: boolean;
  isOnboarding?: boolean;
}

export default function BaseHeader({
  disableMenu,
  isOnboarding,
}: BaseHeaderProps): React.ReactElement {
  const navigate = useNavigate();

  const navigateBack = (): void => {
    navigate(-1);
  };

  const handleLoginClicked = (): void => {
    navigate("/login");
  };

  const handleContactClicked = (): void => {
    // TODO: Open contact page or modal
  };

  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.appBar,
        position: "absolute",
        top: 0,
        left: 0,
        px: (theme) => theme.spacing(3),
        color: (theme) => theme.palette.secondary.main,
        height: (theme) => theme.header.height,
        width: "100vw",
      }}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      component="header"
    >
      <Box
        sx={{
          zIndex: (theme) => theme.zIndex.appBar + 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 2,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={navigateBack}
      >
        <Box
          sx={{
            width: 16,
            height: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ChevronLeftIcon
            sx={{
              color: (theme) => theme.palette.secondary.contrastText,
              height: "100%",
              width: "auto",
            }}
          />
        </Box>
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {isOnboarding && (
          <>
            <Button
              sx={{ mr: (theme) => theme.spacing(2) }}
              variant="outlined"
              size="large"
              onClick={handleContactClicked}
            >
              Kontakt
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={handleLoginClicked}
            >
              Login
            </Button>
          </>
        )}
      </Box>
      {!disableMenu && <MoreOptionsMenu />}
    </Box>
  );
}

BaseHeader.defaultProps = {
  disableMenu: false,
  isOnboarding: false,
};
