import { Box } from "@mui/material";
import { IconProps } from "./types";

export function EditOutlineIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 20, height: 20, ...sx }}
      component="svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M1.637 18.362h1.2L16.072 5.12l-.6-.6-.6-.602L1.636 17.16v1.202zM0 20v-3.495L16.044.451c.309-.31.695-.46 1.16-.45.463.008.85.168 1.159.477l1.173 1.174c.31.31.464.692.464 1.147 0 .455-.155.837-.464 1.147L3.492 20H0zM18.281 2.772l-1.119-1.12 1.119 1.12zm-2.21 2.348-.6-.6-.6-.602 1.2 1.202z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

EditOutlineIcon.defaultProps = {
  sx: undefined,
};
