import React from "react";
import { Typography } from "@mui/material";
import { ChevronAllClosedIcon } from "../../icons/ChevronAllClosedIcon";
import { ChevronAllOpenIcon } from "../../icons/ChevronAllOpenIcon";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  layerCategoriesExpanded,
  toggleLayerCategories,
} from "../../../store/slices/layer-categories/slice";
import WhtButton from "../../custom/buttons/WhtButton";

export default function LayerMenuButton(): React.ReactElement {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector((state) => layerCategoriesExpanded(state));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(toggleLayerCategories());
  };

  return (
    <WhtButton
      sx={{
        width: 247,
        height: 43,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: (theme) => theme.spacing(3),
      }}
      variant="contained"
      onClick={handleClick}
    >
      {expanded ? <ChevronAllOpenIcon /> : <ChevronAllClosedIcon />}
      <Typography sx={{ textTransform: "none" }} variant="h1" component="h1">
        MobilityOps
      </Typography>
    </WhtButton>
  );
}
