import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dispatchFetchLayerData } from "../../../store/slices/layers/factory";
import {
  selectMapConfigById,
  updateObservation,
} from "../../../store/slices/maps/slice";
import { MapConfigId, Observation } from "../../../store/slices/maps/types";
import { equalMapConfigObservation } from "../../../store/slices/maps/utils";
import { isoTimestampToDate } from "../../../tech/utils/TimestampFormatter";
import DateRangePicker from "../../custom/date-picker/DateRangePicker";
import LabeledSwitch from "../../custom/LabeledSwitch";

interface DateRangeSelectorProps {
  mapConfigId: MapConfigId;
}

export default function DateRangeSelector({
  mapConfigId,
}: DateRangeSelectorProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const [dtvChecked, setDtvChecked] = useState(false);
  const [dtvw5Checked, setDtvw5Checked] = useState(false);

  const mapConfig = useAppSelector(
    (state) => selectMapConfigById(state, mapConfigId),
    equalMapConfigObservation
  );

  const selectedStartDate = isoTimestampToDate(
    mapConfig?.observation?.startTimestamp || ""
  );
  const selectedEndDate = isoTimestampToDate(
    mapConfig?.observation?.endTimestamp || ""
  );

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      dispatchFetchLayerData("occupancy-avg", true);
      dispatchFetchLayerData("occupancy-daily", true);
      dispatchFetchLayerData("flow-avg", true);
      dispatchFetchLayerData("flow-daily", true);
    }
  }, [selectedStartDate, selectedEndDate]);

  const handleChangeDate = (startDate: Date | null, endDate: Date | null) => {
    const observation: Observation = {
      startTimestamp: startDate?.toISOString() || null,
      endTimestamp: endDate?.toISOString() || null,
    };
    dispatch(updateObservation({ id: mapConfigId, observation }));
  };

  const handleChangeDateToToday = () => {
    const today = new Date();
    handleChangeDate(today, null);
  };

  return (
    <DateRangePicker
      color="white"
      startDate={selectedStartDate}
      endDate={selectedEndDate}
      onChange={handleChangeDate}
      showInputHeader
      SidePanelRight={
        <>
          <Button
            variant="text"
            color="primary"
            onClick={handleChangeDateToToday}
          >
            Heute
          </Button>
          <LabeledSwitch
            sx={{
              width: "100%",
              pl: (theme) => theme.spacing(1.5),
              mt: (theme) => theme.spacing(2),
            }}
            label="DTV"
            description="Durchschnittliches tägliches Verkehrsaufkommen"
            checked={dtvChecked}
            onChange={setDtvChecked}
          />
          <LabeledSwitch
            sx={{
              width: "100%",
              pl: (theme) => theme.spacing(1.5),
              mt: (theme) => theme.spacing(2),
            }}
            label="DTVw5"
            description="Durchschnittliches tägliches Verkehrsaufkommen, werktags (Mo - Fr)"
            checked={dtvw5Checked}
            onChange={setDtvw5Checked}
          />
        </>
      }
    />
  );
}
