import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  SxProps,
  FormControl,
  RadioGroup,
  Typography,
  FormControlLabel,
  Radio,
  Chip,
  Box,
  Link,
  TextField,
  Theme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import ApiContext from "../../tech/context/ApiContext";
import { text, email } from "../../tech/utils/InputValidator";
import Logger from "../../tech/utils/Logger";
import { createTenant } from "../../api/tenants/tenant-api";
import { DataSource } from "../../api/versioning/types";
import { BaseDialog } from "./base/BaseDialog";

interface AreaSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  areaId: string;
}

interface DataSourceAvailability {
  available: boolean;
  dataSource: DataSource;
}

interface LabeledTextFieldProps {
  sx?: SxProps<Theme>;
  id: string;
  label: string;
  type: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  required?: boolean;
}

interface FormElement {
  value: string;
  label: string;
  type: string;
  required?: boolean;
}

interface FormData {
  [id: string]: FormElement;
}

const maxDialogSteps = 4;
const initalFormData: FormData = {
  name: { value: "", label: "Name", type: "text", required: true },
  email: { value: "", label: "Email", type: "email", required: true },
  company: { value: "", label: "Unternehmen", type: "text" },
  // otherUsers: { value: "", label: "Weitere Nutzer", type: "text" },
};

function LabeledTextField({
  sx,
  id,
  label,
  type,
  value,
  onChange,
  required,
}: LabeledTextFieldProps): React.ReactElement {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...sx,
      }}
    >
      <Typography
        sx={{
          mr: (theme) => theme.spacing(4),
          width: "25%",
          textAlign: "end",
        }}
        component="p"
        variant="body1"
      >
        {label}
        {required && "*"}
      </Typography>
      <TextField
        sx={{ width: "50%" }}
        id={id}
        variant="standard"
        type={type}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}

LabeledTextField.defaultProps = {
  sx: undefined,
  required: false,
};

export default function AreaSelectionDialog({
  open,
  onClose,
  areaId,
}: AreaSelectionDialogProps): React.ReactElement {
  const { dataSources } = useContext(ApiContext);
  const navigate = useNavigate();
  const [dataSourceAvailabilities, setDataSourceAvailabilities] = useState<
    DataSourceAvailability[]
  >([]);
  const [areaRadioValue, setAreaRadioValue] = useState("SINGLE_AREA");
  const [formData, setFormData] = useState<FormData>(initalFormData);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (areaId) {
      const checkedDataSources = dataSources.map((dataSource) => {
        const availabilities = dataSource.availability.split(",");
        const isAvailable = availabilities.some((availabilitiy) =>
          new RegExp(`^${availabilitiy}`).test(areaId)
        );
        const dataSourceAvailability: DataSourceAvailability = {
          available: isAvailable,
          dataSource,
        };
        return dataSourceAvailability;
      });
      setDataSourceAvailabilities(checkedDataSources);
    }
  }, [dataSources, areaId]);

  useEffect(
    () => () => {
      if (!open) {
        setStep(0);
      }
    },
    [open]
  );

  const handleAreaRadioValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setAreaRadioValue((event.target as HTMLInputElement).value);
  };

  const handleClose = (): void => {
    onClose();
  };

  const handleBackClicked = (): void => {
    if (step === 0) {
      handleClose();
    } else {
      setStep((previousStep) => {
        const newStep = previousStep - 1;
        if (newStep < 0) {
          return 0;
        }
        return newStep;
      });
    }
  };

  const goBackHome = (): void => {
    navigate("/welcome");
  };

  const moveToNextStep = (): void => {
    setStep((previousStep) => {
      const newStep = previousStep + 1;
      if (newStep >= maxDialogSteps) {
        return maxDialogSteps - 1;
      }
      return newStep;
    });
  };

  const triggerTenantCreation = (): void => {
    setLoading(true);
    createTenant({
      selectedBoundaryId: areaId,
      withAdjacentBoundaries: areaRadioValue === "MULTI_AREA",
      users: [
        {
          name: formData.name.value,
          email: formData.email.value,
          company: formData.company.value || null,
        },
      ],
    })
      .then(() => {
        setLoading(false);
        moveToNextStep();
      })
      .catch((error) => {
        setLoading(false);
        Logger.error(error);
      });
  };

  const handleNextClicked = (): void => {
    if (step === maxDialogSteps - 1) {
      goBackHome();
    } else if (step === maxDialogSteps - 2) {
      triggerTenantCreation();
    } else {
      moveToNextStep();
    }
  };

  const handleContactClicked = (): void => {
    // TODO: Implement
    Logger.info("Kontakt aufnehmen");
  };

  const handleFormInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const { id, value } = event.target;
    setFormData((previousFormData) => ({
      ...previousFormData,
      [id]: {
        ...previousFormData[id],
        value,
      },
    }));
  };

  const areFormInputsValid = (): boolean =>
    text(formData.name.value) && email(formData.email.value);

  const getTitle = (): string => {
    if (step < 2) return `Auswahl: ${areaId}`;
    if (step === 2) return "Demo Anfordern";
    if (step === 3) return "Vielen Dank!";
    return "";
  };

  const getPrimaryButtonLabel = (): string => {
    if (step !== 2 && step !== maxDialogSteps - 1) return "Weiter";
    if (step === 2) return "Anfordern";
    if (step === maxDialogSteps - 1) return "Zur Startseite";
    return "";
  };

  const getSecondaryButtonLabel = (): string => {
    if (step === 0) return "Auswahl ändern";
    if (step > 0) return "Zurück";
    return "";
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      title={getTitle()}
      primaryButton={{
        onClick: handleNextClicked,
        disabled: step === 2 && (!areFormInputsValid() || loading),
        label: getPrimaryButtonLabel(),
      }}
      secondaryButton={{
        onClick: handleBackClicked,
        label: getSecondaryButtonLabel(),
      }}
      hideCancelButton
    >
      {step === 0 && (
        <>
          <Typography component="p" variant="body1">
            Folgende Datenquellen stehen für das ausgewählte Gebiet bereit:
          </Typography>
          <Box sx={{ my: (theme) => theme.spacing(2) }}>
            {dataSourceAvailabilities.map((dataSourceAvailability) => {
              const { dataSource, available } = dataSourceAvailability;
              return (
                <Chip
                  sx={{
                    my: (theme) => theme.spacing(0.5),
                    mr: (theme) => theme.spacing(1),
                  }}
                  key={`chip-${dataSource.id}`}
                  variant="filled"
                  color={available ? "secondary" : "default"}
                  label={
                    <Typography
                      sx={{
                        color: (theme) =>
                          available
                            ? theme.palette.grey[700]
                            : theme.palette.grey[400],
                        fontWeight: 400,
                      }}
                      component="p"
                      variant="body1"
                    >
                      {dataSource.createdBy}
                    </Typography>
                  }
                  icon={available ? <CheckCircleIcon /> : <CancelIcon />}
                />
              );
            })}
          </Box>
          <Typography component="p" variant="body2">
            * Sollte eine benötigte Datenquelle nicht aufgeführt oder vorhanden
            sein, nehmen Sie bitte{" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              variant="body2"
              onClick={handleContactClicked}
            >
              Kontakt
            </Link>{" "}
            mit uns auf. Wir überprüfen dann gerne, ob wir die Datenquelle für
            Sie einbinden können.
          </Typography>
        </>
      )}
      {step === 1 && (
        <>
          <Typography component="p" variant="body1">
            Möchten Sie nur dieses oder auch die angrenzenden Gebiete in ihr
            Verkehrsmodell laden?
          </Typography>
          <FormControl
            sx={{ mt: (theme) => theme.spacing(2) }}
            component="fieldset"
          >
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={areaRadioValue}
              onChange={handleAreaRadioValueChange}
            >
              <FormControlLabel
                value="SINGLE_AREA"
                control={<Radio />}
                label="Nur ausgewähltes Gebiet"
              />
              <FormControlLabel
                value="MULTI_AREA"
                control={<Radio />}
                label="Ausgewähltes inkl. angrenzende Gebiete"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      {step === 2 && (
        <>
          {Object.keys(formData).map((id, index, ids) => {
            const formElement = formData[id];
            const addMargin = index !== 0 && index < ids.length - 1;
            return (
              <LabeledTextField
                sx={{ my: (theme) => (addMargin ? theme.spacing(2) : 0) }}
                key={id}
                id={id}
                label={formElement.label}
                type={formElement.type}
                value={formElement.value}
                required={formElement.required}
                onChange={handleFormInputChange}
              />
            );
          })}
        </>
      )}
      {step === 3 && (
        <Typography>
          Ihre Demo wird nun erstellt. Wir werden Sie in Kürze kontaktieren und
          Ihnen den persönlich Zugangslink bereitstellen.
        </Typography>
      )}
    </BaseDialog>
  );
}
