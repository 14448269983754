import React from "react";
import { Box, Slider, SxProps, Theme, Typography } from "@mui/material";

interface LabeledSliderProps {
  sx?: SxProps<Theme>;
  label: string;
  value: number;
  onChange: (value: number) => void;
  max: number;
  min: number;
  step: number;
}

function LabeledSlider({
  sx,
  label,
  value,
  onChange,
  max,
  min,
  step,
}: LabeledSliderProps): React.ReactElement {
  const handleChange = (event: Event): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Type of Event does not contain value in target, but it exists there
    const newValue = event?.target?.value;
    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{ color: (theme) => theme.palette.blk.main }}
        component="p"
        variant="body2"
      >
        {label}
      </Typography>
      <Slider
        sx={{ ...sx }}
        value={value}
        onChange={handleChange}
        max={max}
        min={min}
        step={step}
        size="small"
      />
    </Box>
  );
}

LabeledSlider.defaultProps = {
  sx: undefined,
};

export default React.memo(LabeledSlider);
