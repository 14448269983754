const REGEX_RFC_5322_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const hasContent = (input: string): boolean => {
  if (input && typeof input === "string") {
    return input.trim().length > 0;
  }
  return false;
};

export const email = (input: string): boolean => {
  if (hasContent(input)) {
    const match = input.match(REGEX_RFC_5322_EMAIL);
    return match !== null && match.length > 0;
  }
  return false;
};

export const text = (input: string): boolean => hasContent(input);
