import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFlowData,
  getGeoJsonData,
  getGeoJsonProperties,
  getHeatmapData,
  getMarkerData,
  getTazesGeoJsonData,
} from "../../../api/layers/layer-api";
import {
  FetchFlowDataParams,
  FetchGeoJsonDataParams,
  FetchHeatmapDataParams,
  FetchMarkerDataParams,
  FetchTazesGeoJsonDataParams,
} from "./types";

export const fetchMarkerData = createAsyncThunk(
  "layers/marker-data",
  async (params: FetchMarkerDataParams) => {
    const response = await getMarkerData(params.dataId);
    return response.data;
  }
);

export const fetchHeatmapData = createAsyncThunk(
  "layers/heatmap-data",
  async (params: FetchHeatmapDataParams) => {
    const response = await getHeatmapData(
      params.dataset,
      params.attrib,
      params.interval,
      params.fromMs,
      params.toMs
    );
    return response.data;
  }
);

export const fetchGeoJsonData = createAsyncThunk(
  "layers/geo-json-data",
  async (params: FetchGeoJsonDataParams) => {
    const responses = await Promise.all([
      getGeoJsonProperties(params.dataCategoryId),
      getGeoJsonData(params.dataCategoryId),
    ]);
    return {
      properties: responses[0].data.properties,
      geoJson: responses[1].data,
    };
  }
);

export const fetchTazesGeoJsonData = createAsyncThunk(
  "layers/tazes-geo-json-data",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: FetchTazesGeoJsonDataParams) => {
    const responses = await Promise.all([
      getGeoJsonProperties("areas"),
      getTazesGeoJsonData(),
    ]);
    return {
      properties: responses[0].data.properties,
      geoJson: responses[1].data,
    };
  }
);

export const fetchFlowData = createAsyncThunk(
  "layers/flow-data",
  async (params: FetchFlowDataParams) => {
    const response = await getFlowData(
      params.dataset,
      params.flowCount,
      params.fromMs,
      params.toMs
    );
    return response.data;
  }
);
