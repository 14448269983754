import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { formatTimestampToMonthYear } from "../../../tech/utils/TimestampFormatter";
import { ChevronLeftIcon } from "../../icons/ChevronLeftIcon";
import { ChevronRightIcon } from "../../icons/ChevronRightIcon";

function CustomPickerHeader({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: Partial<ReactDatePickerCustomHeaderProps>): React.ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {customHeaderCount === 1 ? (
        <Box sx={{ width: 10, height: 15, m: (theme) => theme.spacing(2) }} />
      ) : (
        <ChevronLeftIcon
          sx={{
            width: 10,
            height: 15,
            m: (theme) => theme.spacing(2),
            color: (theme) => theme.palette.grey[300],
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.grey[500],
            },
          }}
          onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}
        />
      )}
      <Typography sx={{ lineHeight: 1 }} component="p" variant="body1">
        {monthDate
          ? formatTimestampToMonthYear(monthDate.toISOString())
          : "Monat"}
      </Typography>
      {customHeaderCount === 0 ? (
        <Box sx={{ width: 10, height: 15, m: (theme) => theme.spacing(2) }} />
      ) : (
        <ChevronRightIcon
          sx={{
            width: 10,
            height: 15,
            m: (theme) => theme.spacing(2),
            color: (theme) => theme.palette.grey[300],
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.grey[500],
            },
          }}
          onClick={nextMonthButtonDisabled ? undefined : increaseMonth}
        />
      )}
    </Box>
  );
}

export default React.memo(CustomPickerHeader);
