import { combineReducers, configureStore } from "@reduxjs/toolkit";
import layerReducer from "./slices/layers/slice";
import layerCategoryReducer from "./slices/layer-categories/slice";
import mapReducer from "./slices/maps/slice";
import poiReducer from "./slices/pois/slice";

const rootReducer = combineReducers({
  layerCategoryReducer,
  layerReducer,
  mapReducer,
  poiReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
