import { Breakpoint, Dialog, SxProps, Theme } from "@mui/material";
import { ActionButtonProps, BaseDialogActions } from "./BaseDialogActions";
import { BaseDialogContent } from "./BaseDialogContent";
import { BaseDialogTitle } from "./BaseDialogTitle";

interface BaseDialogProps {
  sx?: SxProps<Theme>;
  open: boolean;
  onClose: React.MouseEventHandler;
  title: React.ReactNode;
  children: React.ReactNode;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  primaryButton?: ActionButtonProps;
  secondaryButton?: ActionButtonProps;
  hideCancelButton?: boolean;
}

export function BaseDialog({
  sx,
  open,
  onClose,
  title,
  children,
  maxWidth,
  fullWidth,
  primaryButton,
  secondaryButton,
  hideCancelButton,
}: BaseDialogProps): React.ReactElement {
  return (
    <Dialog
      sx={sx}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      onClose={onClose}
    >
      <BaseDialogTitle onClose={onClose}>{title}</BaseDialogTitle>
      <BaseDialogContent>{children}</BaseDialogContent>
      <BaseDialogActions
        onCancel={onClose}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        hideCancelButton={hideCancelButton}
      />
    </Dialog>
  );
}

BaseDialog.defaultProps = {
  sx: undefined,
  maxWidth: "sm",
  fullWidth: false,
  primaryButton: undefined,
  secondaryButton: undefined,
  hideCancelButton: false,
};
