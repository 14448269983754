import React from "react";
import { Box, Paper, Theme, Typography, SxProps } from "@mui/material";
import { EditIcon } from "../../../icons/EditIcon";

interface ControllerBaseProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  label: string;
}

function ControllerBase({
  sx,
  children,
  label,
}: ControllerBaseProps): React.ReactElement {
  return (
    <Paper
      sx={{
        width: 150,
        py: (theme) => theme.spacing(2),
        px: (theme) => theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        border: 2,
        borderColor: (theme) => theme.palette.primary.main,
        borderRadius: "6px",
        ...sx,
      }}
      elevation={2}
    >
      <Box
        sx={{
          width: 43,
          minWidth: 43,
          height: 43,
          minHeight: 43,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          color: (theme) => theme.palette.wht.main,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <EditIcon />
      </Box>
      <Box
        sx={{
          width: "100%",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            my: (theme) => theme.spacing(2.5),
            px: (theme) => theme.spacing(1),
            textAlign: "center",
          }}
          component="p"
          variant="h3"
        >
          {label}
        </Typography>
        {children}
      </Box>
    </Paper>
  );
}

ControllerBase.defaultProps = {
  sx: undefined,
};

export default React.memo(ControllerBase);
