import React, { useEffect, useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import { isValid } from "date-fns";
import { formatTimestampToDate } from "../../../tech/utils/TimestampFormatter";
import { CalendarIcon } from "../../icons/CalendarIcon";
import InlineDatePicker from "./InlineDatePicker";
import DateInput from "./DateInput";
import CustomButton from "../buttons/CustomButton";
import { CustomButtonColor } from "../buttons/types";

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  variant?: "text" | "contained" | "outlined";
  color?: CustomButtonColor;
  showInputHeader?: boolean;
  SidePanelLeft?: React.ReactNode;
  SidePanelRight?: React.ReactNode;
}

export default function DateRangePicker({
  startDate,
  endDate,
  onChange,
  variant,
  color,
  showInputHeader,
  SidePanelLeft,
  SidePanelRight,
}: DateRangePickerProps): React.ReactElement {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedStartDate(startDate);
  }, [startDate]);

  useEffect(() => {
    setSelectedEndDate(endDate);
  }, [endDate]);

  const handleOpenPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target instanceof HTMLInputElement) {
      // NOTE: Prevent close if event comes from input element in menu
      return;
    }
    setAnchorEl(null);
  };

  const handleChangeDate = (
    dates: [Date | null, Date | null],
    disableClosePicker?: boolean,
    event?: React.SyntheticEvent
  ) => {
    event?.preventDefault();
    const [newStartDate, newEndDate] = dates;
    if (
      (newStartDate === null || isValid(newStartDate)) &&
      (newEndDate === null || isValid(newEndDate))
    ) {
      onChange(newStartDate, newEndDate);
      if (!disableClosePicker && newEndDate) {
        setAnchorEl(null);
      }
    }
  };

  const handleDisableCloseOnTab = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === "Tab") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <CustomButton
        sx={{
          pointerEvents: "all",
          py: (theme) => theme.spacing(0.5),
          px: (theme) => theme.spacing(2),
          minWidth: 140,
          maxWidth: 140,
          height: 43,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: (theme) => theme.spacing(2),
        }}
        variant={variant}
        color={color}
        onClick={handleOpenPicker}
      >
        <CalendarIcon sx={{ minWidth: 21, minHeight: 19 }} />
        <Typography
          sx={{ textAlign: "start", fontWeight: 500 }}
          component="p"
          variant="body2"
        >
          {startDate
            ? formatTimestampToDate(startDate.toISOString())
            : "dd.mm.yyyy"}{" "}
          &#8211;{" "}
          {endDate
            ? formatTimestampToDate(endDate.toISOString())
            : "dd.mm.yyyy"}
        </Typography>
      </CustomButton>
      <Popover
        elevation={2}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePicker}
        onKeyDown={handleDisableCloseOnTab}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            transform: "translateY(8px)",
            display: "flex",
            flexDirection: "row",
            padding: "16px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {SidePanelLeft && (
          <Box
            sx={{
              pr: (theme) => theme.spacing(1),
              borderRight: 1,
              borderColor: (theme) => theme.palette.grey[400],
              maxWidth: 150,
            }}
          >
            {SidePanelLeft}
          </Box>
        )}
        <Box>
          {showInputHeader && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                pt: (theme) => theme.spacing(1),
                gap: (theme) => theme.spacing(1),
              }}
              component="form"
            >
              <DateInput
                sx={{ width: 72 }}
                value={selectedStartDate}
                onChange={(newDate) => handleChangeDate([newDate, null], true)}
                variant="standard"
                tabIndex={0}
              />
              <Typography component="p" variant="body1">
                &#8211;
              </Typography>
              <DateInput
                sx={{ width: 72 }}
                value={selectedEndDate}
                onChange={(newDate) =>
                  handleChangeDate([selectedStartDate, newDate], true)
                }
                variant="standard"
                tabIndex={-1}
              />
            </Box>
          )}
          <InlineDatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={(dates, event) => handleChangeDate(dates, false, event)}
          />
        </Box>
        {SidePanelRight && (
          <Box
            sx={{
              pl: (theme) => theme.spacing(1),
              borderLeft: 1,
              borderColor: (theme) => theme.palette.grey[400],
              maxWidth: 150,
            }}
          >
            {SidePanelRight}
          </Box>
        )}
      </Popover>
    </>
  );
}

DateRangePicker.defaultProps = {
  variant: "contained",
  color: "primary",
  showInputHeader: false,
  SidePanelLeft: undefined,
  SidePanelRight: undefined,
};
