/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  DebugLayerCategory,
  LayerCategory,
  LayerCategoryId,
  LayerCategoryState,
} from "./types";

const initialState: LayerCategoryState = {
  expanded: false,
  layerCategoriesById: {
    situation: {
      id: "situation",
      label: "Verkehrslage",
      expanded: false,
    },
    network: {
      id: "network",
      label: "Verkehrsnetze",
      expanded: false,
    },
    demand: {
      id: "demand",
      label: "Verkehrsnachfrage",
      expanded: false,
    },
  },
  debugCategory: {
    id: "debug",
    label: "Debug Modus",
    visible: false,
    expanded: false,
  },
};

export const layerCategorySlice = createSlice({
  name: "layer-categories",
  initialState,
  reducers: {
    toggleLayerCategories: (state) => {
      state.expanded = !state.expanded;
    },
    toggleLayerCategoryById: (
      state,
      action: PayloadAction<LayerCategoryId>
    ) => {
      const id = action.payload;
      state.layerCategoriesById[id].expanded =
        !state.layerCategoriesById[id].expanded;
    },
    toggleDebugCategoryVisibilty: (state) => {
      state.debugCategory.visible = !state.debugCategory.visible;
    },
    toggleDebugCategory: (state) => {
      state.debugCategory.expanded = !state.debugCategory.expanded;
    },
  },
  extraReducers: {},
});

export const selectAllLayerCategories = (state: RootState): LayerCategory[] =>
  Object.values(state.layerCategoryReducer.layerCategoriesById);

export const selectDebugCategory = (state: RootState): DebugLayerCategory =>
  state.layerCategoryReducer.debugCategory;

export const selectLayerCategoryById = (
  state: RootState,
  id: LayerCategoryId
): LayerCategory => state.layerCategoryReducer.layerCategoriesById[id];

export const layerCategoriesExpanded = (state: RootState): boolean =>
  state.layerCategoryReducer.expanded;

export const {
  toggleLayerCategories,
  toggleLayerCategoryById,
  toggleDebugCategoryVisibilty,
  toggleDebugCategory,
} = layerCategorySlice.actions;

export default layerCategorySlice.reducer;
