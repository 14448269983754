import Content from "../components/layout/Content";
import DeckGLMap from "../components/map/DeckGLMap";
import Contributions from "../components/map/contributions/Contributions";
import MapHeader from "../components/layout/header/MapHeader";
import NavigationControls from "../components/map/controls/navigation/NavigationControls";
import { MapConfigId } from "../store/slices/maps/types";

const MAP_CONFIG_ID: MapConfigId = "main";

export default function MapPage(): React.ReactElement {
  return (
    <Content fullScreenMode>
      <MapHeader
        sx={{
          position: "absolute",
          top: (theme) => theme.spacing(1),
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
        mapConfigId={MAP_CONFIG_ID}
      />
      <NavigationControls
        sx={{
          position: "absolute",
          bottom: (theme) => theme.spacing(8),
          right: (theme) => theme.spacing(1),
          zIndex: (theme) => theme.zIndex.appBar,
        }}
        mapConfigId={MAP_CONFIG_ID}
      />
      <DeckGLMap
        sx={{ width: "100%", height: "99vh" }}
        additionalContributions={<Contributions />}
        mapConfigId={MAP_CONFIG_ID}
      />
    </Content>
  );
}
