import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ChevronLeftIcon({
  sx,
  onClick,
}: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 10, height: 15, ...sx }}
      component="svg"
      viewBox="0 0 10 15"
      onClick={onClick}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="chevron-closed"
          transform="translate(-4.000000, 3.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M9.24710111,8.23302071 L15.4443264,1.68752411 C15.8240355,1.28647616 15.8067369,0.653547531 15.405689,0.273838449 C15.2199653,0.097996764 14.9739256,-1.25019467e-14 14.7181649,-1.15463195e-14 L2.32385896,-1.15463195e-14 C1.77157421,-1.14448664e-14 1.32385896,0.44771525 1.32385896,1 C1.32385896,1.25575673 1.42185264,1.5017929 1.59768939,1.68751565 L7.79476999,8.23301225 C8.1744744,8.63406462 8.80740283,8.65137057 9.2084552,8.27166617 C9.22168685,8.25913884 9.23457363,8.24625221 9.24710111,8.23302071 Z"
            transform="translate(8.521031, 4.500000) rotate(-270.000000) translate(-8.521031, -4.500000) "
          />
        </g>
      </g>
    </Box>
  );
}

ChevronLeftIcon.defaultProps = {
  sx: undefined,
  onClick: undefined,
};
