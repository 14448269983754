import axios, { AxiosResponse } from "axios";

export async function getZensusInspireGridByWms(
  layers: string,
  bbox: string
): Promise<AxiosResponse<ArrayBuffer>> {
  return axios.get<ArrayBuffer>(
    `https://www.wms.nrw.de/wms/zensusatlas?SERVICE=WMS&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=TRUE&STYLES=default&LAYERS=${layers}&VERSION=1.3.0&WIDTH=256&HEIGHT=256&CRS=EPSG:4326&BBOX=${bbox}`,
    { responseType: "arraybuffer" }
  );
}

export async function getZensusWmsFeatureInfo(
  layers: string,
  bbox: string,
  x: string,
  y: string
): Promise<AxiosResponse<string>> {
  return axios.get<string>(
    `https://www.wms.nrw.de/wms/zensusatlas?SERVICE=WMS&REQUEST=GetFeatureInfo&LAYERS=${layers}&QUERY_LAYERS=${layers}&VERSION=1.3.0&INFO_FORMAT=text/plain&WIDTH=256&HEIGHT=256&CRS=EPSG:4326&BBOX=${bbox}&X=${x}&Y=${y}`
  );
}

export async function getZensusInspireGridWmsTiles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any
): Promise<ImageBitmap[] | null> {
  if (!params?.bbox) {
    return null;
  }
  const wmsBbox = `${params.bbox.south},${params.bbox.west},${params.bbox.north},${params.bbox.east}`;
  const response = await getZensusInspireGridByWms("gitter", wmsBbox);
  const imageBlob = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  const imageBitmap = await createImageBitmap(imageBlob);
  return [imageBitmap];
}
