import { Box } from "@mui/material";
import { IconProps } from "./types";

export function EditIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 21, height: 21, ...sx }}
      component="svg"
      viewBox="0 0 21 21"
    >
      <path
        d="M17.066 0a3.92 3.92 0 0 0-2.78 1.15L1.087 14.348l5.566 5.565c4.4-4.398 8.798-8.797 13.197-13.196a3.94 3.94 0 0 0-.004-5.565A3.921 3.921 0 0 0 17.067 0zM.405 15.52l-.403 4.77a.656.656 0 0 0 .71.708l4.765-.405L.405 15.52z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

EditIcon.defaultProps = {
  sx: undefined,
};
