/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { PoiKey } from "../../../api/pois/types";
import type { RootState } from "../../store";
import { fetchPoiKeys } from "./thunks";
import { PoiState } from "./types";

const initialState: PoiState = {
  poiKeysById: {},
  poiKeyStatus: { status: "idle" },
};

export const poiSlice = createSlice({
  name: "pois",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPoiKeys.pending, (state) => {
      state.poiKeyStatus.status = "loading";
    });
    builder.addCase(fetchPoiKeys.fulfilled, (state, action) => {
      action.payload.forEach((poiKey) => {
        state.poiKeysById[poiKey.id] = poiKey;
      });
      state.poiKeyStatus.status = "succeeded";
    });
    builder.addCase(fetchPoiKeys.rejected, (state, action) => {
      state.poiKeyStatus = {
        status: "failed",
        error: action.error.message,
      };
    });
  },
});

export const selectAllPoiKeys = (state: RootState): PoiKey[] =>
  Object.values(state.poiReducer.poiKeysById);

export default poiSlice.reducer;
