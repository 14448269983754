import React, { useState, useContext, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ApiContext from "../../tech/context/ApiContext";
import BackdropContext from "../../tech/context/BackdropContext";
import Logger from "../../tech/utils/Logger";
import { Commit } from "../../api/versioning/types";
import { postCreateMerge } from "../../api/versioning/versioning-api";
import { BaseDialog } from "./base/BaseDialog";

interface MergeDialogProps {
  open: boolean;
  onClose: () => void;
  commit: Commit;
  onSuccess?: () => void;
}

export default function MergeDialog({
  open,
  onClose,
  commit,
  onSuccess,
}: MergeDialogProps): React.ReactElement {
  const { merges, branches, fetchDatabaseData } = useContext(ApiContext);
  const { setBackdropActive } = useContext(BackdropContext);
  const [selectedTargetBranchId, setSelectedTargetBranchId] = useState("");
  const [sourceBranchId, setSourceBranchId] = useState("");
  const [sourceBranchName, setSourceBranchName] = useState("");

  useEffect(() => {
    if (open) {
      let id = commit.branchId;
      let name = "";

      merges.some((merge) => {
        if (merge.sourceBranchId === id) {
          id = merge.targetBranchId;
          return true;
        }
        return false;
      });

      branches.some((branch) => {
        if (branch.id === id) {
          name = branch.name;
          return true;
        }
        return false;
      });

      setSourceBranchId(id);
      setSourceBranchName(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleTargetListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: string
  ): void => {
    setSelectedTargetBranchId(index);
  };

  const handleClose = (): void => {
    setSelectedTargetBranchId("");
    onClose();
  };

  const merge = (
    mergeSourceBranchId: string,
    mergeTargetBranchId: string
  ): void => {
    setBackdropActive(true);
    postCreateMerge(mergeSourceBranchId, mergeTargetBranchId)
      .then(() => fetchDatabaseData())
      .then(() => {
        setBackdropActive(false);
        handleClose();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        Logger.error(error);
      });
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      title={`Merge Branch ${sourceBranchName} in ...`}
      primaryButton={{
        onClick: () => merge(sourceBranchId, selectedTargetBranchId),
        disabled: !selectedTargetBranchId,
        label: "Übernehmen",
      }}
    >
      <List component="nav" aria-label="secondary mailbox folder">
        {branches
          ?.filter(
            (branch) =>
              branch.id !== commit.branchId &&
              branch.status !== "MERGED" &&
              branch.id !== sourceBranchId
          )
          .map((branch) => (
            <ListItem
              key={branch.id}
              button
              selected={selectedTargetBranchId === branch.id}
              onClick={(event) => handleTargetListItemClick(event, branch.id)}
            >
              <ListItemText primary={branch.name} />
            </ListItem>
          ))}
      </List>
    </BaseDialog>
  );
}

MergeDialog.defaultProps = {
  onSuccess: undefined,
};
