import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { PoiKeys } from "./types";

export async function getPoiKeys(): Promise<AxiosResponse<PoiKeys>> {
  return AxiosClient.get(`/core/markers/poi-keys`);
}

export async function postResetPois(): Promise<AxiosResponse<void>> {
  return AxiosClient.post("/core/markers/reset-pois/");
}
