import React, { useState } from "react";
import { ContextProviderProps } from "./types";
import BackdropContext from "./BackdropContext";

export default function BackdropContextProvider({
  children,
}: ContextProviderProps): React.ReactElement {
  const [isBackdropActive, setBackdropActive] = useState(false);

  return (
    <BackdropContext.Provider
      // TODO: memoize context
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isBackdropActive,
        setBackdropActive,
      }}
    >
      {children}
    </BackdropContext.Provider>
  );
}
