import React from "react";
import { AdminBoundaryIcon } from "./AdminBoundaryIcon";
import { BikeNetworkIcon } from "./BikeNetworkIcon";
import { BurgerIcon } from "./BurgerIcon";
import { CalendarIcon } from "./CalendarIcon";
import { CapacityIcon } from "./CapacityIcon";
import { ChangeListIcon } from "./ChangeListIcon";
import { ChevronAllClosedIcon } from "./ChevronAllClosedIcon";
import { ChevronAllOpenIcon } from "./ChevronAllOpenIcon";
import { ChevronClosedIcon } from "./ChevronClosedIcon";
import { ChevronLeftIcon } from "./ChevronLeftIcon";
import { ChevronOpenIcon } from "./ChevronOpenIcon";
import { ChevronRightIcon } from "./ChevronRightIcon";
import { CloseIcon } from "./CloseIcon";
import { CommuterIcon } from "./CommuterIcon";
import { EditIcon } from "./EditIcon";
import { ExportIcon } from "./ExportIcon";
import { ImportIcon } from "./ImportIcon";
import { InductionLoopIcon } from "./InductionLoopIcon";
import { LocationCenteredIcon } from "./LocationCenteredIcon";
import { LocationNotCenteredIcon } from "./LocationNotCenteredIcon";
import { MagnifyingGlassIcon } from "./MagnifyingGlassIcon";
import { PointsOfInterestIcon } from "./PointsOfInterestIcon";
import { ProfileIcon } from "./ProfileIcon";
import { ResetIcon } from "./ResetIcon";
import { RulesIcon } from "./RulesIcon";
import { SaveIcon } from "./SaveIcon";
import { SchoolsIcon } from "./SchoolsIcon";
import { StreetNetworkIcon } from "./StreetNetworkIcon";
import { TazIcon } from "./TazIcon";
import { TrafficFlowDailyIcon } from "./TrafficFlowDailyIcon";
import { TrafficFlowAvgIcon } from "./TrafficFlowAvgIcon";
import { TrafficInformationIcon } from "./TrafficInformationIcon";
import { TrafficOccupancyAvgIcon } from "./TrafficOccupancyAvgIcon";
import { TrafficOccupancyDailyIcon } from "./TrafficOccupancyDailyIcon";
import { VersionsIcon } from "./VersionsIcon";
import { ZensusRasterBigIcon } from "./ZensusRasterBigIcon";
import { ZensusRasterSmallIcon } from "./ZensusRasterSmallIcon";
import { DeleteIcon } from "./DeleteIcon";
import { EditOutlineIcon } from "./EditOutlineIcon";
import { VisibilityIcon } from "./VisibilityIcon";
import { VisibilityInactiveIcon } from "./VisibilityInactiveIcon";

export default class IconFactory {
  static createIcon(id: string): React.ReactElement {
    switch (id) {
      case "AdminBoundaryIcon":
        return <AdminBoundaryIcon />;
      case "BikeNetworkIcon":
        return <BikeNetworkIcon />;
      case "BurgerIcon":
        return <BurgerIcon />;
      case "CalendarIcon":
        return <CalendarIcon />;
      case "CapacityIcon":
        return <CapacityIcon />;
      case "ChangeListIcon":
        return <ChangeListIcon />;
      case "ChevronAllClosedIcon":
        return <ChevronAllClosedIcon />;
      case "ChevronAllOpenIcon":
        return <ChevronAllOpenIcon />;
      case "ChevronClosedIcon":
        return <ChevronClosedIcon />;
      case "ChevronLeftIcon":
        return <ChevronLeftIcon />;
      case "ChevronOpenIcon":
        return <ChevronOpenIcon />;
      case "ChevronRightIcon":
        return <ChevronRightIcon />;
      case "CloseIcon":
        return <CloseIcon />;
      case "CommuterIcon":
        return <CommuterIcon />;
      case "DeleteIcon":
        return <DeleteIcon />;
      case "EditIcon":
        return <EditIcon />;
      case "EditOutlineIcon":
        return <EditOutlineIcon />;
      case "ExportIcon":
        return <ExportIcon />;
      case "ImportIcon":
        return <ImportIcon />;
      case "InductionLoopIcon":
        return <InductionLoopIcon />;
      case "LocationCenteredIcon":
        return <LocationCenteredIcon />;
      case "LocationNotCenteredIcon":
        return <LocationNotCenteredIcon />;
      case "MagnifyingGlassIcon":
        return <MagnifyingGlassIcon />;
      case "PointsOfInterestIcon":
        return <PointsOfInterestIcon />;
      case "ProfileIcon":
        return <ProfileIcon />;
      case "ResetIcon":
        return <ResetIcon />;
      case "RulesIcon":
        return <RulesIcon />;
      case "SaveIcon":
        return <SaveIcon />;
      case "SchoolsIcon":
        return <SchoolsIcon />;
      case "StreetNetworkIcon":
        return <StreetNetworkIcon />;
      case "TazIcon":
        return <TazIcon />;
      case "TrafficFlowAvgIcon":
        return <TrafficFlowAvgIcon />;
      case "TrafficFlowDailyIcon":
        return <TrafficFlowDailyIcon />;
      case "TrafficInformationIcon":
        return <TrafficInformationIcon />;
      case "TrafficOccupancyAvgIcon":
        return <TrafficOccupancyAvgIcon />;
      case "TrafficOccupancyDailyIcon":
        return <TrafficOccupancyDailyIcon />;
      case "VersionsIcon":
        return <VersionsIcon />;
      case "VisibilityIcon":
        return <VisibilityIcon />;
      case "VisibilityInactiveIcon":
        return <VisibilityInactiveIcon />;
      case "ZensusRasterBigIcon":
        return <ZensusRasterBigIcon />;
      case "ZensusRasterSmallIcon":
        return <ZensusRasterSmallIcon />;
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  }
}
