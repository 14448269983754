import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import CommitsTable from "../../components/table/CommitsTable";
import MergedCommitsTable from "../../components/table/MergedCommitsTable";
import CommitDetailsMap from "../../components/map/CommitDetailsMap";
import BackdropContext from "../../tech/context/BackdropContext";
import BasicLayout from "../../components/layout/BaseLayout";
import { getCommitById } from "../../api/versioning/versioning-api";
import { Commit } from "../../api/versioning/types";

export default function ChangeDetailsPage(): React.ReactElement {
  const [commit, setCommit] = useState<Commit>();
  const { id } = useParams();
  const { setBackdropActive } = useContext(BackdropContext);

  const onRefresh = (): void => {
    if (id) {
      getCommitById(id).then((response) => {
        setCommit(response.data);
      });
    }
  };

  useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (commit) {
      setBackdropActive(false);
    } else {
      setBackdropActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commit]);

  if (!commit) {
    // TODO: 404 Commit not found
    return <div />;
  }

  if (commit.processEnd) {
    return (
      <BasicLayout>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ mb: (theme) => theme.spacing(4) }}
            component="h1"
            variant="h1"
          >
            Detailansicht
          </Typography>
          <MergedCommitsTable
            sx={{ height: "max-content", mb: (theme) => theme.spacing(2) }}
            disableActionButtons
            disablePagination
            commits={commit ? [commit] : []}
          />
          <CommitDetailsMap sx={{ height: 0, flexGrow: 1 }} commit={commit} />
        </Box>
      </BasicLayout>
    );
  }

  return (
    <BasicLayout>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{ mb: (theme) => theme.spacing(4) }}
          component="h1"
          variant="h1"
        >
          Detailansicht
        </Typography>
        <CommitsTable
          sx={{ height: "max-content", mb: (theme) => theme.spacing(2) }}
          disableActionDetails
          disablePagination
          commits={commit ? [commit] : []}
        />
        <CommitDetailsMap sx={{ height: 0, flexGrow: 1 }} commit={commit} />
      </Box>
    </BasicLayout>
  );
}
