import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { SxProps, Theme, Box } from "@mui/material";
import { TableColumn } from "./types";
import { formatTimestampToDate } from "../../tech/utils/TimestampFormatter";
import DropDown, { DropDownItem } from "../custom/DropDown";
import MergeDialog from "../dialog/MergeDialog";
import PushAndCreateDialog from "../dialog/PushAndCreateDialog";
import { Commit } from "../../api/versioning/types";

interface CommitsTableProps {
  sx?: SxProps<Theme>;
  commits: Commit[];
  disableActionButtons?: boolean;
  disableActionDetails?: boolean;
  disableActionBranching?: boolean;
  disableActionDiscard?: boolean;
  disablePagination?: boolean;
}

const columns: TableColumn[] = [
  { id: "title", label: "Titel", minWidth: 50, maxWidth: 250, align: "left" },
  {
    id: "description",
    label: "Beschreibung",
    minWidth: 10,
    maxWidth: 600,
    align: "left",
  },
  { id: "creation", label: "Einstelldatum", minWidth: 50, align: "left" },
  { id: "timeRange", label: "Zeitraum", minWidth: 50, align: "left" },
  {
    id: "type",
    label: "Kategorie",
    minWidth: 50,
    maxWidth: 200,
    align: "left",
  },
  { id: "createdBy", label: "Quelle", minWidth: 50, align: "left" },
];

export default function CommitsTable({
  sx,
  commits,
  disableActionButtons,
  disableActionDetails,
  disableActionBranching,
  disableActionDiscard,
  disablePagination,
}: CommitsTableProps): React.ReactElement {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noneMasterCommitsLength, setNoneMasterCommitsLength] = useState(0);
  const [filteredCommits, setFilteredCommits] = useState<Commit[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCommit, setSelectedCommit] = useState<Commit | undefined>();

  useEffect(() => {
    const commitsWithoutProcessEnd: Commit[] = commits.filter(
      (commit) => !commit.processEnd
    );
    setFilteredCommits(commitsWithoutProcessEnd);
    setNoneMasterCommitsLength(commitsWithoutProcessEnd.length);
  }, [commits]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDropDownItemClicked = (
    event: React.MouseEvent,
    commit: Commit
  ): void => {
    const { id } = event.currentTarget;
    switch (id) {
      case "menu-item-change-details":
        navigate(`/changes/${commit.id}`);
        break;
      case "menu-item-change-branching":
        setSelectedCommit(commit);
        setDialogOpen(true);
        break;
      case "menu-item-change-discard":
        // TODO: Implement "Änderung verwerfen"
        break;
      default:
        break;
    }
  };

  const handleDialogClose = (): void => {
    setSelectedCommit(undefined);
    setDialogOpen(false);
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: (theme) => theme.palette.grey[300],
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column: TableColumn) => (
                <TableCell
                  sx={{ minWidth: column.minWidth, width: column.maxWidth }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
              {!disableActionButtons && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommits && filteredCommits.length > 0 ? (
              filteredCommits
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((commit) => (
                  <TableRow key={commit.id}>
                    <TableCell>{commit.title}</TableCell>
                    <TableCell>{commit.description}</TableCell>
                    <TableCell>
                      {formatTimestampToDate(commit.createdAt)}
                    </TableCell>
                    <TableCell>
                      {`${formatTimestampToDate(
                        commit.startsAt
                      )} - ${formatTimestampToDate(commit.endsAt)}`}
                    </TableCell>
                    <TableCell>{commit.type}</TableCell>
                    <TableCell>{commit.createdBy}</TableCell>
                    {!disableActionButtons && (
                      <TableCell align="center">
                        <DropDown label="Änderung">
                          {!disableActionDetails && (
                            <DropDownItem
                              id="menu-item-change-details"
                              onClick={(event) =>
                                handleDropDownItemClicked(event, commit)
                              }
                            >
                              Detailansicht
                            </DropDownItem>
                          )}
                          {!disableActionBranching && (
                            <DropDownItem
                              id="menu-item-change-branching"
                              onClick={(event) =>
                                handleDropDownItemClicked(event, commit)
                              }
                            >
                              In Branch übernehmen
                            </DropDownItem>
                          )}
                          {!disableActionDiscard && (
                            <DropDownItem
                              id="menu-item-change-discard"
                              onClick={(event) =>
                                handleDropDownItemClicked(event, commit)
                              }
                            >
                              Verwerfen
                            </DropDownItem>
                          )}
                        </DropDown>
                      </TableCell>
                    )}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: "center" }}
                  colSpan={
                    disableActionButtons ? columns.length : columns.length + 1
                  }
                >
                  Zur Zeit gibt es keine Einträge in der Änderungsliste.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            py: (theme) => theme.spacing(1),
            px: (theme) => theme.spacing(2),
            borderTop: 1,
            borderColor: (theme) => theme.palette.grey[200],
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={noneMasterCommitsLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      {selectedCommit &&
        (selectedCommit.branchId ? (
          <MergeDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            commit={selectedCommit}
          />
        ) : (
          <PushAndCreateDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            commit={selectedCommit}
          />
        ))}
    </Box>
  );
}

CommitsTable.defaultProps = {
  sx: undefined,
  disableActionButtons: false,
  disableActionDetails: false,
  disableActionBranching: false,
  disableActionDiscard: false,
  disablePagination: false,
};
