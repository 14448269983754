import KeycloakInstance, { KeycloakConfig } from "keycloak-js";
import Logger from "../tech/utils/Logger";

const TOKEN_VALIDITY_SECONDS = 60;

const keycloakConfig: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL || "",
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
};

const keycloakInstance = new KeycloakInstance(keycloakConfig);

export const doLogin = keycloakInstance.login;

export const doLogout = keycloakInstance.logout;

export const initKeycloak = async (
  onAuthenticated: () => void
): Promise<void> => {
  try {
    const authenticated = await keycloakInstance.init({
      onLoad: "login-required",
      redirectUri: `${process.env.REACT_APP_KEYCLOAK_REDIRECT_URL}${window.location.pathname}`,
    });
    if (authenticated) {
      onAuthenticated();
    } else {
      Logger.error("Authentication failed!");
      doLogin();
    }
  } catch (error) {
    Logger.error(error);
    doLogin();
  }
};

export const getToken = (): string | undefined => keycloakInstance.token;

export const updateToken = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: () => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    await keycloakInstance.updateToken(TOKEN_VALIDITY_SECONDS);
    return await onSuccess();
  } catch (error) {
    return doLogin();
  }
};

export const getUsername = (): string | undefined =>
  keycloakInstance.profile?.username ||
  keycloakInstance.tokenParsed?.preferred_username;

export const getFirstName = (): string | undefined =>
  keycloakInstance.profile?.firstName ||
  keycloakInstance.tokenParsed?.given_name;

export const getLastName = (): string | undefined =>
  keycloakInstance.profile?.lastName ||
  keycloakInstance.tokenParsed?.family_name;

export const getFullName = (): string | undefined => {
  const firstName = getFirstName();
  const lastName = getLastName();
  if (!firstName && !lastName) {
    return undefined;
  }
  let fullName = firstName;
  if (fullName && lastName) {
    fullName = `${fullName} ${lastName}`;
  }
  return fullName;
};

export const isLoggedIn = (): boolean => !!keycloakInstance.token;

export const hasRole = (roles: string[]): boolean =>
  roles.some((role) => keycloakInstance.hasRealmRole(role));

// TODO: determine flag based on user token claim
export const enableDebugMode = (): boolean => true;
