import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import GitGraph from "../../components/versioning/GitGraph";
import BasicLayout from "../../components/layout/BaseLayout";

export default function VersionsBoardPage(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1">
            Versionsboard
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ overflowX: "auto", pb: (theme) => theme.spacing(2) }}
        >
          <GitGraph />
        </Grid>
      </Grid>
      <Button
        sx={{
          zIndex: (theme) => theme.zIndex.appBar - 1,
          position: "fixed",
          top: 145,
          right: "calc((100% / 10) - (100vw - 100%))",
          py: (theme) => theme.spacing(2),
          px: (theme) => theme.spacing(4),
        }}
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => navigate("/export")}
      >
        Branch exportieren
      </Button>
    </BasicLayout>
  );
}
