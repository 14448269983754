/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useContext } from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import { PickInfo } from "deck.gl";
import DeckGLMap from "../../components/map/DeckGLMap";
import GeoJsonLayer from "../../components/map/layer/GeoJsonLayer";
import BackdropContext from "../../tech/context/BackdropContext";
import AreaSelectionDialog from "../../components/dialog/AreaSelectionDialog";
import BasicLayout from "../../components/layout/BaseLayout";
import { GeoJsonData } from "../../api/layers/types";
import { getGeoJsonData } from "../../api/layers/layer-api";
import Logger from "../../tech/utils/Logger";

type AreaType = "DISTRICT";

interface ClickedArea {
  type: AreaType;
  id: string;
}

export default function AreaSelectionPage(): React.ReactElement {
  const { setBackdropActive } = useContext(BackdropContext);
  const [clickedArea, setClickedArea] = useState<ClickedArea | undefined>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [layerData, setLayerData] = useState<GeoJsonData>();

  useEffect(() => {
    if (!isLoading) {
      setLoading(true);
      getGeoJsonData("brd_district_boundaries")
        .then((response) => {
          setLayerData({
            properties: [],
            geoJson: response.data,
          });
          setLoading(false);
        })
        .catch((error) => {
          Logger.error(error);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBackdropActive(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (clickedArea && !isLoading && clickedArea.type === "DISTRICT") {
      setDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedArea]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSearchValue(event.target.value);
  };

  const handleSearchRegion = (): void => {
    // TODO: Implement region search
  };

  const handleDialogClose = (geoJsonLayer: GeoJsonLayer): void => {
    geoJsonLayer.clearSelectedObjects();
    setClickedArea(undefined);
    setDialogOpen(false);
  };

  const handleAreaClicked = (
    clickData: PickInfo<any>,
    type: AreaType
  ): void => {
    const { object } = clickData;
    const id = object?.properties?.id;
    if (id) {
      setClickedArea({ id, type });
    }
  };

  const districtLayer = new GeoJsonLayer({
    id: "onboarding-district-layer",
    data: layerData,
    allowObjectSelection: true,
    onClick: (clickData: PickInfo<any>) =>
      handleAreaClicked(clickData, "DISTRICT"),
  });
  const renderedLayer = districtLayer.render(true);

  return (
    <BasicLayout>
      <DeckGLMap
        sx={{
          width: "100%",
          height: (theme) =>
            `calc(100vh - ${theme.header.height} - ${theme.footer.height} / 5)`,
        }}
        selfManagedLayers={[renderedLayer]}
        mapConfigId="germany"
      />
      <Box
        sx={{
          top: 90,
          left: 24,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          width: 400,
          height: "max-content",
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          boxShadow: 2,
          px: (theme) => theme.spacing(2),
          pt: (theme) => theme.spacing(2),
          pb: (theme) => theme.spacing(1),
        }}
      >
        <Typography component="h2" variant="h4">
          Herzlich Willkommen
        </Typography>
        <Typography
          sx={{ mt: (theme) => theme.spacing(1) }}
          component="p"
          variant="body1"
        >
          Suchen Sie ihre Region bei Namen, Postleitzahl oder direkt auf der
          Karte.
        </Typography>
        <TextField
          sx={{ mt: (theme) => theme.spacing(3) }}
          label="Ort / PLZ"
          value={searchValue}
          onChange={handleChangeSearchValue}
        />
        <Button
          sx={{
            my: (theme) => theme.spacing(3),
            px: (theme) => theme.spacing(4),
            width: "max-content",
          }}
          variant="contained"
          onClick={handleSearchRegion}
          disabled={isLoading}
        >
          Suchen
        </Button>
      </Box>
      <AreaSelectionDialog
        open={dialogOpen}
        onClose={() => handleDialogClose(districtLayer)}
        areaId={clickedArea?.id || ""}
      />
    </BasicLayout>
  );
}
