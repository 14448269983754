import React, { useState } from "react";
import { Menu, Typography } from "@mui/material";
import { formatTimestampToDate } from "../../../tech/utils/TimestampFormatter";
import { CalendarIcon } from "../../icons/CalendarIcon";
import InlineDatePicker from "./InlineDatePicker";
import CustomButton from "../buttons/CustomButton";
import { CustomButtonColor } from "../buttons/types";

interface DatePickerProps {
  date: Date | null;
  onChange: (date: Date | null) => void;
  variant?: "text" | "contained" | "outlined";
  color?: CustomButtonColor;
}

export default function DatePicker({
  date,
  onChange,
  variant,
  color,
}: DatePickerProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setAnchorEl(null);
  };

  const handleChangeDate = (
    dates: [Date | null, Date | null],
    event?: React.SyntheticEvent
  ) => {
    event?.preventDefault();
    const [startDate] = dates;
    onChange(startDate);
    handleClosePicker();
  };

  return (
    <>
      <CustomButton
        sx={{
          pointerEvents: "all",
          py: (theme) => theme.spacing(0.5),
          px: (theme) => theme.spacing(2),
          minWidth: 140,
          height: 43,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant={variant}
        color={color}
        onClick={handleOpenPicker}
      >
        <CalendarIcon />
        <Typography component="p" variant="h4">
          {date ? formatTimestampToDate(date.toISOString()) : "Datum"}
        </Typography>
      </CustomButton>
      <Menu
        elevation={2}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePicker}
        PaperProps={{
          style: {
            transform: "translateY(8px)",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <InlineDatePicker startDate={date} onChange={handleChangeDate} />
      </Menu>
    </>
  );
}

DatePicker.defaultProps = {
  variant: "contained",
  color: "primary",
};
