import React from "react";
import { Box } from "@mui/material";
import { IconProps } from "./types";

export function RulesIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box sx={sx} component="svg" viewBox="0 0 27.75 36.378">
      <path
        fill="currentColor"
        transform="translate(41.125 44)"
        d="M-19.771-44v2.129h6.4v34.249h-27.75v-34.236h6.391V-44zm4.25 34.257V-39.7h-4.265v4.213h-14.965v-4.223h-4.237v29.967zm-17.049-32.11v4.235h10.64v-4.235z"
      />
      <path
        fill="currentColor"
        transform="translate(-33.696 -123.3)"
        d="m46.457 146.5 5.661-5.651 1.434 1.438-7.076 7.061-4.929-4.918 1.424-1.422z"
      />
    </Box>
  );
}

RulesIcon.defaultProps = {
  sx: undefined,
};
