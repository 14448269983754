import { RGBAColor } from "@deck.gl/core";

export interface Marker {
  id: string;
  lat: number;
  lon: number;
  dataRef?: string;
  properties?: unknown;
}

export interface MarkerData {
  markers: Marker[];
}

export interface Heatmap {
  data: number[][];
}

export interface HeatmapData {
  heatmaps: Heatmap[];
}

export type HeatmapDataInterval = "daily" | "half-hourly";

export interface GeoJsonLayerProperty {
  id: string;
  dataCategoryId: string;
  dataSubCategoryId: string;
  description: string;
  lineWidth: number;
  lineColor: RGBAColor;
  lineOpacity: number;
  fillColor: RGBAColor;
  fillOpacity: number;
}

export interface GeoJsonLayerProperties {
  properties: GeoJsonLayerProperty[];
}

export interface GeoJsonData {
  properties: GeoJsonLayerProperty[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoJson: any;
}

export type LocationValue = number | string;
export type FlowValue = number | string;

export interface FlowData {
  locations: LocationValue[][];
  flows: FlowValue[][];
}

export const wmsDataIds = ["wms-zensus-gitter"] as const;
export type WmsDataId = typeof wmsDataIds[number];
