import { createContext } from "react";
import { BackdropContextProps } from "./types";

const initialContext = {
  isBackdropActive: false,
  setBackdropActive: () => {
    // Do nothing
  },
};
const BackdropContext = createContext<BackdropContextProps>(initialContext);

export default BackdropContext;
