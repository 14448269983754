import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";
import { formatTimestampToTime } from "../../tech/utils/TimestampFormatter";

interface CustomLineChartProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  xDataKey: string;
  yDataKey: string;
  dataLabel: string;
  xLabel: string;
  yLabel: string;
  width?: number;
  height?: number;
}

export default function CustomLineChart({
  data,
  xDataKey,
  yDataKey,
  dataLabel,
  xLabel,
  yLabel,
  width,
  height,
}: CustomLineChartProps): React.ReactElement {
  return (
    <LineChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 15,
        right: 30,
        left: 20,
        bottom: 20,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey={xDataKey}
        tickFormatter={(value) => {
          if (value === "auto") {
            return "";
          }
          if (value && typeof value === "string") {
            const timestamp = value.endsWith("Z") ? value : `${value}Z`;
            return formatTimestampToTime(timestamp);
          }
          return "";
        }}
      >
        <Label value={xLabel} offset={-15} position="insideBottom" />
      </XAxis>
      <YAxis
        label={{
          value: yLabel,
          angle: -90,
          position: "insideBottomLeft",
        }}
      />
      <Tooltip />
      <Line
        name={dataLabel}
        type="monotone"
        dataKey={yDataKey}
        stroke="#0295b6"
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
}

CustomLineChart.defaultProps = {
  data: undefined,
  width: 1000,
  height: 300,
};
