import React from "react";
import { hasRole } from "../../api/AuthService";
import Forbidden from "./Forbidden";

interface RoleBasedRouteProps {
  roles: string[];
  children: React.ReactNode;
}

export default function RoleBasedRoute({
  roles,
  children,
}: RoleBasedRouteProps): React.ReactElement {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hasRole(roles) ? children : <Forbidden />}</>;
}
