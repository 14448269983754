import { createContext } from "react";
import { ApiContextProps } from "./types";

const initialContext: ApiContextProps = {
  commits: [],
  setCommits: () => {
    // Do nothing
  },
  branches: [],
  setBranches: () => {
    // Do nothing
  },
  merges: [],
  setMerges: () => {
    // Do nothing
  },
  masterBranch: undefined,
  setMasterBranch: () => {
    // Do nothing
  },
  exportCommits: [],
  setExportCommits: () => {
    // Do nothing
  },
  rules: [],
  setRules: () => {
    // Do nothing
  },
  dataSources: [],
  setDataSources: () => {
    // Do nothing
  },
  fetchDatabaseData: () => Promise.resolve(),
};
const ApiContext = createContext<ApiContextProps>(initialContext);

export default ApiContext;
