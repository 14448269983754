import React from "react";
import { Box } from "@mui/material";
import { IconProps } from "./types";

export function CloseIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box sx={sx} component="svg" viewBox="0 0 26.554 26.554">
      <path
        stroke="currentColor"
        transform="translate(1.768 1.768) rotate(45)"
        d="m0 0h32.553"
        fill="none"
        strokeWidth="4px"
      />
      <path
        stroke="currentColor"
        transform="translate(24.786 1.768) rotate(45)"
        d="m0 0v32.553"
        fill="none"
        strokeWidth="4px"
      />
    </Box>
  );
}

CloseIcon.defaultProps = {
  sx: undefined,
};
