import React, { useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import HeaderButton from "./HeaderButton";
import MapLayerMenu from "../../map/controls/LayerMenu";
import { ExportIcon } from "../../icons/ExportIcon";
import { ImportIcon } from "../../icons/ImportIcon";
import { ResetIcon } from "../../icons/ResetIcon";
import { SaveIcon } from "../../icons/SaveIcon";
import MoreOptionsMenu from "./MoreOptionsMenu";
import StyleSelector from "../../map/controls/StyleSelector";
import { MapConfigId } from "../../../store/slices/maps/types";
import DateRangeSelector from "../../map/controls/DateRangeSelector";
import ProfileMenu from "./ProfileMenu";
import ResetMapLayoutDialog from "../../dialog/ResetMapLayoutDialog";
import LoadMapLayoutDialog from "../../dialog/LoadMapLayoutDialog/LoadMapLayoutDialog";
import ExportMapLayoutDialog from "../../dialog/ExportMapLayoutDialog";
import SaveMapLayoutDialog from "../../dialog/SaveMapLayoutDialog";

interface MapHeaderProps {
  sx?: SxProps<Theme>;
  mapConfigId: MapConfigId;
}

type DialogId = "export" | "reset" | "load" | "save";

export default function MapHeader({
  sx,
  mapConfigId,
}: MapHeaderProps): React.ReactElement {
  const [openedDialogId, setOpenedDialogId] = useState<DialogId | null>(null);

  const handleOpenDialog = (event: React.MouseEvent, id: DialogId): void => {
    event.preventDefault();
    event.stopPropagation();
    setOpenedDialogId(id);
  };

  const handleCloseDialog = (event: React.MouseEvent, id: DialogId): void => {
    event.preventDefault();
    event.stopPropagation();
    if (openedDialogId === id) {
      setOpenedDialogId(null);
    }
  };

  return (
    <Box
      sx={{
        pointerEvents: "none",
        px: (theme) => theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: (theme) => theme.spacing(1),
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <MapLayerMenu />
        <DateRangeSelector mapConfigId={mapConfigId} />
      </Box>

      <StyleSelector mapConfigId={mapConfigId} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <HeaderButton
          label="Export"
          icon={<ExportIcon />}
          onClick={(event) => handleOpenDialog(event, "export")}
        />
        <ExportMapLayoutDialog
          mapConfigId={mapConfigId}
          open={openedDialogId === "export"}
          onClose={(event) => handleCloseDialog(event, "export")}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: (theme) => theme.spacing(0.25),
          }}
        >
          <HeaderButton
            label="Ansicht Reset"
            icon={<ResetIcon />}
            onClick={(event) => handleOpenDialog(event, "reset")}
          />
          <ResetMapLayoutDialog
            mapConfigId={mapConfigId}
            open={openedDialogId === "reset"}
            onClose={(event) => handleCloseDialog(event, "reset")}
          />
          <HeaderButton
            label="Ansicht Laden"
            icon={<ImportIcon />}
            onClick={(event) => handleOpenDialog(event, "load")}
          />
          <LoadMapLayoutDialog
            mapConfigId={mapConfigId}
            open={openedDialogId === "load"}
            onClose={(event) => handleCloseDialog(event, "load")}
          />
          <HeaderButton
            label="Ansicht Speichern"
            icon={<SaveIcon />}
            onClick={(event) => handleOpenDialog(event, "save")}
          />
          <SaveMapLayoutDialog
            mapConfigId={mapConfigId}
            open={openedDialogId === "save"}
            onClose={(event) => handleCloseDialog(event, "save")}
          />
        </Box>

        <ProfileMenu />
        <MoreOptionsMenu />
      </Box>
    </Box>
  );
}

MapHeader.defaultProps = {
  sx: undefined,
};
