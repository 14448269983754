import { Box } from "@mui/material";
import { IconProps } from "./types";

export function StreetNetworkIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 24, height: 24, ...sx }}
      component="svg"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M12 0C5.387 0 0 5.387 0 12s5.387 12 12 12 12-5.387 12-12S18.613 0 12 0zm0 21.52c-5.227 0-9.52-4.267-9.52-9.52 0-5.227 4.267-9.52 9.52-9.52 5.227 0 9.52 4.267 9.52 9.52 0 5.227-4.293 9.52-9.52 9.52z" />
        <path d="M17.387 9.98h-.777l-.511-1.605A1.936 1.936 0 0 0 14.239 7H9.762c-.859 0-1.595.563-1.86 1.375L7.39 9.98h-.777a.608.608 0 0 0-.613.625v.354c0 .355.266.625.613.625h.225a8.534 8.534 0 0 0-.184 1.771v3.021c0 .354.266.625.614.625H8.29c.347 0 .613-.27.613-.625v-.958h6.194v.958c0 .354.266.625.613.625h1.023c.347 0 .613-.27.613-.625v-3.02c0-.605-.062-1.188-.184-1.772h.225c.347 0 .613-.27.613-.625v-.354a.608.608 0 0 0-.613-.625zm-8.893.645.593-1.875a.725.725 0 0 1 .695-.52h4.477c.306 0 .593.208.695.52l.593 1.875c.02.083-.041.167-.123.167H8.617c-.103 0-.164-.084-.123-.167zm1.288 3.062H8.535a.392.392 0 0 1-.388-.396v-.604c0-.229.204-.416.429-.375l1.165.188a.35.35 0 0 1 .307.292l.081.416a.36.36 0 0 1-.347.48zm6.071-.375a.392.392 0 0 1-.388.396h-1.247c-.245 0-.43-.229-.368-.458l.082-.417c.04-.146.163-.27.306-.291l1.166-.188c.225-.041.43.146.43.375v.583h.02z" />
      </g>
    </Box>
  );
}

StreetNetworkIcon.defaultProps = {
  sx: undefined,
};
