import { useContext, useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import ApiContext from "../../tech/context/ApiContext";
import BackdropContext from "../../tech/context/BackdropContext";
import Logger from "../../tech/utils/Logger";
import { Commit } from "../../api/versioning/types";
import {
  postCreateBranch,
  postPushCommitToBranch,
} from "../../api/versioning/versioning-api";
import { BaseDialog } from "./base/BaseDialog";

interface PushAndCreateDialogProps {
  open: boolean;
  onClose: () => void;
  commit: Commit;
  onSuccess?: () => void;
}

export default function PushAndCreateDialog({
  open,
  onClose,
  commit,
  onSuccess,
}: PushAndCreateDialogProps): React.ReactElement {
  const { branches, fetchDatabaseData } = useContext(ApiContext);
  const { setBackdropActive } = useContext(BackdropContext);
  const [selectedBranchId, setSelectedBranchId] = useState("");

  useEffect(() => {
    if (open) {
      setSelectedBranchId("");
    }
  }, [open]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: string
  ): void => {
    setSelectedBranchId(index);
  };

  const handleClose = (): void => {
    setSelectedBranchId("");
    onClose();
  };

  const pushCommit = (targetBranchId: string): void => {
    setBackdropActive(true);
    postPushCommitToBranch(commit.id, targetBranchId)
      .then(() => fetchDatabaseData())
      .then(() => {
        setBackdropActive(false);
        handleClose();
        if (onSuccess) {
          onSuccess();
        }
      })
      .then((error) => {
        Logger.error(error);
      });
  };

  const createNewBranchByCommit = (): void => {
    setBackdropActive(true);
    postCreateBranch(commit.id, Math.floor(Math.random() * 10000) + 1000)
      .then(() => fetchDatabaseData())
      .then(() => {
        setBackdropActive(false);
        handleClose();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        // TODO: Check if error is "name exists" and show error
        Logger.error(error);
      });
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Änderung in Branch übernehmen"
      primaryButton={{
        label: "Übernehmen",
        onClick: () => pushCommit(selectedBranchId),
        disabled: !selectedBranchId,
      }}
      secondaryButton={{
        label: "Neuen Branch erstellen",
        onClick: createNewBranchByCommit,
      }}
    >
      <List>
        {branches
          ?.filter(
            (branch) =>
              branch.status === "OPEN" && branch.id !== commit.branchId
          )
          .map((branch) => (
            <ListItem
              key={branch.id}
              button
              selected={selectedBranchId === branch.id}
              onClick={(event) => handleListItemClick(event, branch.id)}
            >
              <ListItemText primary={branch.name} />
            </ListItem>
          ))}
      </List>
    </BaseDialog>
  );
}

PushAndCreateDialog.defaultProps = {
  onSuccess: undefined,
};
