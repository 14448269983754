import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectDebugCategory,
  toggleDebugCategoryVisibilty,
} from "../../../store/slices/layer-categories/slice";
import CircleButton from "../../custom/buttons/CircleButton";
import LayerSubMenu from "./LayerSubMenu";

export default function DebugLayerSubMenu(): React.ReactElement {
  const dispatch = useAppDispatch();
  const debugCategory = useAppSelector((state) => selectDebugCategory(state));

  const handleClickDebugMenuToggle = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(toggleDebugCategoryVisibilty());
  };

  return (
    <>
      {debugCategory.visible && <LayerSubMenu categoryId="debug" />}
      <CircleButton
        onClick={handleClickDebugMenuToggle}
        label={debugCategory.visible ? "-" : "+"}
        color="white"
      />
    </>
  );
}
