import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ResetIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 18, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 18 16"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M1.077 6.718.034 3.215a.93.93 0 0 1 .588-1.129.918.918 0 0 1 1.118.593l.38 1.302C3.502 1.608 6.061 0 9 0c3.28 0 6.104 2.029 7.317 4.9.246.593-.19 1.263-.815 1.263a.873.873 0 0 1-.815-.536C13.739 3.368 11.559 1.799 9 1.799 6.688 1.8 4.66 3.1 3.617 5.014l1.535-.46a.918.918 0 0 1 1.118.594.93.93 0 0 1-.587 1.13L2.214 7.33a.924.924 0 0 1-1.137-.612zM16.26 13.301 15.88 12c-1.383 2.392-3.942 4-6.88 4-3.28 0-6.103-2.029-7.317-4.9-.246-.593.19-1.263.815-1.263.36 0 .683.21.815.536.948 2.259 3.128 3.828 5.687 3.828 2.313 0 4.34-1.301 5.383-3.215l-1.535.459a.918.918 0 0 1-1.118-.593.93.93 0 0 1 .587-1.13l3.469-1.052a.913.913 0 0 1 1.118.612l1.062 3.503a.93.93 0 0 1-.588 1.129c-.474.134-.986-.134-1.118-.612z" />
      </g>
    </Box>
  );
}

ResetIcon.defaultProps = {
  sx: undefined,
};
