import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import ApiContext from "../../tech/context/ApiContext";
import CommitsTable from "../../components/table/CommitsTable";
import BasicLayout from "../../components/layout/BaseLayout";

export default function ChangeListPage(): React.ReactElement {
  const { commits } = useContext(ApiContext);

  return (
    <BasicLayout>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{ mb: (theme) => theme.spacing(4) }}
          component="h1"
          variant="h1"
        >
          Änderungsliste
        </Typography>
        <CommitsTable sx={{ height: "max-content" }} commits={commits} />
      </Box>
    </BasicLayout>
  );
}
