import { HeatmapDataInterval } from "../../../api/layers/types";
import { PoiKey } from "../../../api/pois/types";
import {
  HeatmapSettings,
  PoiSettings,
  TimeseriesSettings,
} from "../../../components/map/layer/types";
import { RequestStatus } from "../../types";
import {
  DebugLayerCategoryId,
  LayerCategoryId,
} from "../layer-categories/types";
import { MapConfigId } from "../maps/types";

export const layerIds = [
  "induction-loops",
  "traffic-information",
  "occupancy-avg",
  "occupancy-daily",
  "flow-avg",
  "flow-daily",
  "capacity",
  "road-network",
  "bike-network",
  "city-areas",
  "points-of-interest",
  "commuters",
  "tazes",
  "zensus-1km-wms",
  "zensus-100m",
  "school-stats",
] as const;
export type LayerId = typeof layerIds[number];

export type ObjectsByLayerId<T> = {
  [id in Partial<LayerId>]: T;
};

export type GroupedLayers = {
  [group: number]: Layer[];
};

export interface Layer {
  id: LayerId;
  categoryId: LayerCategoryId | DebugLayerCategoryId;
  mapConfigId: MapConfigId;
  group: number;
  label: string;
  selected: boolean;
  editing: boolean;
  iconId: string;
  editComponentId?: string;
  additionalComponentId?: string;
}

export type LayerData = {
  settings: LayerSettings | null;
  data: unknown | null;
};

export type LayerSettings = HeatmapSettings | PoiSettings | TimeseriesSettings;

export interface LayerState {
  layersById: ObjectsByLayerId<Layer>;
  dataById: ObjectsByLayerId<LayerData>;
  statusesById: ObjectsByLayerId<RequestStatus>;
}

export interface HeatmapSettingsValueUpdate {
  id: LayerId;
  value: number;
  name: keyof HeatmapSettings;
}

export interface PoiSettingsUpdate {
  id: LayerId;
  poiKey: PoiKey | null;
}

export interface TimeseriesSettingsUpdate {
  id: LayerId;
  dataRef: string | null;
}

export interface FetchMarkerDataParams {
  layerId: LayerId;
  dataId: string;
}

export interface FetchHeatmapDataParams {
  layerId: LayerId;
  dataset: string;
  attrib: string;
  interval: HeatmapDataInterval;
  fromMs: number;
  toMs: number;
}

export interface FetchGeoJsonDataParams {
  layerId: LayerId;
  dataCategoryId: string;
}

export interface FetchTazesGeoJsonDataParams {
  layerId: LayerId;
}

export interface FetchFlowDataParams {
  layerId: LayerId;
  dataset: string;
  flowCount: number;
  fromMs: number;
  toMs: number;
}
