/* eslint-disable @typescript-eslint/no-explicit-any */
import { CompositeLayer, IconLayer } from "deck.gl";
import Supercluster from "supercluster";

function getIconName(size: number): string {
  if (size === 0) {
    return "marker-1";
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 100) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  if (size < 1000) {
    return `marker-${Math.floor(size / 100)}00`;
  }
  if (size < 10000) {
    return `marker-${Math.floor(size / 1000)}000`;
  }
  if (size < 25000) {
    return "marker-10000";
  }
  if (size < 50000) {
    return "marker-25000";
  }
  if (size < 100000) {
    return "marker-50000";
  }
  return "marker-100000";
}

function getIconSize(size: number): number {
  return Math.min(100, size) / 100 + 1;
}

export class ClusterIconMapLayer extends CompositeLayer<any, any> {
  // eslint-disable-next-line class-methods-use-this
  shouldUpdateState({ changeFlags }: any): any {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }: any): void {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 16, radius: props.sizeScale });
      index.load(
        props.data.map((d: any) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }: any): any {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        // eslint-disable-next-line no-param-reassign
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f: any) => f.properties);
      }
      // eslint-disable-next-line no-param-reassign
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers(): IconLayer<any, any> {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;
    return new IconLayer(
      this.getSubLayerProps({
        id: "icon",
        data,
        iconAtlas:
          iconAtlas || `${process.env.PUBLIC_URL}/deckgl/umpf-icon-atlas.png`,
        iconMapping:
          iconMapping ||
          `${process.env.PUBLIC_URL}/deckgl/umpf-icon-mapping.json`,
        sizeScale,
        getPosition: (d: any) => d.geometry.coordinates,
        getIcon: (d: any) =>
          getIconName(d.properties.cluster ? d.properties.point_count : 1),
        getSize: (d: any) =>
          getIconSize(d.properties.cluster ? d.properties.point_count : 1),
      })
    );
  }
}
