import { createTheme, ThemeOptions } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";
import { Theme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    header: {
      height: React.CSSProperties["height"];
    };
    footer: {
      height: React.CSSProperties["height"];
    };
  }
  interface ThemeOptions {
    header?: {
      height?: React.CSSProperties["height"];
    };
    footer?: {
      height?: React.CSSProperties["height"];
    };
  }
  interface Palette {
    wht: Palette["primary"];
    blk: Palette["primary"];
    ber: Palette["primary"];
    grn: Palette["primary"];
    prp: Palette["primary"];
  }
  interface PaletteOptions {
    wht: PaletteOptions["primary"];
    blk: PaletteOptions["primary"];
    ber: PaletteOptions["primary"];
    grn: PaletteOptions["primary"];
    prp: PaletteOptions["primary"];
  }
}

const createCustomTheme = (options: ThemeOptions): Theme =>
  createTheme(
    {
      header: {
        height: "59px",
      },
      footer: {
        height: "125px",
      },
      ...options,
    },
    deDE
  );

const theme = createCustomTheme({
  typography: {
    htmlFontSize: 14,
    fontFamily: ["Heebo", "sans-serif"].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: "bold",
      fontSize: 21,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h2: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: 500,
      fontSize: 21,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h3: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: 1.07,
      letterSpacing: "normal",
    },
    h4: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    body1: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    body2: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: 300,
      fontSize: 10,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    button: {
      fontFamily: ["Heebo", "sans-serif"].join(","),
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  palette: {
    primary: {
      light: "#1bd4fe",
      main: "#0295b6",
      dark: "#016379",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#b502e9",
      dark: "#5f007a",
      contrastText: "#ffffff",
    },
    wht: {
      main: "#ffffff",
      contrastText: "#000000",
    },
    blk: {
      main: "#000000",
      contrastText: "#ffffff",
    },
    ber: {
      main: "#fc1066",
      contrastText: "#ffffff",
    },
    grn: {
      main: "#04f368",
      contrastText: "#ffffff",
    },
    prp: {
      main: "#b502e9",
      dark: "#5f007a",
      contrastText: "#ffffff",
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
    grey: {
      100: "#f5f5f5",
      200: "#ebebeb",
      300: "#d6d6d6",
      400: "#adadad",
      500: "#858585",
      600: "#5c5c5c",
      700: "#333333",
      800: "#1f1f1f",
      900: "#141414",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
  },
});

export default theme;
