import { Box } from "@mui/material";
import { IconProps } from "./types";

export function CommuterIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 23, height: 20, ...sx }}
      component="svg"
      viewBox="0 0 23 20"
    >
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <ellipse
          stroke="currentColor"
          strokeWidth="1.355"
          cx="2.679"
          cy="12.767"
          rx="2.679"
          ry="2.616"
        />
        <ellipse
          stroke="currentColor"
          strokeWidth="1.355"
          cx="18.321"
          cy="12.767"
          rx="2.679"
          ry="2.616"
        />
        <ellipse
          stroke="currentColor"
          strokeWidth="1.355"
          cx="10.476"
          cy="15.384"
          rx="2.679"
          ry="2.616"
        />
        <ellipse
          stroke="currentColor"
          strokeWidth="1.355"
          cx="10.487"
          cy="1.337"
          rx="1.369"
          ry="1.337"
        />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M9.827 2.658h1.299v9.784H9.827zM15 9.115l1.082-.702 1.353 1.988-1.082.702zM4.884 8.38l1.08.706-1.321 1.93-1.08-.705z"
        />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="m9.13 2.178 1.08.705-1.35 1.971-1.08-.705zM7.03 5.245l1.08.705-1.448 2.117-1.08-.705zM10.808 2.955l1.082-.702 1.267 1.862-1.082.702zM12.833 5.93l1.082-.702 1.417 2.082-1.082.702z"
        />
      </g>
    </Box>
  );
}

CommuterIcon.defaultProps = {
  sx: undefined,
};
