/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  GER_CENTER_LAT,
  GER_CENTER_LON,
  REGION_CENTER_LAT,
  REGION_CENTER_LON,
} from "../../../config";
import {
  Coordinate,
  MapConfig,
  MapConfigId,
  MapState,
  MapStyleUpdate,
  ObservationUpdate,
  TooltipDataUpdate,
  ViewState,
  ViewStateUpdate,
} from "./types";

const initialDate = new Date();

const initialGermanyCenter: Coordinate = {
  latitude: GER_CENTER_LAT,
  longitude: GER_CENTER_LON,
};

const initialRegionCenter: Coordinate = {
  latitude: REGION_CENTER_LAT,
  longitude: REGION_CENTER_LON,
};

const initialViewState: ViewState = {
  ...initialGermanyCenter,
  zoom: 11,
  maxZoom: 18,
  minZoom: 0,
  pitch: 0,
  maxPitch: 60,
  minPitch: 0,
  bearing: 0,
  altitude: 1.5,
};

const initialState: MapState = {
  mapConfigsById: {
    main: {
      id: "main",
      style: "1",
      viewState: { ...initialViewState, ...initialRegionCenter, zoom: 11 },
      defaultViewState: {
        ...initialViewState,
        ...initialRegionCenter,
        zoom: 11,
      },
      observation: {
        startTimestamp: initialDate.toISOString(),
        endTimestamp: initialDate.toISOString(),
      },
    },
    germany: {
      id: "germany",
      style: "1",
      viewState: { ...initialViewState, ...initialGermanyCenter, zoom: 5 },
      defaultViewState: {
        ...initialViewState,
        ...initialGermanyCenter,
        zoom: 5,
      },
      usePublicOsmTiles: true,
    },
    minimal: {
      id: "minimal",
      style: "1",
      viewState: { ...initialViewState, ...initialRegionCenter, zoom: 13 },
      defaultViewState: {
        ...initialViewState,
        ...initialRegionCenter,
        zoom: 13,
      },
    },
  },
};

const zoomIncrement = 1;

export const mapSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {
    updateViewState: (state, action: PayloadAction<ViewStateUpdate>) => {
      const { id, viewState } = action.payload;
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        viewState: {
          ...state.mapConfigsById[id].viewState,
          ...viewState,
        },
      };
    },
    updateMapStyle: (state, action: PayloadAction<MapStyleUpdate>) => {
      const { id, style } = action.payload;
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        style,
      };
    },
    updateObservation: (state, action: PayloadAction<ObservationUpdate>) => {
      const { id, observation } = action.payload;
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        observation,
      };
    },
    updateTooltipData: (state, action: PayloadAction<TooltipDataUpdate>) => {
      const { id, tooltipData } = action.payload;
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        tooltipData: tooltipData.object ? tooltipData : undefined,
      };
    },
    resetZoom: (state, action: PayloadAction<MapConfigId>) => {
      const id = action.payload;
      const { defaultViewState } = state.mapConfigsById[id];
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        viewState: {
          ...state.mapConfigsById[id].viewState,
          zoom: defaultViewState.zoom,
        },
      };
    },
    increaseZoom: (state, action: PayloadAction<MapConfigId>) => {
      const id = action.payload;
      const { viewState } = state.mapConfigsById[id];
      const zoom = Math.min(
        viewState.zoom + zoomIncrement,
        viewState.maxZoom || 20
      );
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        viewState: {
          ...state.mapConfigsById[id].viewState,
          zoom,
        },
      };
    },
    decreaseZoom: (state, action: PayloadAction<MapConfigId>) => {
      const id = action.payload;
      const { viewState } = state.mapConfigsById[id];
      const zoom = Math.max(
        viewState.zoom - zoomIncrement,
        viewState.minZoom || 0
      );
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        viewState: {
          ...state.mapConfigsById[id].viewState,
          zoom,
        },
      };
    },
    resetViewStateLocation: (state, action: PayloadAction<MapConfigId>) => {
      const id = action.payload;
      const { defaultViewState } = state.mapConfigsById[id];
      state.mapConfigsById[id] = {
        ...state.mapConfigsById[id],
        viewState: {
          ...state.mapConfigsById[id].viewState,
          latitude: defaultViewState.latitude,
          longitude: defaultViewState.longitude,
        },
      };
    },
  },
  extraReducers: {},
});

export const selectAllMapConfigs = (state: RootState): MapConfig[] =>
  Object.values(state.mapReducer.mapConfigsById);

export const selectMapConfigById = (
  state: RootState,
  id: MapConfigId
): MapConfig => state.mapReducer.mapConfigsById[id];

export const {
  updateViewState,
  updateMapStyle,
  updateObservation,
  updateTooltipData,
  resetZoom,
  increaseZoom,
  decreaseZoom,
  resetViewStateLocation,
} = mapSlice.actions;

export default mapSlice.reducer;
