import { Box } from "@mui/material";

interface BaseContributionsProps {
  additionalContributions?: React.ReactNode;
}

export default function BaseContributions({
  additionalContributions,
}: BaseContributionsProps): React.ReactElement {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        right: 0,
        pl: (theme) => theme.spacing(2),
        pr: (theme) => theme.spacing(4),
        py: (theme) => theme.spacing(0.25),
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        fontSize: "0.75rem",
      }}
    >
      &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>{" "}
      contributors{additionalContributions && " | "}
      {additionalContributions}
    </Box>
  );
}

BaseContributions.defaultProps = {
  additionalContributions: undefined,
};
