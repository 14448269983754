import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Menu } from "@mui/material";
import { BurgerIcon } from "../../icons/BurgerIcon";
import HeaderButton from "./HeaderButton";
import Separator from "../../custom/Separator";

interface MenuItem {
  route: string;
  label: string;
}

const menuItemsById: { [key: string]: MenuItem } = {
  "menu-item-map": { label: "Kartenansicht", route: "/map" },
  "menu-item-versions-board": {
    label: "Versionsboard",
    route: "/versions-board",
  },
  "menu-item-change-list": { label: "Änderungsliste", route: "/changes" },
  "menu-item-rules": { label: "Regelwerk", route: "/rules" },
  "menu-item-add-change": {
    label: "Änderung manuell hinzufügen",
    route: "/changes/new",
  },
  "menu-item-change-history": {
    label: "Änderungshistorie",
    route: "/changes/history",
  },
  "menu-item-settings": { label: "Einstellungen", route: "/settings" },
  "menu-item-faq": { label: "FAQ", route: "/faq" },
  "menu-item-contact": { label: "Kontakt", route: "/contact" },
  "menu-item-info": { label: "INFO", route: "/info" },
};

export default function MoreOptionsMenu(): React.ReactElement {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent): void => {
    const { id } = event.currentTarget;
    const menuItem = menuItemsById[id];
    if (menuItem?.route) {
      handleClose();
      navigate(menuItem.route);
    }
  };

  return (
    <>
      <HeaderButton
        onClick={handleClick}
        label="Weitere Optionen"
        icon={<BurgerIcon />}
        dark
      />
      <Menu
        sx={{
          "& .MuiPaper-root": {
            width: 190,
            backgroundColor: (theme) => theme.palette.grey[50],
            borderRadius: 2,
          },
        }}
        PaperProps={{
          style: {
            transform: "translate(8px, 8px)",
          },
        }}
        elevation={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {Object.entries(menuItemsById).map(
            ([id, menuItem], index, entries) => (
              <Box key={id}>
                <Button
                  sx={{ width: "100%" }}
                  id={id}
                  variant="text"
                  onClick={handleMenuItemClick}
                >
                  {menuItem.label}
                </Button>
                {index < entries.length - 1 && (
                  <Separator sx={{ my: (theme) => theme.spacing(0.5) }} />
                )}
              </Box>
            )
          )}
        </Box>
      </Menu>
    </>
  );
}
