import { Box } from "@mui/material";
import { IconProps } from "./types";

export function TrafficOccupancyAvgIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 22, height: 21, ...sx }}
      component="svg"
      viewBox="0 0 22 21"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M7.318 14.21v-.397c0-1.007.81-1.826 1.806-1.826h.093l.231-.726H2.964c-.092 0-.185-.094-.139-.187l.672-2.107a.82.82 0 0 1 .787-.586h5.072c.347 0 .671.235.787.586l.208.655a3.734 3.734 0 0 1 1.205-.796l-.093-.28A2.192 2.192 0 0 0 9.356 7H4.26a2.22 2.22 0 0 0-2.107 1.545l-.58 1.803h-.88A.686.686 0 0 0 0 11.05v.398c0 .398.301.703.695.703H.95a9.517 9.517 0 0 0-.209 1.99v3.418c0 .398.301.702.695.702h1.158a.686.686 0 0 0 .695-.702v-1.1h4.77c0-.258.023-.492.046-.75a1.776 1.776 0 0 1-.787-1.498zm-3.034.328H2.872a.442.442 0 0 1-.44-.445v-.678c0-.258.231-.469.51-.422l1.32.21a.396.396 0 0 1 .346.329l.093.468a.43.43 0 0 1-.417.538z" />
        <path d="M21.305 13.11h-.88l-.579-1.802a2.192 2.192 0 0 0-2.107-1.546h-5.072a2.22 2.22 0 0 0-2.107 1.546L9.98 13.11h-.88a.686.686 0 0 0-.695.703v.398c0 .398.301.702.695.702h.255a9.517 9.517 0 0 0-.209 1.99v3.395c0 .398.301.702.695.702H11a.686.686 0 0 0 .695-.702V19.22h7.017v1.077c0 .398.3.702.694.702h1.158a.686.686 0 0 0 .695-.702v-3.395c0-.679-.07-1.335-.209-1.99h.255A.686.686 0 0 0 22 14.21v-.398a.686.686 0 0 0-.695-.703zm-10.073.726.671-2.107a.82.82 0 0 1 .787-.585h5.072c.347 0 .672.234.787.585l.672 2.107c.023.093-.047.187-.14.187h-7.71c-.116 0-.186-.093-.14-.187zm1.458 3.441h-1.412a.442.442 0 0 1-.44-.445v-.678c0-.258.231-.469.486-.422l1.32.211a.396.396 0 0 1 .348.328l.092.468a.406.406 0 0 1-.394.538zm6.878-.421a.442.442 0 0 1-.44.445h-1.412c-.278 0-.487-.258-.417-.515l.092-.468c.047-.164.186-.305.348-.328l1.32-.21c.255-.048.486.163.486.42v.656h.023zM20.82.18a.62.62 0 0 0-.873 0l-.803.802a3.7 3.7 0 0 0-5.16 5.162l-.803.802a.617.617 0 1 0 .873.873l.802-.802a3.7 3.7 0 0 0 5.162-5.161l.802-.803a.62.62 0 0 0 0-.873zM14.53 4a2.467 2.467 0 0 1 3.723-2.126l-3.378 3.378A2.457 2.457 0 0 1 14.53 4zm4.941 0a2.467 2.467 0 0 1-3.723 2.126l3.378-3.379c.225.38.345.812.345 1.253z" />
      </g>
    </Box>
  );
}

TrafficOccupancyAvgIcon.defaultProps = {
  sx: undefined,
};
