import React from "react";
import { Box, Typography } from "@mui/material";
import WhtButton from "../../custom/buttons/WhtButton";
import BlkButton from "../../custom/buttons/BlkButton";

interface HeaderButtonProps {
  icon: React.ReactNode;
  label: string;
  dark?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function HeaderButton({
  icon,
  label,
  dark,
  onClick,
}: HeaderButtonProps): React.ReactElement {
  const Button = dark ? BlkButton : WhtButton;

  return (
    <Button
      sx={{
        pointerEvents: "all",
        p: (theme) => theme.spacing(0.5),
        width: 90,
        height: "43px",
        border: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: (theme) => theme.spacing(0.5),
      }}
      variant="contained"
      onClick={onClick}
    >
      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{ textTransform: "none", fontSize: 10 }}
        component="p"
        variant="button"
      >
        {label}
      </Typography>
    </Button>
  );
}

HeaderButton.defaultProps = {
  dark: false,
  onClick: undefined,
};
