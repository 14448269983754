import React from "react";
import { Grid, Typography } from "@mui/material";
import BasicLayout from "../../components/layout/BaseLayout";

export default function AGBPage(): React.ReactElement {
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1">
            Allgemeine Geschäftsbedingungen
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="body1">
            Hier könnten unsere AGBs stehen...
          </Typography>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}
