import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import RulesTable from "../components/table/RulesTable";
import ApiContext from "../tech/context/ApiContext";
import CreateOrEditRuleDialog from "../components/dialog/CreateOrEditRuleDialog";
import BasicLayout from "../components/layout/BaseLayout";

export default function RulesManagerPage(): React.ReactElement {
  const { rules } = useContext(ApiContext);

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1">
            Regelwerk
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RulesTable rules={rules} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          item
          xs={12}
        >
          <CreateOrEditRuleDialog />
        </Grid>
      </Grid>
    </BasicLayout>
  );
}
