import { Box } from "@mui/material";
import { IconProps } from "./types";

export function TrafficFlowAvgIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 30, height: 17, ...sx }}
      component="svg"
      viewBox="0 0 30 17"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M19.848 11.813a.97.97 0 0 0-.863-.923l-3.742-.33-2.775-2.945A1.995 1.995 0 0 0 11.038 7H6.476c-.652 0-1.262.33-1.64.901l-1.787 2.637-2.166.11C.4 10.67 0 11.11 0 11.615v2.33c0 .55.42.989.946.989h1.85C2.88 16.077 3.806 17 4.92 17s2.04-.901 2.123-2.066h5.887C13.014 16.077 13.94 17 15.054 17c1.135 0 2.04-.901 2.123-2.066h1.85c.547 0 .968-.483.926-1.033l-.105-2.088zm-14.928 4c-.568 0-1.03-.483-1.03-1.077 0-.593.462-1.077 1.03-1.077.568 0 1.03.484 1.03 1.077 0 .594-.462 1.077-1.03 1.077zm3.028-5.384H4.94L6.055 8.78a.541.541 0 0 1 .42-.22h1.473v1.869zm1.492 0V8.56h1.598c.147 0 .274.066.358.154l1.619 1.715H9.44zm5.614 5.384c-.568 0-1.03-.483-1.03-1.077 0-.593.462-1.077 1.03-1.077.568 0 1.03.484 1.03 1.077 0 .594-.462 1.077-1.03 1.077zM25.727 10.658v1.266l-4.159.516V13.6l4.159.535v1.265c0 .268.172.517.42.624a.736.736 0 0 0 .782-.125l2.823-2.37c.153-.143.248-.321.248-.5a.675.675 0 0 0-.248-.498l-2.823-2.371a.774.774 0 0 0-.782-.125.647.647 0 0 0-.42.624z" />
        <g>
          <path d="M24.82.18a.62.62 0 0 0-.873 0l-.803.802a3.7 3.7 0 0 0-5.16 5.162l-.803.802a.617.617 0 1 0 .873.873l.802-.802a3.7 3.7 0 0 0 5.162-5.161l.802-.803a.62.62 0 0 0 0-.873zM18.53 4a2.467 2.467 0 0 1 3.723-2.126l-3.378 3.378A2.457 2.457 0 0 1 18.53 4zm4.941 0a2.467 2.467 0 0 1-3.723 2.126l3.378-3.379c.225.38.345.812.345 1.253z" />
        </g>
      </g>
    </Box>
  );
}

TrafficFlowAvgIcon.defaultProps = {
  sx: undefined,
};
