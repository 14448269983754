import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  useMediaQuery,
  Theme,
  SxProps,
} from "@mui/material";

const whiteTextFieldSx: SxProps<Theme> = {
  width: "70%",
  maxWidth: 350,
  "& .MuiInput-root": {
    color: (theme) => theme.palette.wht.main,
    "&:hover:not(.Mui-disabled):before": {
      borderBottomColor: (theme) => theme.palette.wht.main,
    },
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: (theme) => theme.palette.wht.main,
  },
};

export default function LoginPage(): React.ReactElement {
  const navigate = useNavigate();

  const isMediaSizeSmallerMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleRegisterClicked = (): void => {
    // TODO: Implement route
    navigate("/registration");
  };

  const handleLoginClicked = (): void => {
    // TODO: Handle login
  };

  const handleForgotPasswordClicked = (): void => {
    // TODO: Implement route
    navigate("/password-reset");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: (theme) => theme.palette.grey[800],
      }}
    >
      <Box
        sx={{
          mt: (theme) => theme.spacing(16),
          width: "80%",
          maxWidth: 600,
          height: "auto",
        }}
        component="img"
        src="/strazoon-dark-100.png"
        alt="strazoon"
      />
      <Box
        sx={{
          flexGrow: 1,
          width: "80%",
          maxWidth: 400,
          color: (theme) => theme.palette.wht.main,
          backgroundColor: (theme) => theme.palette.grey[800],
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Typography component="h1" variant="h1">
          Login
        </Typography>
        <Box
          sx={{
            mt: (theme) => theme.spacing(6),
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mr: (theme) => theme.spacing(4),
              minWidth: 80,
              width: "30%",
              maxWidth: 100,
            }}
            component="p"
            variant="h4"
          >
            Email
          </Typography>
          <TextField
            sx={whiteTextFieldSx}
            id="email"
            variant="standard"
            type="text"
          />
        </Box>
        <Box
          sx={{
            mt: (theme) => theme.spacing(4),
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mr: (theme) => theme.spacing(4),
              minWidth: 80,
              width: "30%",
              maxWidth: 100,
            }}
            component="p"
            variant="h4"
          >
            Passwort
          </Typography>
          <TextField
            sx={whiteTextFieldSx}
            id="password"
            variant="standard"
            type="password"
          />
        </Box>
      </Box>
      <Grid sx={{ mb: (theme) => theme.spacing(8) }} container spacing={3}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: isMediaSizeSmallerMd ? "center" : "flex-end",
          }}
          item
          xs={12}
          md={4}
        >
          <Button
            sx={{ width: 270, height: 47 }}
            variant="contained"
            color="primary"
            onClick={handleRegisterClicked}
          >
            Registrieren
          </Button>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
          md={4}
        >
          <Button
            sx={{ width: 270, height: 47 }}
            variant="contained"
            color="primary"
            onClick={handleLoginClicked}
          >
            Login
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: isMediaSizeSmallerMd ? "center" : "flex-start",
          }}
          item
          xs={12}
          md={4}
        >
          <Button
            sx={{ width: 270, height: 47 }}
            variant="outlined"
            color="primary"
            onClick={handleForgotPasswordClicked}
          >
            Passwort vergessen
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
