import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ProfileIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 19, height: 20, ...sx }}
      component="svg"
      viewBox="0 0 19 20"
    >
      <g transform="translate(.5 1)" fill="currentColor" fillRule="nonzero">
        <circle cx="9" cy="7.028" r="4.3" />
        <path
          d="M9-.2a9.171 9.171 0 0 1 6.502 2.698A9.172 9.172 0 0 1 18.2 9a9.172 9.172 0 0 1-2.698 6.502A9.171 9.171 0 0 1 9 18.2a9.172 9.172 0 0 1-6.502-2.698A9.171 9.171 0 0 1-.2 9a9.171 9.171 0 0 1 2.698-6.502A9.172 9.172 0 0 1 9-.2zm0 1.443c-2.138 0-4.078.87-5.482 2.275A7.733 7.733 0 0 0 1.243 9c0 2.029.784 3.877 2.064 5.26C4.23 13.177 6.42 12.39 9 12.39c2.58 0 4.77.787 5.693 1.87A7.725 7.725 0 0 0 16.757 9c0-2.139-.87-4.078-2.275-5.482A7.732 7.732 0 0 0 9 1.243z"
          stroke="currentColor"
          strokeWidth=".4"
        />
      </g>
    </Box>
  );
}

ProfileIcon.defaultProps = {
  sx: undefined,
};
