import React from "react";
import { Box, Typography } from "@mui/material";
import WhtButton from "../../custom/buttons/WhtButton";

interface StyleSelectorButtonProps {
  label: string;
  backgroundImageSrc: string;
  active?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function StyleSelectorButton({
  label,
  backgroundImageSrc,
  active,
  onClick,
}: StyleSelectorButtonProps): React.ReactElement {
  return (
    <WhtButton
      sx={{
        pointerEvents: "all",
        p: 0,
        width: 86,
        height: "43px",
        border: 3,
        borderColor: (theme) =>
          active ? theme.palette.primary.main : theme.palette.wht.main,
        color: (theme) =>
          active ? theme.palette.primary.main : theme.palette.blk.main,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundImage: `url(${backgroundImageSrc})`,
        "&:hover": {
          borderColor: (theme) => theme.palette.grey[300],
        },
      }}
      variant="contained"
      onClick={onClick}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: (theme) => theme.palette.wht.main,
          opacity: 0.75,
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            lineHeight: "100%",
            py: 0,
            px: (theme) => theme.spacing(1),
          }}
          component="p"
          variant="body1"
        >
          {label}
        </Typography>
      </Box>
    </WhtButton>
  );
}

StyleSelectorButton.defaultProps = {
  active: false,
};
