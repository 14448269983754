import React, { useEffect, useState, useContext } from "react";
import { GitgraphUserApi } from "@gitgraph/core";
import { ReactSvgElement } from "@gitgraph/react/lib/types";
import { Gitgraph, templateExtend, TemplateName } from "@gitgraph/react";
import ApiContext from "../../tech/context/ApiContext";
import { GitGraphElement } from "./types";
import GitGraphDot from "./GitGraphDot";
import { formatTimestamp } from "../../tech/utils/TimestampFormatter";
import { Commit } from "../../api/versioning/types";

const graphColors: string[] = ["grey", "blue", "green", "#ed7021"];

const graphOptions = {
  template: templateExtend(TemplateName.Metro, {
    commit: {
      message: {
        displayHash: false,
        displayAuthor: false,
      },
    },
    colors: graphColors,
  }),
};

function compare(a: GitGraphElement, b: GitGraphElement): number {
  if (a.timestamp < b.timestamp) {
    return -1;
  }
  if (a.timestamp > b.timestamp) {
    return 1;
  }
  return 0;
}

function buildSubject(commit: Commit, element: GitGraphElement): string {
  if (element.status === "OPEN") {
    return `${commit.title} - ${formatTimestamp(element.timestamp)}`;
  }

  const approver = commit.approvedBy ? ` - ${commit.approvedBy}` : "";
  return `${element.branchName} wurde gemerged in ${
    element.targetBranchName
  } - ${formatTimestamp(element.timestamp)}${approver}`;
}

export default function GitGraph(): React.ReactElement {
  const { branches, merges, masterBranch } = useContext(ApiContext);
  const [masterBranchName, setMasterBranchName] = useState("master");
  const [gitGraphElements, setGitGraphElements] = useState<GitGraphElement[]>(
    []
  );
  const [gitGraphApi, setGitGraphApi] =
    useState<GitgraphUserApi<ReactSvgElement>>();

  useEffect(() => {
    if (masterBranch && masterBranch.name !== masterBranchName) {
      setMasterBranchName(masterBranch.name);
    }
  }, [masterBranch, masterBranchName]);

  useEffect(() => {
    if (branches && merges) {
      const elements: GitGraphElement[] = [];

      branches.forEach((branch) => {
        let commitId = "";
        branch.commits.forEach((commit: Commit) => {
          commitId = commit.id;
          elements.push({
            id: branches.indexOf(branch),
            commitId: commit.id,
            branchName: branch.name,
            status: "OPEN",
            alreadyMerged: false,
            timestamp: commit.pushedAt,
            sourceBranchName: masterBranchName,
            targetBranchName: "",
          });
        });

        if (branch.status !== "OPEN") {
          let targetBranchName = "";
          let createdAt = "";
          merges.some((merge) => {
            if (merge.sourceBranchId === branch.id) {
              const { targetBranchId } = merge;
              branches.some((item) => {
                if (item.id === targetBranchId) {
                  targetBranchName = item.name;
                  return true;
                }
                return false;
              });
              createdAt = merge.createdAt;
              return true;
            }
            return false;
          });

          elements.push({
            id: branches.indexOf(branch),
            commitId,
            branchName: branch.name,
            status: "MERGED",
            alreadyMerged: true,
            timestamp: createdAt,
            sourceBranchName: masterBranchName,
            targetBranchName,
          });
        }
      });

      setGitGraphElements(elements.sort(compare));
    }
  }, [branches, merges, masterBranchName]);

  const createMasterElement = (): void => {
    gitGraphApi
      ?.branch({
        name: masterBranchName,
      })
      .commit({
        subject: "Master",
        author: "",
      });
  };

  const createOpenBranchElements = (gitGraphElement: GitGraphElement): void => {
    branches[gitGraphElement.id].commits.forEach((commit: Commit) => {
      if (gitGraphElement.commitId === commit.id) {
        gitGraphApi
          ?.branch({
            name: masterBranchName,
          })
          .branch({
            name: gitGraphElement.branchName,
          })
          .commit({
            subject: buildSubject(commit, gitGraphElement),
            renderDot: (gitGraphCommit) => (
              <GitGraphDot
                color={gitGraphCommit.style.dot.color}
                commit={commit}
                alreadyMerged={gitGraphElement.alreadyMerged}
              />
            ),
          });
      }
    });
  };

  const createClosedBranchElements = (
    gitGraphElement: GitGraphElement
  ): void => {
    branches[gitGraphElement.id].commits.forEach((commit: Commit) => {
      if (gitGraphElement.commitId === commit.id) {
        gitGraphApi?.branch({ name: gitGraphElement.targetBranchName }).merge({
          branch: gitGraphElement.branchName,
          commitOptions: {
            subject: buildSubject(commit, gitGraphElement),
            renderDot: (gitGraphCommit) => (
              <GitGraphDot
                color={gitGraphCommit.style.dot.color}
                commit={commit}
                alreadyMerged={gitGraphElement.alreadyMerged}
              />
            ),
          },
        });
      }
    });
  };

  const createGitGraph = (): void => {
    createMasterElement();
    gitGraphElements.forEach((gitGraphElement) => {
      if (gitGraphElement.status === "OPEN") {
        createOpenBranchElements(gitGraphElement);
      } else {
        createClosedBranchElements(gitGraphElement);
      }
    });
  };

  useEffect(() => {
    if (gitGraphApi) {
      gitGraphApi.clear();
      createGitGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gitGraphElements]);

  return <Gitgraph options={graphOptions}>{setGitGraphApi}</Gitgraph>;
}
