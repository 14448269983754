import { Box } from "@mui/material";
import { IconProps } from "./types";

export function SaveIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 18, height: 18, ...sx }}
      component="svg"
      viewBox="0 0 18 18"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M9.994 11.57V.998a.999.999 0 0 0-2 0V11.57a.999.999 0 0 0 2 0z" />
        <path d="m4.955 9.611 3.333 3.334a.995.995 0 0 0 1.413 0l3.332-3.334a.996.996 0 0 0 0-1.413.995.995 0 0 0-1.413 0l-2.624 2.625-2.628-2.625a.995.995 0 0 0-1.413 0 1.003 1.003 0 0 0 0 1.413z" />
        <path d="M15.993 12.808v2.857a.341.341 0 0 1-.335.335H2.33a.325.325 0 0 1-.236-.099.341.341 0 0 1-.1-.236v-2.857a.999.999 0 0 0-.997-.999c-.549 0-.998.45-.998.999v2.857c0 .617.244 1.211.686 1.65A2.336 2.336 0 0 0 2.335 18h13.33a2.32 2.32 0 0 0 1.65-.686 2.337 2.337 0 0 0 .685-1.65v-2.856a.999.999 0 0 0-.998-.999 1 1 0 0 0-1.01.999z" />
      </g>
    </Box>
  );
}

SaveIcon.defaultProps = {
  sx: undefined,
};
