/* eslint-disable @typescript-eslint/no-explicit-any */
import { ViewStateProps } from "@deck.gl/core/lib/deck";
import { PickInfo } from "deck.gl";
import { MarkerData } from "../../../api/layers/types";
import { ClusterIconMapLayer } from "./ClusterIconMapLayer";

interface ClusterIconLayerProps {
  id: string;
  data?: MarkerData;
  viewState?: ViewStateProps;
  onLeafIconClick?: (clickData: unknown) => void;
  onHover?: (hoverData: PickInfo<unknown>) => void;
  onViewStateChange?: (viewState: ViewStateProps) => void;
}

const EMPTY_DATA: MarkerData = {
  markers: [],
};

export default class ClusterIconLayer {
  private props;

  private renderData?: MarkerData;

  constructor(props: ClusterIconLayerProps) {
    this.props = props;
    this.renderData = props.data || EMPTY_DATA;
  }

  private handleIconClick = (d: any): void => {
    const { viewState, onLeafIconClick, onViewStateChange } = this.props;
    if (d.object) {
      if (viewState && onViewStateChange && d.object.cluster) {
        let newZoom = (viewState.zoom || 0) + 1;
        if (
          d.layer &&
          d.layer.state &&
          d.layer.state.index &&
          d.layer.state.index.getClusterExpansionZoom
        ) {
          newZoom = d.layer.state.index.getClusterExpansionZoom(
            d.object.cluster_id
          );
        }
        if (viewState.maxZoom && newZoom > viewState.maxZoom) {
          newZoom = viewState.maxZoom;
        }
        onViewStateChange({
          ...viewState,
          zoom: newZoom,
          longitude: d.objects[0] ? d.objects[0].lon : d.coordinate[0],
          latitude: d.objects[0] ? d.objects[0].lat : d.coordinate[1],
        });
      } else if (onLeafIconClick) {
        onLeafIconClick(d.object);
      }
    }
  };

  render = (visible?: boolean): ClusterIconMapLayer => {
    const { id, onHover } = this.props;
    return new ClusterIconMapLayer({
      id,
      visible: visible || false,
      pickable: true,
      data: this.renderData?.markers,
      sizeScale: 50,
      getPosition: (d: any) => [d.lon, d.lat],
      onHover,
      onClick: this.handleIconClick,
    });
  };
}
