import React from "react";
import DialogContent from "@mui/material/DialogContent";
import { SxProps, Theme } from "@mui/material";

interface BaseDialogContentProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export function BaseDialogContent({
  sx,
  children,
}: BaseDialogContentProps): React.ReactElement {
  return <DialogContent sx={sx}>{children}</DialogContent>;
}

BaseDialogContent.defaultProps = {
  sx: undefined,
};
