import { SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";

interface DateInputProps {
  sx?: SxProps<Theme>;
  label?: string;
  variant?: TextFieldProps["variant"];
  tabIndex?: number;
  value: Date | null;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
}

export default function DateInput({
  sx,
  label,
  variant,
  tabIndex,
  value,
  onChange,
}: DateInputProps): React.ReactElement {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <DatePicker
        disableOpenPicker
        mask="__.__.____"
        label={label}
        value={value}
        onChange={onChange}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderInput={(params: any) => {
          const textFieldSx = params.sx;
          return (
            <TextField
              sx={{ ...textFieldSx, ...sx }}
              tabIndex={tabIndex}
              variant={variant}
              placeholder="dd.mm.yyyy"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

DateInput.defaultProps = {
  sx: undefined,
  label: undefined,
  variant: "outlined",
  tabIndex: undefined,
};
