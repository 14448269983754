export const GER_CENTER_LAT = 51.31844517715381;
export const GER_CENTER_LON = 10.366936779793633;

export const REGION_CENTER_LAT =
  Number(process.env.REACT_APP_REGION_CENTER_LAT) || GER_CENTER_LAT;
export const REGION_CENTER_LON =
  Number(process.env.REACT_APP_REGION_CENTER_LON) || GER_CENTER_LON;

export const PUBLIC_OSM_TILES_URL =
  "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png";
export const OWN_OSM_TILES_URL =
  process.env.REACT_APP_TILESERVER_URL || PUBLIC_OSM_TILES_URL;
