import React, { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface DropDownProps {
  label: string;
  children: React.ReactNode;
}

interface DropDownItemProps {
  id: string;
  onClick: (event: React.MouseEvent) => void;
  children: React.ReactNode;
}

export default function DropDown({
  label,
  children,
}: DropDownProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleDropDownClicked = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <Button
        sx={{
          width: 200,
          zIndex: (theme) =>
            open ? theme.zIndex.modal + 1 : theme.zIndex.appBar - 1,
          py: (theme) => theme.spacing(1),
          px: (theme) => theme.spacing(2),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          borderBottomLeftRadius: open ? 0 : 4,
          borderBottomRightRadius: open ? 0 : 4,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        size="small"
        variant="contained"
        color="primary"
        endIcon={
          open ? (
            <KeyboardArrowUpIcon fontSize="inherit" />
          ) : (
            <KeyboardArrowDownIcon fontSize="inherit" />
          )
        }
        onClick={handleDropDownClicked}
      >
        {label}
      </Button>
      <Menu
        sx={{
          "& .MuiPaper-root": {
            width: 200,
            border: 1,
            borderColor: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.wht.main,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.16)",
          },
        }}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onMouseUp={handleClose}
      >
        {children}
      </Menu>
    </>
  );
}

export function DropDownItem({
  id,
  onClick,
  children,
}: DropDownItemProps): React.ReactElement {
  return (
    <MenuItem id={id} onClick={onClick}>
      <Typography
        sx={{ overflowWrap: "break-word" }}
        component="p"
        variant="body1"
      >
        {children}
      </Typography>
    </MenuItem>
  );
}
