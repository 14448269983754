export default function Contributions(): React.ReactElement {
  return (
    <>
      Stadt Frankfurt am Main, Straßenverkehrsamt (
      <a href="https://www.govdata.de/dl-de/by-2-0">
        Datenlizenz Deutschland -Namensnennung- Version 2.0
      </a>
      ) Verkehrsdaten{" "}
      <a href="https://service.mdm-portal.de/mdm-portal-application/publDetail.do?publicationId=2386001">
        (MDM ID 2386001)
      </a>{" "}
      | Pendlerverflechtungen{" "}
      <a href="https://statistik.arbeitsagentur.de/">
        (Statistik der Bundesagentur für Arbeit)
      </a>
    </>
  );
}
