import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ChevronClosedIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 15, height: 10, ...sx }}
      component="svg"
      viewBox="0 0 15 10"
    >
      <g
        id="MobilityOps-Clickdummy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01---13-MobilityOps-Clickdummy"
          transform="translate(-217.000000, -79.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="Menu" transform="translate(8.000000, 59.000000)">
            <g id="Verkehrslage" transform="translate(16.000000, 10.000000)">
              <g
                id="chevron-closed"
                transform="translate(192.000000, 10.610656)"
              >
                <path
                  d="M9.24710111,8.23302071 L15.4443264,1.68752411 C15.8240355,1.28647616 15.8067369,0.653547531 15.405689,0.273838449 C15.2199653,0.097996764 14.9739256,-9.55627221e-16 14.7181649,0 L2.32385896,0 C1.77157421,1.01453063e-16 1.32385896,0.44771525 1.32385896,1 C1.32385896,1.25575673 1.42185264,1.5017929 1.59768939,1.68751565 L7.79476999,8.23301225 C8.1744744,8.63406462 8.80740283,8.65137057 9.2084552,8.27166617 C9.22168685,8.25913884 9.23457363,8.24625221 9.24710111,8.23302071 Z"
                  transform="translate(8.521031, 4.500000) rotate(-360.000000) translate(-8.521031, -4.500000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Box>
  );
}

ChevronClosedIcon.defaultProps = {
  sx: undefined,
};
