import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableColumn } from "./types";
import CreateOrEditRuleDialog from "../dialog/CreateOrEditRuleDialog";
import ApiContext from "../../tech/context/ApiContext";
import BackdropContext from "../../tech/context/BackdropContext";
import Logger from "../../tech/utils/Logger";
import { Rule } from "../../api/versioning/types";
import { deleteRuleById } from "../../api/versioning/versioning-api";

const columns: TableColumn[] = [
  { id: "rowNumber", label: "Zeile", minWidth: 50, align: "left" },
  { id: "action", label: "Aktion", minWidth: 50, align: "left" },
  { id: "createdBy", label: "Quelle", minWidth: 50, align: "left" },
  { id: "format", label: "Datenformat", minWidth: 50, align: "left" },
  { id: "region", label: "Region", minWidth: 50, align: "left" },
  { id: "type", label: "Kategorie", minWidth: 50, align: "left" },
  { id: "editButton", label: "", minWidth: 50, align: "left" },
  { id: "deleteButton", label: "", minWidth: 50, align: "left" },
];

interface RulesTableProps {
  rules: Rule[];
  disableActionButtons?: boolean;
}

export default function RulesTable({
  rules,
  disableActionButtons,
}: RulesTableProps): React.ReactElement {
  const { fetchDatabaseData } = useContext(ApiContext);
  const { setBackdropActive } = useContext(BackdropContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteRule = (id: string): void => {
    setBackdropActive(true);
    deleteRuleById(id)
      .then(() => fetchDatabaseData())
      .then(() => {
        setBackdropActive(false);
      })
      .catch((error) => {
        Logger.error(error);
      });
  };

  return (
    <Paper>
      <TableContainer
        sx={{
          maxHeight: 440,
          fontSize: "10pt",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: TableColumn) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rules
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Rule, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.action}</TableCell>
                  <TableCell>{row.createdBy}</TableCell>
                  <TableCell>{row.format}</TableCell>
                  <TableCell>{row.region}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  {!disableActionButtons && (
                    <>
                      <TableCell>
                        <CreateOrEditRuleDialog rule={row} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteRule(row.id)}
                        >
                          <DeleteIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rules.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

RulesTable.defaultProps = {
  disableActionButtons: false,
};
