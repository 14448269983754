import React from "react";
import { SxProps, Theme } from "@mui/material";
import { LocationCenteredIcon } from "../../../icons/LocationCenteredIcon";
import { LocationNotCenteredIcon } from "../../../icons/LocationNotCenteredIcon";
import { MapConfigId } from "../../../../store/slices/maps/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resetViewStateLocation,
  selectMapConfigById,
} from "../../../../store/slices/maps/slice";
import CircleButton from "../../../custom/buttons/CircleButton";
import {
  equalMapConfigLocation,
  isMapCentered,
} from "../../../../store/slices/maps/utils";

interface LocationCenterButtonProps {
  sx?: SxProps<Theme>;
  mapConfigId: MapConfigId;
}

export default function LocationCenterButton({
  sx,
  mapConfigId,
}: LocationCenterButtonProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const mapConfig = useAppSelector(
    (state) => selectMapConfigById(state, mapConfigId),
    equalMapConfigLocation
  );

  const handleCenterMap = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(resetViewStateLocation(mapConfig.id));
  };

  return (
    <CircleButton
      sx={sx}
      size={40}
      onClick={handleCenterMap}
      label={
        isMapCentered(mapConfig) ? (
          <LocationCenteredIcon />
        ) : (
          <LocationNotCenteredIcon />
        )
      }
      color="white"
    />
  );
}

LocationCenterButton.defaultProps = {
  sx: undefined,
};
