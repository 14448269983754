import { Box } from "@mui/material";
import { IconProps } from "./types";

export function CapacityIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 22, height: 22, ...sx }}
      component="svg"
      viewBox="0 0 22 22"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M7.318 15.21v-.397c0-1.007.81-1.826 1.806-1.826h.093l.231-.726H2.964c-.092 0-.185-.094-.139-.187l.672-2.107a.82.82 0 0 1 .787-.586h5.072c.347 0 .671.235.787.586l.208.655a3.734 3.734 0 0 1 1.205-.796l-.093-.28A2.192 2.192 0 0 0 9.356 8H4.26a2.22 2.22 0 0 0-2.107 1.545l-.58 1.803h-.88A.686.686 0 0 0 0 12.05v.398c0 .398.301.703.695.703H.95a9.517 9.517 0 0 0-.209 1.99v3.418c0 .398.301.702.695.702h1.158a.686.686 0 0 0 .695-.702v-1.1h4.77c0-.258.023-.492.046-.75a1.776 1.776 0 0 1-.787-1.498zm-3.034.328H2.872a.442.442 0 0 1-.44-.445v-.678c0-.258.231-.469.51-.422l1.32.21a.396.396 0 0 1 .346.329l.093.468a.43.43 0 0 1-.417.538z" />
        <path d="M21.305 14.11h-.88l-.579-1.802a2.192 2.192 0 0 0-2.107-1.546h-5.072a2.22 2.22 0 0 0-2.107 1.546L9.98 14.11h-.88a.686.686 0 0 0-.695.703v.398c0 .398.301.702.695.702h.255a9.517 9.517 0 0 0-.209 1.99v3.395c0 .398.301.702.695.702H11a.686.686 0 0 0 .695-.702V20.22h7.017v1.077c0 .398.3.702.694.702h1.158a.686.686 0 0 0 .695-.702v-3.395c0-.679-.07-1.335-.209-1.99h.255A.686.686 0 0 0 22 15.21v-.398a.686.686 0 0 0-.695-.703zm-10.073.726.671-2.107a.82.82 0 0 1 .787-.585h5.072c.347 0 .672.234.787.585l.672 2.107c.023.093-.047.187-.14.187h-7.71c-.116 0-.186-.093-.14-.187zm1.458 3.441h-1.412a.442.442 0 0 1-.44-.445v-.678c0-.258.231-.469.486-.422l1.32.211a.396.396 0 0 1 .348.328l.092.468a.406.406 0 0 1-.394.538zm6.878-.421a.442.442 0 0 1-.44.445h-1.412c-.278 0-.487-.258-.417-.515l.092-.468c.047-.164.186-.305.348-.328l1.32-.21c.255-.048.486.163.486.42v.656h.023z" />
        <path
          d="M17.469 1c2.204 0 4 1.796 4 4 0 2.204-1.796 4-4 4-2.205 0-4-1.796-4-4 0-2.204 1.795-4 4-4zm0 .8a3.194 3.194 0 0 0-3.2 3.2c0 1.772 1.427 3.2 3.2 3.2 1.772 0 3.2-1.428 3.2-3.2 0-1.772-1.428-3.2-3.2-3.2zm0 .8c.22 0 .4.18.4.4v2a.4.4 0 0 1-.4.4h-1.734a.4.4 0 1 1 0-.8h1.334V3a.4.4 0 0 1 .4-.4z"
          stroke="currentColor"
          strokeWidth=".4"
        />
      </g>
    </Box>
  );
}

CapacityIcon.defaultProps = {
  sx: undefined,
};
