import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ZensusRasterBigIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 23, height: 23, ...sx }}
      component="svg"
      viewBox="0 0 23 23"
    >
      <path
        d="M21.4 7.1a1.1 1.1 0 0 0 0-2.2h-3.3V1.6a1.1 1.1 0 0 0-2.2 0v3.3H7.1V1.6a1.1 1.1 0 0 0-2.2 0v3.3H1.6a1.1 1.1 0 0 0 0 2.2h3.3v8.8H1.6a1.1 1.1 0 0 0 0 2.2h3.3v3.3a1.1 1.1 0 0 0 2.2 0v-3.3h8.8v3.3a1.1 1.1 0 0 0 2.2 0v-3.3h3.3a1.1 1.1 0 0 0 0-2.2h-3.3V7.1h3.3zm-5.5 8.8H7.1V7.1h8.8v8.8z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

ZensusRasterBigIcon.defaultProps = {
  sx: undefined,
};
