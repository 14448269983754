/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultHeatmapSettings } from "../../../components/map/layer/HeatmapLayer";
import { getHeatmapSettingsById } from "./selectors";
import {
  buildFlowDataHandler,
  buildGeoJsonDataHandler,
  buildHeatmapDataHandler,
  buildMarkerDataHandler,
  buildTazesGeoJsonDataHandler,
} from "./thunk-handler";
import {
  LayerState,
  LayerId,
  HeatmapSettingsValueUpdate,
  PoiSettingsUpdate,
  TimeseriesSettingsUpdate,
  Layer,
} from "./types";

const initialState: LayerState = {
  layersById: {
    "induction-loops": {
      id: "induction-loops",
      categoryId: "situation",
      mapConfigId: "main",
      group: 0,
      label: "Zählstationen",
      selected: false,
      editing: false,
      iconId: "InductionLoopIcon",
      additionalComponentId: "LayerBasedChartDialog",
    },
    "traffic-information": {
      id: "traffic-information",
      categoryId: "situation",
      mapConfigId: "main",
      group: 1,
      label: "Verkehrsmeldungen",
      selected: false,
      editing: false,
      iconId: "TrafficInformationIcon",
    },
    "occupancy-avg": {
      id: "occupancy-avg",
      categoryId: "situation",
      mapConfigId: "main",
      group: 2,
      label: "Verkehrsbelegung Ø/Tag",
      selected: false,
      editing: false,
      iconId: "TrafficOccupancyAvgIcon",
      editComponentId: "HeatmapController",
    },
    "occupancy-daily": {
      id: "occupancy-daily",
      categoryId: "situation",
      mapConfigId: "main",
      group: 2,
      label: "Verkehrsbelegung Tagesverlauf",
      selected: false,
      editing: false,
      iconId: "TrafficOccupancyDailyIcon",
      editComponentId: "HeatmapController",
    },
    "flow-avg": {
      id: "flow-avg",
      categoryId: "situation",
      mapConfigId: "main",
      group: 2,
      label: "Verkehrsfluss Ø/Tag",
      selected: false,
      editing: false,
      iconId: "TrafficFlowAvgIcon",
      editComponentId: "HeatmapController",
    },
    "flow-daily": {
      id: "flow-daily",
      categoryId: "situation",
      mapConfigId: "main",
      group: 2,
      label: "Verkehrsfluss Tagesverlauf",
      selected: false,
      editing: false,
      iconId: "TrafficFlowDailyIcon",
      editComponentId: "HeatmapController",
    },
    capacity: {
      id: "capacity",
      categoryId: "network",
      mapConfigId: "main",
      group: 0,
      label: "Kapazitäten",
      selected: false,
      editing: false,
      iconId: "CapacityIcon",
    },
    "road-network": {
      id: "road-network",
      categoryId: "network",
      mapConfigId: "main",
      group: 1,
      label: "Straßenverkehrsnetz",
      selected: false,
      editing: false,
      iconId: "StreetNetworkIcon",
    },
    "bike-network": {
      id: "bike-network",
      categoryId: "network",
      mapConfigId: "main",
      group: 1,
      label: "Radverkehrsnetz",
      selected: false,
      editing: false,
      iconId: "BikeNetworkIcon",
    },
    "city-areas": {
      id: "city-areas",
      categoryId: "network",
      mapConfigId: "main",
      group: 2,
      label: "Verwaltungsgrenzen",
      selected: false,
      editing: false,
      iconId: "AdminBoundaryIcon",
    },
    "points-of-interest": {
      id: "points-of-interest",
      categoryId: "demand",
      mapConfigId: "main",
      group: 0,
      label: "Points of Interest",
      iconId: "PointsOfInterestIcon",
      selected: false,
      editing: false,
      editComponentId: "PoiController",
    },
    commuters: {
      id: "commuters",
      categoryId: "demand",
      mapConfigId: "main",
      group: 1,
      label: "Pendlerdaten",
      selected: false,
      editing: false,
      iconId: "CommuterIcon",
    },
    tazes: {
      id: "tazes",
      categoryId: "demand",
      mapConfigId: "main",
      group: 2,
      label: "Verkehrszellen",
      selected: false,
      editing: false,
      iconId: "TazIcon",
    },
    "zensus-1km-wms": {
      id: "zensus-1km-wms",
      categoryId: "debug",
      mapConfigId: "main",
      group: 0,
      label: "Zensus 1x1 km Raster (WMS)",
      selected: false,
      editing: false,
      iconId: "ZensusRasterBigIcon",
    },
    "zensus-100m": {
      id: "zensus-100m",
      categoryId: "debug",
      mapConfigId: "main",
      group: 1,
      label: "Zensus 100x100 m Raster",
      selected: false,
      editing: false,
      iconId: "ZensusRasterSmallIcon",
    },
    "school-stats": {
      id: "school-stats",
      categoryId: "debug",
      mapConfigId: "main",
      group: 2,
      label: "Schulen (amtliche Statistik)",
      selected: false,
      editing: false,
      iconId: "SchoolsIcon",
    },
  },
  dataById: {
    "induction-loops": { settings: null, data: null },
    "traffic-information": { settings: null, data: null },
    "occupancy-avg": { settings: defaultHeatmapSettings, data: null },
    "occupancy-daily": { settings: defaultHeatmapSettings, data: null },
    "flow-avg": { settings: defaultHeatmapSettings, data: null },
    "flow-daily": { settings: defaultHeatmapSettings, data: null },
    capacity: { settings: null, data: null },
    "road-network": { settings: null, data: null },
    "bike-network": { settings: null, data: null },
    "city-areas": { settings: null, data: null },
    "points-of-interest": { settings: null, data: null },
    commuters: { settings: null, data: null },
    tazes: { settings: null, data: null },
    "zensus-1km-wms": { settings: null, data: "wms-zensus-gitter" },
    "zensus-100m": { settings: null, data: null },
    "school-stats": { settings: null, data: null },
  },
  statusesById: {
    "induction-loops": { status: "idle" },
    "traffic-information": { status: "idle" },
    "occupancy-avg": { status: "idle" },
    "occupancy-daily": { status: "idle" },
    "flow-avg": { status: "idle" },
    "flow-daily": { status: "idle" },
    capacity: { status: "idle" },
    "road-network": { status: "idle" },
    "bike-network": { status: "idle" },
    "city-areas": { status: "idle" },
    "points-of-interest": { status: "idle" },
    commuters: { status: "idle" },
    tazes: { status: "idle" },
    "zensus-1km-wms": { status: "idle" },
    "zensus-100m": { status: "idle" },
    "school-stats": { status: "idle" },
  },
};

function disableEditingForAllExceptThis(state: LayerState, id: LayerId): void {
  Object.values(state.layersById)
    .filter((l) => l.editing && l.id !== id)
    .forEach((l) => {
      state.layersById[l.id].editing = false;
    });
}

function deselectLayersInCategoryGroupExceptThis(
  state: LayerState,
  layer: Layer
): void {
  Object.values(state.layersById)
    .filter(
      (l) =>
        l.selected &&
        l.categoryId === layer.categoryId &&
        l.group === layer.group &&
        l.id !== layer.id
    )
    .forEach((l) => {
      state.layersById[l.id].selected = false;
    });
}

export const layerSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    toggleSelectedLayerById: (state, action: PayloadAction<LayerId>) => {
      const id = action.payload;
      const layer = state.layersById[id];
      if (layer) {
        if (!layer.selected) {
          deselectLayersInCategoryGroupExceptThis(state, layer);
          disableEditingForAllExceptThis(state, id);
        }
        state.layersById[id] = {
          ...layer,
          selected: !layer.selected,
          editing: !layer.selected,
        };
      }
    },
    toggleEditingLayerById: (state, action: PayloadAction<LayerId>) => {
      const id = action.payload;
      const layer = state.layersById[id];
      state.layersById[id].editing = !layer.editing;
      disableEditingForAllExceptThis(state, id);
    },
    updateHeatmapSettingsValue: (
      state,
      action: PayloadAction<HeatmapSettingsValueUpdate>
    ) => {
      const { id, value, name } = action.payload;
      const heatmapSettings = getHeatmapSettingsById(state, id);
      if (heatmapSettings) {
        state.dataById[id].settings = {
          ...heatmapSettings,
          [name]: value,
        };
      }
    },
    updatePoiSettings: (state, action: PayloadAction<PoiSettingsUpdate>) => {
      const { id, poiKey } = action.payload;
      state.dataById[id].settings = poiKey ? { poiKey } : null;
    },
    clearPoiData: (state, action: PayloadAction<LayerId>) => {
      const id = action.payload;
      state.dataById[id] = {
        settings: null,
        data: null,
      };
    },
    updateTimeseriesSettings: (
      state,
      action: PayloadAction<TimeseriesSettingsUpdate>
    ) => {
      const { id, dataRef } = action.payload;
      state.dataById[id].settings = dataRef ? { dataRef } : null;
    },
  },
  extraReducers: (builder) => {
    buildMarkerDataHandler(builder);
    buildHeatmapDataHandler(builder);
    buildGeoJsonDataHandler(builder);
    buildTazesGeoJsonDataHandler(builder);
    buildFlowDataHandler(builder);
  },
});

export const {
  toggleSelectedLayerById,
  toggleEditingLayerById,
  updateHeatmapSettingsValue,
  updatePoiSettings,
  clearPoiData,
  updateTimeseriesSettings,
} = layerSlice.actions;

export default layerSlice.reducer;
