import React from "react";
import { useNavigate } from "react-router-dom";
import { Commit } from "../../api/versioning/types";

interface GitDotProps {
  color: string | undefined;
  commit: Commit;
  alreadyMerged: boolean;
}

export default function GitGraphDot({
  color,
  commit,
  alreadyMerged,
}: GitDotProps): React.ReactElement {
  const navigate = useNavigate();

  const handleClick = (): void => {
    if (!alreadyMerged) {
      navigate(`/changes/${commit.id}`);
    }
  };

  return (
    <svg
      style={{ cursor: alreadyMerged ? "default" : "pointer" }}
      viewBox="0 0 25 25"
      height="40px"
      width="40px"
      onClick={handleClick}
    >
      <circle fill={color || "#000000"} cx="8" cy="8" r="8" />
    </svg>
  );
}
