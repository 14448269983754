/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
  fetchFlowData,
  fetchGeoJsonData,
  fetchHeatmapData,
  fetchMarkerData,
  fetchTazesGeoJsonData,
} from "./thunks";
import { LayerState } from "./types";

export function buildMarkerDataHandler(
  builder: ActionReducerMapBuilder<LayerState>
): void {
  builder.addCase(fetchMarkerData.pending, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId].status = "loading";
  });
  builder.addCase(fetchMarkerData.fulfilled, (state, action) => {
    const { layerId } = action.meta.arg;
    state.dataById[layerId].data = action.payload;
    state.statusesById[layerId].status = "succeeded";
  });
  builder.addCase(fetchMarkerData.rejected, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId] = {
      status: "failed",
      error: action.error.message,
    };
  });
}

export function buildHeatmapDataHandler(
  builder: ActionReducerMapBuilder<LayerState>
): void {
  builder.addCase(fetchHeatmapData.pending, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId].status = "loading";
  });
  builder.addCase(fetchHeatmapData.fulfilled, (state, action) => {
    const { layerId } = action.meta.arg;
    state.dataById[layerId].data = action.payload;
    state.statusesById[layerId].status = "succeeded";
  });
  builder.addCase(fetchHeatmapData.rejected, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId] = {
      status: "failed",
      error: action.error.message,
    };
  });
}

export function buildGeoJsonDataHandler(
  builder: ActionReducerMapBuilder<LayerState>
): void {
  builder.addCase(fetchGeoJsonData.pending, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId].status = "loading";
  });
  builder.addCase(fetchGeoJsonData.fulfilled, (state, action) => {
    const { layerId } = action.meta.arg;
    state.dataById[layerId].data = action.payload;
    state.statusesById[layerId].status = "succeeded";
  });
  builder.addCase(fetchGeoJsonData.rejected, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId] = {
      status: "failed",
      error: action.error.message,
    };
  });
}

export function buildTazesGeoJsonDataHandler(
  builder: ActionReducerMapBuilder<LayerState>
): void {
  builder.addCase(fetchTazesGeoJsonData.pending, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId].status = "loading";
  });
  builder.addCase(fetchTazesGeoJsonData.fulfilled, (state, action) => {
    const { layerId } = action.meta.arg;
    state.dataById[layerId].data = action.payload;
    state.statusesById[layerId].status = "succeeded";
  });
  builder.addCase(fetchTazesGeoJsonData.rejected, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId] = {
      status: "failed",
      error: action.error.message,
    };
  });
}

export function buildFlowDataHandler(
  builder: ActionReducerMapBuilder<LayerState>
): void {
  builder.addCase(fetchFlowData.pending, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId].status = "loading";
  });
  builder.addCase(fetchFlowData.fulfilled, (state, action) => {
    const { layerId } = action.meta.arg;
    state.dataById[layerId].data = action.payload;
    state.statusesById[layerId].status = "succeeded";
  });
  builder.addCase(fetchFlowData.rejected, (state, action) => {
    const { layerId } = action.meta.arg;
    state.statusesById[layerId] = {
      status: "failed",
      error: action.error.message,
    };
  });
}
