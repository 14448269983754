import { SxProps, Theme } from "@mui/material";
import CustomButton from "./CustomButton";
import { CustomButtonColor } from "./types";

interface CircleButtonProps {
  label: React.ReactNode;
  sx?: SxProps<Theme>;
  size?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: "text" | "contained" | "outlined";
  color?: CustomButtonColor;
}

export default function CircleButton({
  label,
  sx,
  size,
  onClick,
  variant,
  color,
}: CircleButtonProps): React.ReactElement {
  return (
    <CustomButton
      sx={{
        p: 0,
        fontSize: 27,
        fontWeight: "bold",
        ...sx,
        minWidth: size || 43,
        maxWidth: size || 43,
        minHeight: size || 43,
        maxHeight: size || 43,
        borderRadius: size ? size / 2 : 21.5,
      }}
      variant={variant || "contained"}
      color={color}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
}

CircleButton.defaultProps = {
  sx: undefined,
  size: 43,
  onClick: undefined,
  variant: "contained",
  color: "primary",
};
