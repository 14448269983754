import { Box } from "@mui/material";
import { IconProps } from "./types";

export function PointsOfInterestIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 16, height: 23, ...sx }}
      component="svg"
      viewBox="0 0 16 23"
    >
      <path
        d="M8 0c4.41 0 8 3.487 8 7.769 0 3.792-2.016 5.872-4.157 9.236L8 23l-3.843-5.995C2.016 13.641 0 11.561 0 7.77 0 3.568 3.456.132 7.751.004L8 0zm0 3.18c-2.456 0-4.41 1.958-4.41 4.282 0 2.386 1.953 4.282 4.41 4.282 2.456 0 4.41-1.896 4.41-4.282 0-2.324-1.953-4.281-4.41-4.281z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

PointsOfInterestIcon.defaultProps = {
  sx: undefined,
};
