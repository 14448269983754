import React from "react";
import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ChangeListIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box sx={sx} component="svg" viewBox="0 0 35.403 35.339">
      <path
        fill="currentColor"
        transform="translate(100 55.252)"
        d="M-71.65-19.913h-21.3c-.1-.023-.2-.053-.3-.069a7.582 7.582 0 0 1-6.263-5.01 15.5 15.5 0 0 1-.487-1.961v-21.258c.025-.111.057-.221.073-.334a7.421 7.421 0 0 1 1.351-3.427 7.639 7.639 0 0 1 6.48-3.276q9.8-.013 19.6 0 .242 0 .484.011a7.675 7.675 0 0 1 6.941 5.1 18.727 18.727 0 0 1 .48 1.931v21.257c-.025.111-.055.221-.073.333a7.578 7.578 0 0 1-5.023 6.216 15.693 15.693 0 0 1-1.963.487zm4.84-17.665c0-3.29.008-6.579 0-9.869a5.5 5.5 0 0 0-5.544-5.589q-9.939-.026-19.878 0a5.489 5.489 0 0 0-5.549 5.548q-.022 9.9 0 19.806a5.494 5.494 0 0 0 5.577 5.556q9.9.019 19.809-.009a5.916 5.916 0 0 0 1.829-.3 5.453 5.453 0 0 0 3.757-5.279c.009-3.286 0-6.575 0-9.865z"
      />
      <path
        fill="currentColor"
        transform="translate(-52.188 -38.399)"
        d="m66.587 49.425v-2.166h13.238v2.166z"
      />
      <path
        fill="currentColor"
        transform="translate(-52.195 -120.11)"
        d="M79.839 136.7v2.165H66.594V136.7z"
      />
      <path
        fill="currentColor"
        transform="translate(-52.314 -201.8)"
        d="m79.957 226.12v2.167h-13.232v-2.167z"
      />
      <path
        fill="currentColor"
        transform="translate(18.17 -26.41)"
        d="M-10.424 36.343c0-.368-.01-.736 0-1.1a1.089 1.089 0 0 1 1.1-1.094q1.105-.02 2.211 0A1.083 1.083 0 0 1-6 35.237c.015.747.016 1.494 0 2.241a1.077 1.077 0 0 1-1.083 1.075q-1.122.023-2.245 0a1.089 1.089 0 0 1-1.089-1.107c-.016-.367-.007-.735-.007-1.103z"
      />
      <path
        fill="currentColor"
        transform="translate(18.199 -108.15)"
        d="M-8.237 123.609c.368 0 .737-.009 1.105 0a1.089 1.089 0 0 1 1.1 1.1q.021 1.1 0 2.207a1.089 1.089 0 0 1-1.125 1.105q-1.088.016-2.176 0a1.089 1.089 0 0 1-1.116-1.115q-.018-1.086 0-2.172a1.1 1.1 0 0 1 1.143-1.124c.355-.01.713-.002 1.069-.001z"
      />
      <path
        fill="currentColor"
        transform="translate(18.205 -189.82)"
        d="M-8.25 217.427c-.368 0-.737.009-1.105 0a1.089 1.089 0 0 1-1.1-1.1q-.022-1.1 0-2.207a1.089 1.089 0 0 1 1.123-1.108q1.088-.017 2.176 0a1.089 1.089 0 0 1 1.119 1.112q.019 1.086 0 2.172a1.1 1.1 0 0 1-1.141 1.127c-.358.011-.715.005-1.072.004z"
      />
    </Box>
  );
}

ChangeListIcon.defaultProps = {
  sx: undefined,
};
