import React from "react";
import { LayerId } from "../../../../store/slices/layers/types";
import ControllerBase from "./ControllerBase";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectHeatmapSettingsById,
  selectLayerById,
} from "../../../../store/slices/layers/selectors";
import { updateHeatmapSettingsValue } from "../../../../store/slices/layers/slice";
import LabeledSlider from "../../../custom/LabeledSlider";

interface HeatmapControllerProps {
  layerId: LayerId;
}

export default function HeatmapController({
  layerId,
}: HeatmapControllerProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const layerHeatmapSettings = useAppSelector((state) =>
    selectHeatmapSettingsById(state, layerId)
  );
  const layerLabel = useAppSelector(
    (state) => selectLayerById(state, layerId).label
  );

  const handleUpdateIntensity = (newIntensity: number): void => {
    dispatch(
      updateHeatmapSettingsValue({
        id: layerId,
        value: newIntensity,
        name: "intensity",
      })
    );
  };

  const handleUpdateRadiusPixels = (newRadius: number): void => {
    dispatch(
      updateHeatmapSettingsValue({
        id: layerId,
        value: newRadius,
        name: "radiusPixels",
      })
    );
  };

  const handleUpdateThreshold = (newThreshold: number): void => {
    dispatch(
      updateHeatmapSettingsValue({
        id: layerId,
        value: newThreshold,
        name: "threshold",
      })
    );
  };

  return (
    <ControllerBase label={layerLabel}>
      {layerHeatmapSettings && (
        <>
          <LabeledSlider
            sx={{ width: 50 }}
            label="Intensität"
            value={layerHeatmapSettings.intensity}
            onChange={handleUpdateIntensity}
            max={5}
            min={0}
            step={0.1}
          />
          <LabeledSlider
            sx={{ width: 50 }}
            label="Radius"
            value={layerHeatmapSettings.radiusPixels}
            onChange={handleUpdateRadiusPixels}
            max={50}
            min={25}
            step={1}
          />
          <LabeledSlider
            sx={{ width: 50 }}
            label="Grenzwert"
            value={layerHeatmapSettings.threshold}
            onChange={handleUpdateThreshold}
            max={0.25}
            min={0.12}
            step={0.01}
          />
        </>
      )}
    </ControllerBase>
  );
}
