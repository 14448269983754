import { Box } from "@mui/material";
import { IconProps } from "./types";

export function BurgerIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 17, height: 13, ...sx }}
      component="svg"
      viewBox="0 0 17 13"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M1.251 0h14.467C16.42 0 17 .594 17 1.281c0 .719-.58 1.313-1.282 1.313H1.251C.58 2.594 0 2 0 1.28 0 .594.58 0 1.251 0zM1.251 5.219h14.467c.702 0 1.282.562 1.282 1.28 0 .72-.58 1.313-1.282 1.313H1.251C.58 7.812 0 7.22 0 6.5c0-.719.58-1.281 1.251-1.281zM1.251 10.437h14.467C16.42 10.437 17 11 17 11.72c0 .719-.58 1.281-1.282 1.281H1.251C.58 13 0 12.438 0 11.719c0-.719.58-1.282 1.251-1.282z" />
      </g>
    </Box>
  );
}

BurgerIcon.defaultProps = {
  sx: undefined,
};
