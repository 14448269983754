import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { IconButton, SelectChangeEvent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ApiContext from "../../tech/context/ApiContext";
import SelectInput from "../custom/SelectInput";
import BackdropContext from "../../tech/context/BackdropContext";
import Logger from "../../tech/utils/Logger";
import { Rule } from "../../api/versioning/types";
import {
  postCreateRule,
  putUpdateRule,
} from "../../api/versioning/versioning-api";
import { BaseDialog } from "./base/BaseDialog";

interface CreateOrEditRuleDialogProps {
  rule?: Rule;
}

export default function CreateOrEditRuleDialog({
  rule,
}: CreateOrEditRuleDialogProps): React.ReactElement {
  const { fetchDatabaseData, dataSources } = useContext(ApiContext);
  const { setBackdropActive } = useContext(BackdropContext);
  const [open, setOpen] = useState(false);
  const [dataSourcesCreatedBy, setDataSourcesCreatedBy] = useState<string[]>(
    []
  );
  const [action, setAction] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [type, setType] = useState<string>("");

  useEffect(() => {
    if (rule) {
      setAction(rule.action);
      setCreatedBy(rule.createdBy);
      setRegion(rule.region);
      setType(rule.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listOfCreatedBy = dataSources
      ? dataSources.map((dataSource) => dataSource.createdBy)
      : [];
    setDataSourcesCreatedBy(listOfCreatedBy);
  }, [dataSources]);

  const handleChangeAction = (event: SelectChangeEvent<string>): void => {
    setAction(String(event.target.value));
  };

  const handleChangeCreatedBy = (event: SelectChangeEvent<string>): void => {
    setCreatedBy(String(event.target.value));
  };

  const handleChangeRegion = (event: SelectChangeEvent<string>): void => {
    setRegion(String(event.target.value));
  };

  const handleChangeType = (event: SelectChangeEvent<string>): void => {
    setType(String(event.target.value));
  };

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const createOrUpdateRule = (): void => {
    setBackdropActive(true);
    if (rule) {
      putUpdateRule(rule.id, action, createdBy, region, type)
        .then(() => fetchDatabaseData())
        .then(() => {
          setBackdropActive(false);
          handleClose();
        })
        .catch((error) => {
          Logger.error(error);
        });
    } else {
      postCreateRule(action, createdBy, region, type)
        .then(() => fetchDatabaseData())
        .then(() => {
          setBackdropActive(false);
          handleClose();
        })
        .catch((error) => {
          Logger.error(error);
        });
    }
  };

  return (
    <>
      {rule ? (
        <IconButton aria-label="delete" onClick={handleClickOpen}>
          <EditIcon color="primary" />
        </IconButton>
      ) : (
        <Button
          sx={{
            py: (theme) => theme.spacing(1),
            px: (theme) => theme.spacing(4),
          }}
          size="small"
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Neue Regel erstellen
        </Button>
      )}
      <BaseDialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        title={rule === null ? "Neue Regel erstellen" : "Regel bearbeiten"}
        primaryButton={{
          label: rule ? "Speichern" : "Erstellen",
          onClick: createOrUpdateRule,
          disabled: !action || !createdBy || !region || !type,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SelectInput
            sx={{ width: "100%", mb: (theme) => theme.spacing(2) }}
            label="Aktion"
            value={action}
            menuItems={["Automatisch", "Manuell", "Nie hinzufügen"]}
            handleChange={handleChangeAction}
          />
          <SelectInput
            sx={{ width: "100%", mb: (theme) => theme.spacing(2) }}
            label="Quelle"
            value={createdBy}
            menuItems={dataSourcesCreatedBy}
            handleChange={handleChangeCreatedBy}
          />
          <SelectInput
            sx={{ width: "100%", mb: (theme) => theme.spacing(2) }}
            label="Region"
            value={region}
            menuItems={["Hessen"]}
            handleChange={handleChangeRegion}
          />
          <SelectInput
            sx={{ width: "100%" }}
            label="Kategorie"
            value={type}
            menuItems={["Verkehrsdaten", "Radwege", "Schnellstraßen"]}
            handleChange={handleChangeType}
          />
        </Box>
      </BaseDialog>
    </>
  );
}

CreateOrEditRuleDialog.defaultProps = {
  rule: undefined,
};
