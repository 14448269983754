import { Box } from "@mui/material";
import { IconProps } from "./types";

export function VersionsIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box sx={sx} component="svg" viewBox="0 0 37.722 41">
      <g fill="currentColor" transform="translate(-701.09 -272.41)">
        <path
          fill="currentColor"
          transform="translate(0 2.234)"
          d="m707.98 297.48a6.9 6.9 0 1 1 6.9-6.9 6.9 6.9 0 0 1-6.9 6.9zm0-10.2a3.3 3.3 0 1 0 3.3 3.3 3.3 3.3 0 0 0-3.3-3.297z"
        />
        <path
          fill="currentColor"
          transform="translate(3.955 2.234)"
          d="m727.96 297.48a6.9 6.9 0 1 1 6.9-6.9 6.9 6.9 0 0 1-6.9 6.9zm0-10.2a3.3 3.3 0 1 0 3.3 3.3 3.306 3.306 0 0 0-3.3-3.297z"
        />
        <path
          fill="currentColor"
          transform="translate(3.955)"
          d="m727.96 286.2a6.9 6.9 0 1 1 6.9-6.9 6.9 6.9 0 0 1-6.9 6.9zm0-10.2a3.3 3.3 0 1 0 3.3 3.3 3.307 3.307 0 0 0-3.3-3.3z"
        />
        <path
          fill="currentColor"
          transform="translate(3.955 4.497)"
          d="m727.96 308.91a6.9 6.9 0 1 1 6.9-6.9 6.9 6.9 0 0 1-6.9 6.9zm0-10.2a3.3 3.3 0 1 0 3.3 3.3 3.307 3.307 0 0 0-3.3-3.297z"
        />
        <rect
          fill="currentColor"
          transform="translate(713.53 291.02)"
          width="12.863"
          height="3.594"
        />
        <rect
          fill="currentColor"
          transform="matrix(.853 -.522 .522 .853 711.73 288.41)"
          width="16.844"
          height="3.594"
        />
        <rect
          fill="currentColor"
          transform="translate(711.73 297.26) rotate(-58.529)"
          width="3.594"
          height="16.844"
        />
      </g>
    </Box>
  );
}

VersionsIcon.defaultProps = {
  sx: undefined,
};
