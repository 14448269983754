import { shallowEqual } from "react-redux";
import { Box } from "@mui/material";
import LayerMenuButton from "./LayerMenuButton";
import LayerSubMenu from "./LayerSubMenu";
import { enableDebugMode } from "../../../api/AuthService";
import { useAppSelector } from "../../../store/hooks";
import {
  layerCategoriesExpanded,
  selectAllLayerCategories,
} from "../../../store/slices/layer-categories/slice";
import DebugLayerSubMenu from "./DebugLayerSubMenu";

export default function LayerMenu(): React.ReactElement {
  const expanded = useAppSelector(
    (state) => layerCategoriesExpanded(state),
    shallowEqual
  );

  const layerCategories = useAppSelector(
    (state) => selectAllLayerCategories(state),
    shallowEqual
  );

  return (
    <Box
      sx={{
        maxHeight: "95vh",
        pointerEvents: "all",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: (theme) => theme.spacing(1),
        overflowY: "auto",
        pb: (theme) => theme.spacing(2),
      }}
    >
      <LayerMenuButton />
      {expanded && (
        <>
          {layerCategories.map((layerCategory) => (
            <LayerSubMenu
              key={layerCategory.id}
              categoryId={layerCategory.id}
            />
          ))}
          {enableDebugMode() && <DebugLayerSubMenu />}
        </>
      )}
    </Box>
  );
}
