import { GroupedLayers } from "./types";

export function equalGroupedLayers(
  prevGroupedLayers: GroupedLayers,
  nextGroupedLayers: GroupedLayers
): boolean {
  const prevKeys = Object.keys(prevGroupedLayers);
  const nextKeys = new Set(Object.keys(prevGroupedLayers));
  return (
    prevKeys.length === nextKeys.size &&
    prevKeys.every(
      (prevKey) =>
        nextKeys.has(prevKey) &&
        prevGroupedLayers[Number(prevKey)].length ===
          nextGroupedLayers[Number(prevKey)].length
    )
  );
}
