import { Box } from "@mui/material";
import { IconProps } from "./types";

export function TrafficFlowDailyIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 30, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 30 16"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M19.848 10.813a.97.97 0 0 0-.863-.923l-3.742-.33-2.775-2.945A1.995 1.995 0 0 0 11.038 6H6.476c-.652 0-1.262.33-1.64.901L3.049 9.538l-2.166.11C.4 9.67 0 10.11 0 10.615v2.33c0 .55.42.989.946.989h1.85C2.88 15.077 3.806 16 4.92 16s2.04-.901 2.123-2.066h5.887C13.014 15.077 13.94 16 15.054 16c1.135 0 2.04-.901 2.123-2.066h1.85c.547 0 .968-.483.926-1.033l-.105-2.088zm-14.928 4c-.568 0-1.03-.483-1.03-1.077 0-.593.462-1.077 1.03-1.077.568 0 1.03.484 1.03 1.077 0 .594-.462 1.077-1.03 1.077zM7.948 9.43H4.94L6.055 7.78a.541.541 0 0 1 .42-.22h1.473V9.43zm1.492 0V7.56h1.598c.147 0 .274.066.358.154l1.619 1.715H9.44zm5.614 5.384c-.568 0-1.03-.483-1.03-1.077 0-.593.462-1.077 1.03-1.077.568 0 1.03.484 1.03 1.077 0 .594-.462 1.077-1.03 1.077zM25.727 9.658v1.266l-4.159.516V12.6l4.159.535v1.265c0 .268.172.517.42.624a.736.736 0 0 0 .782-.125l2.823-2.37c.153-.143.248-.321.248-.5a.675.675 0 0 0-.248-.498l-2.823-2.371a.774.774 0 0 0-.782-.125.647.647 0 0 0-.42.624z" />
        <g>
          <path d="M25.974.885C25.859.372 25.379 0 24.832 0a1.23 1.23 0 0 0-.246.025c-.63.129-1.033.724-.898 1.328.05.23.176.43.346.582l-.987 1.923a1.22 1.22 0 0 0-.458.09l-1.66-1.469c.093-.21.117-.44.066-.668-.114-.513-.595-.885-1.142-.885-.082 0-.165.008-.246.025-.63.129-1.033.725-.898 1.328.05.225.17.422.336.574l-.896 1.91a1.163 1.163 0 0 0-.96.51c-.17.25-.228.55-.163.842.115.513.595.885 1.143.885.082 0 .165-.008.245-.025.305-.062.567-.235.737-.486.17-.25.227-.55.162-.842a1.1 1.1 0 0 0-.317-.554l.907-1.932c.065-.003.13-.01.193-.022.06-.012.118-.03.175-.05l1.684 1.49c-.08.2-.1.417-.051.632.115.512.595.884 1.143.884a1.164 1.164 0 0 0 .982-.51 1.072 1.072 0 0 0-.14-1.382l1.01-1.969a1.23 1.23 0 0 0 .176-.021c.63-.13 1.034-.725.899-1.328z" />
        </g>
      </g>
    </Box>
  );
}

TrafficFlowDailyIcon.defaultProps = {
  sx: undefined,
};
