import { Box, Switch, SxProps, Theme, Typography } from "@mui/material";

interface LabeledSwitchProps {
  sx?: SxProps<Theme>;
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  description?: string;
}

interface LabeledSwitchContainerProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
}

function LabeledSwitchContainer({
  sx,
  children,
  onClick,
}: LabeledSwitchContainerProps): React.ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: (theme) => theme.spacing(1.5),
        "&:hover": {
          cursor: "pointer",
          color: (theme) => theme.palette.primary.main,
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}

LabeledSwitchContainer.defaultProps = {
  sx: undefined,
};

export default function LabeledSwitch({
  sx,
  label,
  checked,
  onChange,
  description,
}: LabeledSwitchProps): React.ReactElement {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onChange(!checked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(event.target.checked);
  };

  if (description) {
    return (
      <Box sx={{ display: "table", width: 0, ...sx }}>
        <LabeledSwitchContainer onClick={handleClick}>
          <Typography sx={{ fontWeight: 500 }} component="p" variant="body1">
            {label}
          </Typography>
          <Switch checked={checked} onChange={handleChange} />
        </LabeledSwitchContainer>
        <Typography component="p" variant="body2">
          {description}
        </Typography>
      </Box>
    );
  }

  return (
    <LabeledSwitchContainer sx={sx} onClick={handleClick}>
      <Typography sx={{ fontWeight: 500 }} component="p" variant="body1">
        {label}
      </Typography>
      <Switch checked={checked} onChange={handleChange} />
    </LabeledSwitchContainer>
  );
}

LabeledSwitch.defaultProps = {
  sx: undefined,
  description: undefined,
};
