import { Button, ButtonProps } from "@mui/material";
import BlkButton from "./BlkButton";
import { CustomButtonColor } from "./types";
import WhtButton from "./WhtButton";

interface CustomButtonProps extends Omit<ButtonProps, "color"> {
  color?: CustomButtonColor;
}

export default function CustomButton(
  props: CustomButtonProps
): React.ReactElement {
  const { children, color } = props;
  const reducedProps = { ...props, color: undefined, children: undefined };

  switch (color) {
    case "black":
      return (
        <BlkButton
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...reducedProps}
        >
          {children}
        </BlkButton>
      );
    case "white":
      return (
        <WhtButton
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...reducedProps}
        >
          {children}
        </WhtButton>
      );
    default:
      return (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...reducedProps}
          color={color}
        >
          {children}
        </Button>
      );
  }
}

CustomButton.defaultProps = {
  color: "primary",
};
