import { Box, Button, TextField } from "@mui/material";
import { add } from "date-fns";
import { useState } from "react";
import BlkButton from "../components/custom/buttons/BlkButton";
import WhtButton from "../components/custom/buttons/WhtButton";
import DateInput from "../components/custom/date-picker/DateInput";
import DatePicker from "../components/custom/date-picker/DatePicker";
import DateRangePicker from "../components/custom/date-picker/DateRangePicker";
import LabeledSlider from "../components/custom/LabeledSlider";
import LabeledSwitch from "../components/custom/LabeledSwitch";

const startDate = new Date();
const endDate = add(startDate, { days: 7 });

export default function StyleGuidePage(): React.ReactElement {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [sliderValue, setSliderValue] = useState(50);

  return (
    <Box
      sx={{
        width: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        p: (theme) => theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(4),
        backgroundColor: (theme) => theme.palette.wht.main,
      }}
    >
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <Button variant="contained">Contained</Button>
        <Button variant="outlined">Outlined</Button>
        <Button variant="text">Text</Button>
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
          backgroundColor: (theme) => theme.palette.grey[800],
        }}
      >
        <WhtButton variant="contained">Contained</WhtButton>
        <WhtButton variant="outlined">Outlined</WhtButton>
        <WhtButton variant="text">Text</WhtButton>
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <BlkButton variant="contained">Contained</BlkButton>
        <BlkButton variant="outlined">Outlined</BlkButton>
        <BlkButton variant="text">Text</BlkButton>
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <DatePicker
          date={startDate}
          onChange={() => {
            // do nothing
          }}
          variant="contained"
        />
        <DatePicker
          date={startDate}
          onChange={() => {
            // do nothing
          }}
          variant="outlined"
        />
        <DatePicker
          date={startDate}
          onChange={() => {
            // do nothing
          }}
          variant="text"
        />
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={() => {
            // do nothing
          }}
          variant="contained"
        />
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={() => {
            // do nothing
          }}
          variant="outlined"
        />
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={() => {
            // do nothing
          }}
          variant="text"
        />
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <TextField label="Outlined" variant="outlined" />
        <TextField label="Filled" variant="filled" />
        <TextField label="Standard" variant="standard" />
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <DateInput
          label="Outlined"
          value={startDate}
          onChange={() => {
            // do nothing
          }}
          variant="outlined"
        />
        <DateInput
          label="Filled"
          value={startDate}
          onChange={() => {
            // do nothing
          }}
          variant="filled"
        />
        <DateInput
          label="Standard"
          value={startDate}
          onChange={() => {
            // do nothing
          }}
          variant="standard"
        />
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <LabeledSwitch
          sx={{ width: 166 }}
          label="Labeled Switch"
          onChange={(checked) => setSwitchChecked(checked)}
          checked={switchChecked}
        />
        <LabeledSwitch
          sx={{ width: 166 }}
          label="Labeled Switch"
          description="With some useful description that can be very long, too"
          onChange={(checked) => setSwitchChecked(checked)}
          checked={switchChecked}
        />
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <LabeledSlider
          label="Labeled Slider"
          min={0}
          max={100}
          step={1}
          value={sliderValue}
          onChange={(value) => setSliderValue(value)}
        />
      </Box>
    </Box>
  );
}
