import { Box } from "@mui/material";
import { IconProps } from "./types";

export function SchoolsIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 26, height: 23, ...sx }}
      component="svg"
      viewBox="0 0 26 23"
    >
      <path
        d="M12.516.663c-.068.067-.339.196-.728.3-.388.103-.88.202-1.375.379-.495.176-1.006.436-1.403.899-.396.462-.639 1.12-.639 1.93 0 .209.116.4.302.498a.572.572 0 0 0 .586-.031c.293-.194.951-.414 1.606-.512.6-.089 1.193-.066 1.482 0v.538L6.41 7.607a.564.564 0 0 0-.31.511v3.737l-5.352 2.52a.564.564 0 0 0-.328.511v6.794a.57.57 0 1 0 1.136 0v-6.45L6.1 13.097v8.275H.988a.57.57 0 0 0-.576.564c0 .151.06.296.169.402a.57.57 0 0 0 .407.162h23.856a.566.566 0 0 0 .568-.564v-7.05a.564.564 0 0 0-.329-.511l-5.352-2.52V8.118a.564.564 0 0 0-.31-.511l-5.937-2.943V1.068a.562.562 0 0 0-.19-.426.57.57 0 0 0-.778.021zm-.169 1.313v1.022a5.463 5.463 0 0 0-1.641.01c-.334.049-.663.12-.977.21.047-.081.085-.18.142-.246.216-.252.54-.424.932-.564.393-.14.842-.236 1.278-.353.09-.023.178-.053.266-.079zm.568 3.675 5.68 2.82v12.901h-2.272v-4.758c0-1.757-1.556-3.138-3.408-3.138-1.851 0-3.408 1.38-3.408 3.138v4.758H7.235V8.471l5.68-2.82zm0 1.621c-1.405 0-2.555 1.144-2.555 2.538 0 1.395 1.15 2.538 2.555 2.538 1.405 0 2.556-1.143 2.556-2.538 0-1.394-1.15-2.538-2.556-2.538zm0 1.128c.791 0 1.42.625 1.42 1.41 0 .786-.629 1.41-1.42 1.41-.79 0-1.42-.624-1.42-1.41 0-.785.63-1.41 1.42-1.41zm6.816 4.697 4.544 2.133v6.142h-4.544v-8.275zm-6.816 1.507c1.296 0 2.272.916 2.272 2.01v4.758h-4.544v-4.758c0-1.094.977-2.01 2.272-2.01z"
        fill="currentColor"
        fillRule="nonzero"
        stroke="currentColor"
        strokeWidth=".25"
      />
    </Box>
  );
}

SchoolsIcon.defaultProps = {
  sx: undefined,
};
