import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { SxProps, Theme, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "./types";
import { formatTimestampToDate } from "../../tech/utils/TimestampFormatter";
import { getAutomatedDisplayName } from "../../tech/utils/DisplayNameHandler";
import { Commit } from "../../api/versioning/types";

interface MergedCommitsTableProps {
  sx?: SxProps<Theme>;
  commits: Commit[];
  disableActionButtons?: boolean;
  disablePagination?: boolean;
}

const columns: TableColumn[] = [
  { id: "title", label: "Titel", minWidth: 100, maxWidth: 250, align: "left" },
  {
    id: "description",
    label: "Beschreibung",
    minWidth: 100,
    maxWidth: 600,
    align: "left",
  },
  { id: "creation", label: "Einstelldatum", minWidth: 100, align: "left" },
  { id: "timeRange", label: "Zeitraum", minWidth: 50, align: "left" },
  {
    id: "type",
    label: "Kategorie",
    minWidth: 50,
    maxWidth: 200,
    align: "left",
  },
  { id: "createdBy", label: "Quelle", minWidth: 50, align: "left" },
  { id: "automated", label: "Hinzugefügt", minWidth: 100, align: "left" },
  { id: "approvedBy", label: "Abgenommen", minWidth: 100, align: "left" },
];

export default function MergedCommitsTable({
  sx,
  commits,
  disableActionButtons,
  disablePagination,
}: MergedCommitsTableProps): React.ReactElement {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [masterCommitsLength, setMasterCommitsLength] = useState(0);
  const [filteredCommits, setFilteredCommits] = useState<Commit[]>([]);

  useEffect(() => {
    const commitsWithProcessEnd: Commit[] = commits.filter(
      (commit) => !!commit.processEnd
    );
    setFilteredCommits(commitsWithProcessEnd);
    setMasterCommitsLength(commitsWithProcessEnd.length);
  }, [commits]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: 1,
        borderColor: (theme) => theme.palette.grey[300],
        ...sx,
      }}
    >
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column: TableColumn) => (
                <TableCell
                  style={{ minWidth: column.minWidth, width: column.maxWidth }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
              {!disableActionButtons && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommits && filteredCommits.length > 0 ? (
              filteredCommits
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((commit) => (
                  <TableRow key={commit.id}>
                    <TableCell>{commit.title}</TableCell>
                    <TableCell>{commit.description}</TableCell>
                    <TableCell>
                      {formatTimestampToDate(commit.createdAt)}
                    </TableCell>
                    <TableCell>
                      {`${formatTimestampToDate(
                        commit.startsAt
                      )} - ${formatTimestampToDate(commit.endsAt)}`}
                    </TableCell>
                    <TableCell>{commit.type}</TableCell>
                    <TableCell>{commit.createdBy}</TableCell>
                    <TableCell>
                      {getAutomatedDisplayName(commit.automated)}
                    </TableCell>
                    <TableCell>{commit.approvedBy}</TableCell>
                    {!disableActionButtons && (
                      <TableCell>
                        <Button
                          sx={{
                            py: (theme) => theme.spacing(1),
                            px: (theme) => theme.spacing(4),
                          }}
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => navigate(`/changes/${commit.id}`)}
                        >
                          Details anzeigen
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: "center" }}
                  colSpan={
                    disableActionButtons ? columns.length : columns.length + 1
                  }
                >
                  Zur Zeit gibt es keine Einträge in der Änderungshistorie.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            py: (theme) => theme.spacing(1),
            px: (theme) => theme.spacing(2),
            borderTop: 1,
            borderColor: (theme) => theme.palette.grey[200],
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={masterCommitsLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}

MergedCommitsTable.defaultProps = {
  sx: undefined,
  disableActionButtons: false,
  disablePagination: false,
};
