import React from "react";
import { Box, CircularProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { RequestStatus } from "../../../../store/types";

interface LayerSubMenuItemStatusIndicatorProps {
  requestStatus: RequestStatus;
}

function LayerSubMenuItemStatusIndicator({
  requestStatus,
}: LayerSubMenuItemStatusIndicatorProps): React.ReactElement {
  if (requestStatus.status === "loading") {
    return (
      <Box
        sx={{
          width: 28,
          minWidth: 28,
          height: 28,
          minHeight: 28,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size="24px" thickness={4} />
      </Box>
    );
  }

  if (requestStatus.status === "failed") {
    return (
      <Box
        sx={{
          width: 28,
          minWidth: 28,
          height: 28,
          minHeight: 28,
        }}
      >
        <ErrorIcon color="error" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: 28,
        minWidth: 28,
        height: 28,
        minHeight: 28,
      }}
    />
  );
}

export default React.memo(LayerSubMenuItemStatusIndicator);
