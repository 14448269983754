import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ImportIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 17, height: 18, ...sx }}
      component="svg"
      viewBox="0 0 17 18"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M.654 14.332v1.333c0 .61.22 1.189.609 1.619.415.46.982.716 1.57.716h11.985c.59 0 1.155-.251 1.57-.716A2.42 2.42 0 0 0 17 15.665V2.335c0-.61-.22-1.189-.609-1.619A2.113 2.113 0 0 0 14.821 0H2.833c-.59 0-1.154.251-1.57.716a2.435 2.435 0 0 0-.608 1.619v1.333c0 .552.44.998.982.998a.987.987 0 0 0 .98-.998V2.335c0-.1.03-.198.093-.267.033-.038.078-.068.127-.068h11.986c.048 0 .09.026.127.068a.393.393 0 0 1 .093.267v13.33c0 .1-.03.198-.093.267-.034.038-.079.068-.127.068H2.832c-.048 0-.09-.026-.127-.068a.393.393 0 0 1-.093-.267v-1.333a.99.99 0 0 0-.98-.998.99.99 0 0 0-.978.998z" />
        <path d="M9.483 8.002H.979A.99.99 0 0 0 0 9c0 .548.44.998.979.998h8.5A.99.99 0 0 0 10.458 9a.989.989 0 0 0-.975-.998z" />
        <path d="m8.212 12.37 2.616-2.665a1.009 1.009 0 0 0 0-1.413L8.212 5.625a.963.963 0 0 0-1.386 0 1.009 1.009 0 0 0 0 1.414l1.92 1.957-1.92 1.958a1.009 1.009 0 0 0 0 1.413.96.96 0 0 0 1.386.004z" />
      </g>
    </Box>
  );
}

ImportIcon.defaultProps = {
  sx: undefined,
};
