export function getAutomatedDisplayName(automated: boolean): string {
  return automated ? "Automatisch" : "Manuell";
}

export function getDataLabelDisplayName(label: string): string {
  switch (label) {
    case "timestamp":
      return "Zeitstempel";
    case "occupancy":
      return "Belegung der Messstrecke [ % ]";
    case "vehicle_flow_rate":
      return "Verkehrsfluss [Fahrzeuge / h]";
    default:
      return "Nicht verfügbar";
  }
}

export function getTooltipKeyDisplayName(key: string): string {
  switch (key) {
    case "mdm_id":
      return "MDM-ID";
    case "description":
      return "Beschreibung";
    case "school_name":
      return "Schulname";
    case "school_number":
      return "Schulnummer";
    case "post_code":
      return "Postleitzahl";
    case "school_location":
      return "Schulort";
    case "address":
      return "Adresse";
    case "total_of_students_with_pre_classes":
      return "Anzahl Schüler";
    case "district":
      return "Gemeinde";
    case "phone_code":
      return "Telefonvorwahl";
    case "phone_number":
      return "Telefonnummer";
    case "fax":
      return "Fax";
    case "email":
      return "E-Mail";
    case "lanes":
      return "Anzahl Spuren";
    case "capacity":
      return "Kapazität";
    case "maxspeed":
      return "Max. Geschwindigkeit";
    case "road_type":
      return "OSM-Straßenart";
    case "highway":
      return "Autobahn";
    case "main_road":
      return "Hauptstraße";
    case "residential_road":
      return "Anwohnerstraße";
    case "side_road":
      return "Nebenstraße";
    case "service_road":
      return "Erschließungsweg";
    case "school":
      return "Schule";
    case "culture_spot":
      return "Kulturstelle";
    case "community_boundarie":
      return "Gemeindegrenze";
    case "cycle_lanes":
      return "Fahrradweg";
    case "population":
      return "Bevölkerungszahl";
    default:
      return key;
  }
}
