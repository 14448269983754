import { Box, SxProps, Theme } from "@mui/material";

interface SeparatorProps {
  sx?: SxProps<Theme>;
}

export default function Separator({ sx }: SeparatorProps): React.ReactElement {
  return (
    <Box
      sx={{
        height: "1px",
        mx: (theme) => theme.spacing(2),
        my: (theme) => theme.spacing(1),
        backgroundColor: (theme) => theme.palette.grey[500],
        ...sx,
      }}
    />
  );
}

Separator.defaultProps = {
  sx: undefined,
};
