import { Box } from "@mui/material";
import { IconProps } from "./types";

export function CalendarIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 21, height: 19, ...sx }}
      width="21"
      height="19"
      component="svg"
      viewBox="0 0 21 19"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M17.973 1.775h-1.115v-.121C16.858.746 16.078 0 15.13 0h-1.284c-.947 0-1.726.746-1.726 1.654v.121h-3.2v-.121C8.921.746 8.142 0 7.195 0H5.91c-.947 0-1.726.746-1.726 1.654v.121H3.047C1.637 1.775.5 2.885.5 4.215V16.56C.5 17.911 1.637 19 3.048 19h14.904c1.411 0 2.548-1.09 2.548-2.44V4.215c.02-1.352-1.116-2.441-2.527-2.441zm-4.357-.121c0-.121.105-.222.231-.222h1.284c.127 0 .232.101.232.222v1.735a.229.229 0 0 1-.232.221h-1.284a.229.229 0 0 1-.231-.221V1.654zm-7.937 0c0-.121.105-.222.231-.222h1.285c.126 0 .231.101.231.222v1.735a.229.229 0 0 1-.231.221H5.91a.229.229 0 0 1-.231-.221V1.654zm13.263 14.905c0 .505-.421.928-.969.928H3.068c-.526 0-.968-.423-.968-.928V7.1h16.842v9.46z" />
        <path d="M4.626 11.477h1.916c.358 0 .653-.283.653-.626V9.44c0-.343-.295-.626-.653-.626H4.626c-.358 0-.652.283-.652.626v1.411a.657.657 0 0 0 .652.626zM14.5 11.477h1.916c.357 0 .652-.283.652-.626V9.44c0-.343-.295-.626-.652-.626H14.5c-.358 0-.653.283-.653.626v1.411c0 .343.295.626.653.626zM9.574 11.477h1.915c.358 0 .653-.283.653-.626V9.44c0-.343-.295-.626-.653-.626H9.574c-.358 0-.653.283-.653.626v1.411c0 .343.295.626.653.626zM4.626 15.813h1.916c.358 0 .653-.282.653-.625v-1.412c0-.343-.295-.625-.653-.625H4.626c-.358 0-.652.282-.652.625v1.412a.657.657 0 0 0 .652.625zM14.5 15.813h1.916c.357 0 .652-.282.652-.625v-1.412c0-.343-.295-.625-.652-.625H14.5c-.358 0-.653.282-.653.625v1.412c0 .343.295.625.653.625zM9.574 15.813h1.915c.358 0 .653-.282.653-.625v-1.412c0-.343-.295-.625-.653-.625H9.574c-.358 0-.653.282-.653.625v1.412c0 .343.295.625.653.625z" />
      </g>
    </Box>
  );
}

CalendarIcon.defaultProps = {
  sx: undefined,
};
