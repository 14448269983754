import React from "react";
import { Grid, Typography } from "@mui/material";
import BasicLayout from "../../components/layout/BaseLayout";

export default function LicensesPage(): React.ReactElement {
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1">
            Open-Source Lizenzen
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h2">
            OpenStreetMap
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(2) }}
            component="p"
            variant="body1"
          >
            Das von uns verwendete Kartenmaterial basiert auf
            OpenStreetMap-Daten, die unter Einahltung der{" "}
            <a
              href="https://opendatacommons.org/licenses/odbl/1-0/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Data Commons Open Database Lizenz (ODbL)
            </a>{" "}
            verwendet werden dürfen.
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(4) }}
            component="h2"
            variant="h2"
          >
            Mobilitäts Daten Marktplatz
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(2) }}
            component="p"
            variant="body1"
          >
            Wir beziehen Daten ausgewählter Anbieter über den Mobilitäts Daten
            Marktplatz (MDM), die unter Einhaltung der{" "}
            <a
              href="https://www.govdata.de/dl-de/by-2-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenlizenz Deutschland -Namensnennung- Version 2.0
            </a>{" "}
            verwendet werden dürfen.
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(1) }}
            component="p"
            variant="body1"
          >
            Zu unseren verwendeten MDM-Subskriptionen gehören:
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(1) }}
            component="p"
            variant="body1"
          >
            Statische Verkehrsdaten SOAP der Stadt Frankfurt am Main{" "}
            <a
              href="https://service.mdm-portal.de/mdm-portal-application/publDetail.do?publicationId=2386000"
              target="_blank"
              rel="noopener noreferrer"
            >
              (ID: 3724000)
            </a>
          </Typography>
          <Typography component="p" variant="body1">
            Dynamische Verkehrsdaten SOAP der Stadt Frankfurt am Main{" "}
            <a
              href="https://service.mdm-portal.de/mdm-portal-application/publDetail.do?publicationId=2386001"
              target="_blank"
              rel="noopener noreferrer"
            >
              (ID: 3724001)
            </a>
          </Typography>
          <Typography component="p" variant="body1">
            Verkehrsmeldungen SOAP der Stadt Frankfurt am Main{" "}
            <a
              href="https://service.mdm-portal.de/mdm-portal-application/publDetail.do?publicationId=2380000"
              target="_blank"
              rel="noopener noreferrer"
            >
              (ID: 3817007)
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h2">
            Statistik der Bundesagentur für Arbeit
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(2) }}
            component="p"
            variant="body1"
          >
            Für die Darstellung von Pendlerbewegungen greifen wir auf die{" "}
            <a
              href="https://statistik.arbeitsagentur.de/DE/Navigation/Statistiken/Interaktive-Angebote/Pendleratlas/Pendleratlas-Nav.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pendlerstatistiken der Bundesagentur für Arbeit
            </a>{" "}
            zurück.
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(4) }}
            component="h2"
            variant="h2"
          >
            Weitere TBD...
          </Typography>
          <Typography
            sx={{ mt: (theme) => theme.spacing(2) }}
            component="p"
            variant="body1"
          >
            TBA...
          </Typography>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}
