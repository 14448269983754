import React, { useContext } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import BackdropContext from "../../tech/context/BackdropContext";

export default function BackdropSpinner(): React.ReactElement {
  const { isBackdropActive } = useContext(BackdropContext);

  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.wht.main,
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
      open={isBackdropActive}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
