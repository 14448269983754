import { Box } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import {
  DebugLayerCategoryId,
  LayerCategoryId,
} from "../../../store/slices/layer-categories/types";
import { selectGroupedLayersByCategoryId } from "../../../store/slices/layers/selectors";
import { equalGroupedLayers } from "../../../store/slices/layers/utils";
import LayerSubMenuItem from "./LayerSubMenuItem/LayerSubMenuItem";

interface LayerSubMenuItemsProps {
  categoryId: LayerCategoryId | DebugLayerCategoryId;
}

export default function LayerSubMenuItems({
  categoryId,
}: LayerSubMenuItemsProps): React.ReactElement {
  const groupedLayers = useAppSelector(
    (state) => selectGroupedLayersByCategoryId(state, categoryId),
    equalGroupedLayers
  );

  const groupCount = Object.keys(groupedLayers).length;

  return (
    <Box sx={{ py: (theme) => theme.spacing(1) }}>
      {Object.values(groupedLayers).map((layers, groupIndex) => {
        const isLastGroup = groupIndex === groupCount - 1;
        return layers.map((layer, layerIndex) => {
          const isLastLayerInGroup = layerIndex === layers.length - 1;
          return (
            <LayerSubMenuItem
              key={layer.id}
              layerId={layer.id}
              addSeparator={isLastLayerInGroup && !isLastGroup}
            />
          );
        });
      })}
    </Box>
  );
}
