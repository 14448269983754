import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { MapConfigId } from "../../store/slices/maps/types";
import Logger from "../../tech/utils/Logger";
import { BaseDialog } from "./base/BaseDialog";

interface SaveMapLayoutDialogProps {
  mapConfigId: MapConfigId;
  open: boolean;
  onClose: React.MouseEventHandler;
}

export default function SaveMapLayoutDialog({
  mapConfigId,
  open,
  onClose,
}: SaveMapLayoutDialogProps): React.ReactElement {
  const [name, setName] = useState("");
  const [makePublic, setMakePublic] = useState(false);

  useEffect(() => {
    if (open) {
      setName("");
      setMakePublic(false);
    }
  }, [open]);

  const handleChangeName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setName(event.target.value);
  };

  const handleChangeMakePublic = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setMakePublic(event.target.checked);
  };

  const handleSaveLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. MapConfigId = ", mapConfigId);
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Ansicht speichern?"
      primaryButton={{
        label: "Ansicht speichern",
        onClick: handleSaveLayout,
      }}
    >
      <Typography component="p" variant="body1">
        Wählen Sie, wo die Ansicht gespeichert werden soll.
      </Typography>
      <Box
        sx={{
          pt: (theme) => theme.spacing(1),
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          sx={{
            minWidth: "70%",
            mx: (theme) => theme.spacing(8),
            my: (theme) => theme.spacing(1),
          }}
          label="Name der Ansicht"
          variant="standard"
          value={name}
          onChange={handleChangeName}
        />
      </Box>
      <FormControlLabel
        sx={{
          mt: (theme) => theme.spacing(2),
          px: (theme) => theme.spacing(4),
        }}
        control={
          <Checkbox
            name="Ansicht speichern"
            value={makePublic}
            onChange={handleChangeMakePublic}
          />
        }
        label="Auch in Freigegebene Ansichten speichern"
      />
    </BaseDialog>
  );
}
