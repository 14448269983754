import React, { useContext, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ApiContext from "../../tech/context/ApiContext";
import ExportBranchesTable from "../../components/table/ExportBranchesTable";
import BackdropContext from "../../tech/context/BackdropContext";
import BasicLayout from "../../components/layout/BaseLayout";
import Logger from "../../tech/utils/Logger";
import { getBranchExportById } from "../../api/versioning/versioning-api";
import { Branch } from "../../api/versioning/types";

export default function ExportPage(): React.ReactElement {
  const { branches } = useContext(ApiContext);
  const { setBackdropActive } = useContext(BackdropContext);
  const [branch, setBranch] = useState<Branch | undefined>(branches[0]);

  const exportOSM = (): void => {
    setBackdropActive(true);
    if (branch !== undefined) {
      getBranchExportById(branch.id, `branch-${branch.name}.osm`)
        .then(() => setBackdropActive(false))
        .catch((error) => {
          Logger.error(error);
        });
    } else {
      setBackdropActive(false);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1">
            Verfügbare Branch-Exports
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ExportBranchesTable
            branch={branch}
            setBranch={setBranch}
            branches={branches}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          item
          xs={12}
        >
          <Button
            sx={{
              py: (theme) => theme.spacing(1),
              px: (theme) => theme.spacing(4),
            }}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => exportOSM()}
          >
            Auswahl exportieren
          </Button>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}
