import React from "react";
import {
  SxProps,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface SelectInputProps {
  sx?: SxProps<Theme>;
  label: string;
  value: string;
  menuItems: string[];
  handleChange: (event: SelectChangeEvent<string>) => void;
}

export default function SelectInput({
  sx,
  label,
  value,
  menuItems,
  handleChange,
}: SelectInputProps): React.ReactElement {
  return (
    <FormControl variant="outlined" sx={sx}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} onChange={handleChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {menuItems.map((item, index) => {
          const key = `select-item-${index}`;
          return (
            <MenuItem key={key} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectInput.defaultProps = {
  sx: undefined,
};
