import { Box } from "@mui/material";
import { IconProps } from "./types";

export function LocationNotCenteredIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 21, height: 21, ...sx }}
      component="svg"
      viewBox="0 0 21 21"
    >
      <path
        d="M19.615 9.548h-1.586a7.604 7.604 0 0 0-6.581-6.581V1.38A.38.38 0 0 0 11.067 1H9.926a.38.38 0 0 0-.381.381v1.586a7.604 7.604 0 0 0-6.582 6.581H1.381A.38.38 0 0 0 1 9.93v1.14c0 .21.168.382.381.382h1.586a7.604 7.604 0 0 0 6.581 6.581v1.586c0 .209.169.381.382.381h1.14a.38.38 0 0 0 .382-.381v-1.586a7.604 7.604 0 0 0 6.581-6.581h1.586A.38.38 0 0 0 20 11.07V9.93a.384.384 0 0 0-.385-.382zm-9.117 6.65a5.707 5.707 0 0 1-5.699-5.7A5.707 5.707 0 0 1 10.498 4.8a5.707 5.707 0 0 1 5.699 5.7 5.707 5.707 0 0 1-5.699 5.698z"
        stroke="currentColor"
        strokeWidth=".4"
      />
    </Box>
  );
}

LocationNotCenteredIcon.defaultProps = {
  sx: undefined,
};
