import { Box } from "@mui/material";
import { IconProps } from "./types";

export function TazIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 23, height: 22, ...sx }}
      component="svg"
      viewBox="0 0 23 22"
    >
      <path
        d="M22.003 11.878 17.197 9.13V3.634a.686.686 0 0 0-.347-.595L11.697.092a.7.7 0 0 0-.694 0L5.85 3.039a.686.686 0 0 0-.347.595V9.13L.697 11.878a.686.686 0 0 0-.347.595v5.893c0 .246.132.473.347.595l5.153 2.947a.7.7 0 0 0 .694 0l4.806-2.748 4.805 2.748a.698.698 0 0 0 .695 0l5.153-2.947a.686.686 0 0 0 .347-.595v-5.893a.688.688 0 0 0-.347-.595zM11.35 1.48l4.459 2.55V9.13l-4.459 2.55-4.458-2.55v-5.1l4.458-2.55zM6.197 20.519l-4.459-2.55v-5.1l4.46-2.549 4.458 2.55v5.099l-4.459 2.55zm14.765-2.55-4.46 2.55-4.458-2.55v-5.1l4.459-2.549 4.459 2.55v5.099z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

TazIcon.defaultProps = {
  sx: undefined,
};
