import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import {
  HeatmapData,
  FlowData,
  MarkerData,
  GeoJsonLayerProperties,
  HeatmapDataInterval,
} from "./types";

export async function getMarkerData(
  id: string
): Promise<AxiosResponse<MarkerData>> {
  return AxiosClient.get(`/core/markers/?id=${id}`);
}

export async function getHeatmapData(
  dataset: string,
  attrib: string,
  interval: HeatmapDataInterval,
  fromMs: number,
  toMs: number
): Promise<AxiosResponse<HeatmapData>> {
  return AxiosClient.get(
    `/core/heatmaps/?from_ms=${fromMs}&to_ms=${toMs}&attrib=${attrib}&dataset=${dataset}&interval=${interval}`
  );
}

export async function getGeoJsonData(
  dataCategoryId: string
): Promise<AxiosResponse<ArrayBuffer>> {
  return AxiosClient.get(
    `/core/geo-jsons/?data_category_id=${dataCategoryId}`,
    {
      responseType: "arraybuffer",
    }
  );
}

export async function getGeoJsonProperties(
  dataCategoryId: string
): Promise<AxiosResponse<GeoJsonLayerProperties>> {
  return AxiosClient.get(
    `/core/geo-jsons/properties/?data_category_id=${dataCategoryId}`
  );
}

export async function getTazesGeoJsonData(): Promise<
  AxiosResponse<ArrayBuffer>
> {
  return AxiosClient.get(`/core/traffic-cells/geo-jsons`, {
    responseType: "arraybuffer",
  });
}

export async function getFlowData(
  dataset: string,
  flowCount: number,
  fromMs: number,
  toMs: number
): Promise<AxiosResponse<FlowData>> {
  return AxiosClient.get(
    `/core/flows/?from_ms=${fromMs}&to_ms=${toMs}&dataset=${dataset}&flow_count=${flowCount}`
  );
}
