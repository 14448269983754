import React from "react";
import { ButtonGroup, SxProps, Theme } from "@mui/material";
import { MapConfigId } from "../../../../store/slices/maps/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  decreaseZoom,
  increaseZoom,
  resetZoom,
  selectMapConfigById,
} from "../../../../store/slices/maps/slice";
import {
  calculateZoomPercentage,
  equalMapConfigZoom,
} from "../../../../store/slices/maps/utils";
import WhtButton from "../../../custom/buttons/WhtButton";
import BlkButton from "../../../custom/buttons/BlkButton";

interface ZoomControlsProps {
  sx?: SxProps<Theme>;
  mapConfigId: MapConfigId;
}

export default function ZoomControls({
  sx,
  mapConfigId,
}: ZoomControlsProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const mapConfig = useAppSelector(
    (state) => selectMapConfigById(state, mapConfigId),
    equalMapConfigZoom
  );

  const handleResetZoomLevel = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(resetZoom(mapConfig.id));
  };

  const handleIncreaseZoomLevel = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(increaseZoom(mapConfig.id));
  };

  const handleDecreaseZoomLevel = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(decreaseZoom(mapConfig.id));
  };

  return (
    <ButtonGroup
      sx={{ width: 40, ...sx }}
      orientation="vertical"
      variant="contained"
    >
      <WhtButton
        sx={{
          height: 36,
          fontWeight: "bold",
          fontSize: 21,
        }}
        variant="contained"
        onClick={handleIncreaseZoomLevel}
      >
        +
      </WhtButton>
      <BlkButton
        sx={{ height: 30, fontSize: 10 }}
        variant="contained"
        onClick={handleResetZoomLevel}
      >
        {`${calculateZoomPercentage(mapConfig)}%`}
      </BlkButton>
      <WhtButton
        sx={{ height: 36, fontWeight: "bold", fontSize: 21 }}
        variant="contained"
        onClick={handleDecreaseZoomLevel}
      >
        -
      </WhtButton>
    </ButtonGroup>
  );
}

ZoomControls.defaultProps = {
  sx: undefined,
};
