/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";
import { Theme, SxProps } from "@mui/material";
import { MarkerData } from "../../api/layers/types";
import DeckGLMap from "./DeckGLMap";
import ClusterIconLayer from "./layer/ClusterIconLayer";
import Logger from "../../tech/utils/Logger";
import { Commit } from "../../api/versioning/types";

interface CommitDetailsMapProps {
  sx?: SxProps<Theme>;
  commit: Commit;
}

export default function CommitDetailsMap({
  sx,
  commit,
}: CommitDetailsMapProps): React.ReactElement {
  const clusterIconLayer = useRef<ClusterIconLayer>();

  useEffect(() => {
    const markerData: MarkerData = { markers: [] };
    if (commit) {
      markerData.markers.push({
        id: commit.id,
        lat: commit.latitude,
        lon: commit.longitude,
        properties: {
          name: commit.title,
        },
      });
    }
    if (!clusterIconLayer.current) {
      clusterIconLayer.current = new ClusterIconLayer({
        id: "details-marker-layer",
        data: markerData,
        onLeafIconClick: (d: any) => {
          Logger.debug(d);
        },
      });
    } else {
      // clusterIconLayer.current?.updateLayerData(markerData);
    }
    /*
    TODO: implement
    setViewState((previousViewState: any) => ({
      ...previousViewState,
      longitude: commit.longitude,
      latitude: commit.latitude,
    }));
    */
  }, [commit]);

  return (
    <DeckGLMap
      sx={{ width: "100%", height: "50vh", ...sx }}
      // layers={[clusterIconLayer.current?.render() as any]}
      mapConfigId="minimal"
    />
  );
}

CommitDetailsMap.defaultProps = {
  sx: undefined,
};
