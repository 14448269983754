import React from "react";
import { Box } from "@mui/material";
import { Layer } from "../../../../store/slices/layers/types";
import IconFactory from "../../../icons/IconFactory";

interface LayerSubMenuItemIconProps {
  layer: Layer;
}

function LayerSubMenuItemIcon({
  layer,
}: LayerSubMenuItemIconProps): React.ReactElement {
  return (
    <Box
      sx={{
        width: 38,
        minWidth: 38,
        height: 38,
        minHeight: 38,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        color: (theme) =>
          layer.selected ? theme.palette.wht.main : theme.palette.blk.main,
        backgroundColor: (theme) =>
          layer.selected ? theme.palette.primary.main : theme.palette.wht.main,
      }}
    >
      {IconFactory.createIcon(layer.iconId)}
    </Box>
  );
}

export default React.memo(LayerSubMenuItemIcon);
