import React from "react";
import { Typography } from "@mui/material";
import Content from "../layout/Content";

export default function Forbidden(): React.ReactElement {
  return (
    <Content>
      <Typography component="h1" variant="h1">
        Du bist nicht berechtigt auf diese Ressource zuzugreifen!
      </Typography>
    </Content>
  );
}
