import React from "react";
import { shallowEqual } from "react-redux";
import { Button } from "@mui/material";
import { EditIcon } from "../../../icons/EditIcon";
import { LayerId } from "../../../../store/slices/layers/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { toggleEditingLayerById } from "../../../../store/slices/layers/slice";
import { selectLayerById } from "../../../../store/slices/layers/selectors";

interface LayerSubMenuItemEditButtonProps {
  layerId: LayerId;
}

function LayerSubMenuItemEditButton({
  layerId,
}: LayerSubMenuItemEditButtonProps): React.ReactElement {
  const dispatch = useAppDispatch();

  const editing = useAppSelector(
    (state) => selectLayerById(state, layerId)?.editing || false,
    shallowEqual
  );

  const handleClickEdit = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(toggleEditingLayerById(layerId));
  };

  return (
    <Button
      sx={{
        width: 28,
        minWidth: 28,
        height: 28,
        minHeight: 28,
        p: (theme) => theme.spacing(1),
        boxShadow: 0,
        border: editing ? "none" : 1,
        color: (theme) => theme.palette.blk.main,
        backgroundColor: (theme) =>
          editing ? theme.palette.primary.light : theme.palette.wht.main,
        borderColor: (theme) => theme.palette.primary.light,
        "&:hover": {
          boxShadow: 0,
          backgroundColor: (theme) => theme.palette.primary.main,
          borderColor: (theme) => theme.palette.primary.main,
        },
      }}
      variant="contained"
      onClick={handleClickEdit}
    >
      <EditIcon sx={{ width: 14, height: 14 }} />
    </Button>
  );
}

export default React.memo(LayerSubMenuItemEditButton);
