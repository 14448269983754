import React from "react";
import DatePicker from "react-datepicker";
import CustomPickerHeader from "./CustomPickerHeader";
import "./react-datepicker.css";

interface InlineDatePickerProps {
  startDate: Date | null;
  endDate?: Date | null;
  onChange: (
    dates: [Date | null, Date | null],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
}

export default function InlineDatePicker({
  startDate,
  endDate,
  onChange,
}: InlineDatePickerProps): React.ReactElement {
  return (
    <DatePicker
      selected={startDate}
      startDate={startDate}
      endDate={endDate === undefined ? startDate : endDate}
      onChange={onChange}
      focusSelectedMonth={false}
      disabledKeyboardNavigation
      monthsShown={2}
      selectsRange
      inline
      renderCustomHeader={({
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <CustomPickerHeader
          monthDate={monthDate}
          customHeaderCount={customHeaderCount}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
    />
  );
}

InlineDatePicker.defaultProps = {
  endDate: undefined,
};
