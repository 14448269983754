import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ChevronAllClosedIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 18, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 18 16"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="m9.737 8.633 6.197-6.545A1 1 0 0 0 15.208.4H2.814a1 1 0 0 0-.726 1.688l6.197 6.545a1 1 0 0 0 1.452 0z" />
        <path d="m9.737 14.244 6.931-7.321a.54.54 0 0 0-.392-.912h-.827a1 1 0 0 0-.725.311l-4.987 5.253a1 1 0 0 1-1.451 0L3.318 6.322a1 1 0 0 0-.727-.312h-.835a.545.545 0 0 0-.396.919l6.925 7.314a1 1 0 0 0 1.452 0z" />
      </g>
    </Box>
  );
}

ChevronAllClosedIcon.defaultProps = {
  sx: undefined,
};
