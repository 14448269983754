import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import theme from "./theme";
import { initKeycloak } from "./api/AuthService";
import Logger from "./tech/utils/Logger";
import { store } from "./store/store";

const container = document.getElementById("root") as HTMLDivElement;

const root = ReactDOMClient.createRoot(container);

const renderApp = (): void =>
  root.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </ReduxProvider>
    </React.StrictMode>
  );

initKeycloak(renderApp);

if (process.env.NODE_ENV === "production") {
  reportWebVitals(Logger.info);
}
