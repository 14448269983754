import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import React from "react";

export interface ActionButtonProps {
  label: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
}

interface BaseDialogActionsProps {
  primaryButton?: ActionButtonProps;
  secondaryButton?: ActionButtonProps;
  onCancel: React.MouseEventHandler;
  hideCancelButton?: boolean;
}

export function BaseDialogActions({
  primaryButton,
  secondaryButton,
  onCancel,
  hideCancelButton,
}: BaseDialogActionsProps): React.ReactElement {
  return (
    <DialogActions sx={{ px: (theme) => theme.spacing(2) }}>
      {!hideCancelButton && (
        <Button variant="text" onClick={onCancel}>
          Abbrechen
        </Button>
      )}
      {secondaryButton && (
        <LoadingButton
          variant="text"
          onClick={secondaryButton.onClick}
          disabled={secondaryButton.disabled}
        >
          {secondaryButton.label}
        </LoadingButton>
      )}
      {primaryButton && (
        <LoadingButton
          variant="text"
          onClick={primaryButton.onClick}
          disabled={primaryButton.disabled}
        >
          {primaryButton.label}
        </LoadingButton>
      )}
    </DialogActions>
  );
}

BaseDialogActions.defaultProps = {
  primaryButton: undefined,
  secondaryButton: undefined,
  hideCancelButton: false,
};
