import { Typography } from "@mui/material";
import { MapConfigId } from "../../store/slices/maps/types";
import Logger from "../../tech/utils/Logger";
import { BaseDialog } from "./base/BaseDialog";

interface ResetMapLayoutDialogProps {
  mapConfigId: MapConfigId;
  open: boolean;
  onClose: React.MouseEventHandler;
}

export default function ResetMapLayoutDialog({
  mapConfigId,
  open,
  onClose,
}: ResetMapLayoutDialogProps): React.ReactElement {
  const handleSaveLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. MapConfigId = ", mapConfigId);
  };

  const handleResetLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    Logger.info("Not implemented. MapConfigId = ", mapConfigId);
  };

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Ansicht zurücksetzen?"
      primaryButton={{
        label: "Ansicht speichern",
        onClick: handleSaveLayout,
      }}
      secondaryButton={{
        label: "Zurücksetzen",
        onClick: handleResetLayout,
      }}
    >
      <Typography component="p" variant="body1">
        All Ihre Einstellungen gehen mit dem Reset verloren. Speichern Sie Ihre
        aktuelle Ansicht, um später wieder auf Sie zurückgreifen zu können.
      </Typography>
    </BaseDialog>
  );
}
