/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { shallowEqual } from "react-redux";
import { Theme, Box, SxProps } from "@mui/material";
import DeckGL from "@deck.gl/react";
import { MapView } from "@deck.gl/core";
import { ViewStateProps } from "@deck.gl/core/lib/deck";
import { MapConfigId, ViewStateUpdate } from "../../store/slices/maps/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectMapConfigById,
  updateViewState,
} from "../../store/slices/maps/slice";
import {
  selectLayerDataByIds,
  selectAllSelectedLayersByMapConfigId,
} from "../../store/slices/layers/selectors";
import BaseContributions from "./contributions/BaseContributions";
import {
  createRenderedLayers,
  createTileLayer,
} from "../../store/slices/layers/factory";
import Logger from "../../tech/utils/Logger";
import HoverTooltip from "./tooltip/HoverTooltip";

interface DeckGLMapProps {
  sx?: SxProps<Theme>;
  additionalContributions?: React.ReactNode;
  selfManagedLayers?: any[];
  mapConfigId: MapConfigId;
}

interface ViewStateChangeEvent {
  viewState: ViewStateProps;
  oldViewState: ViewStateProps;
  interactionState: {
    inTransition?: boolean;
    isDragging?: boolean;
    isPanning?: boolean;
    isRotating?: boolean;
    isZooming?: boolean;
  };
}

export default function DeckGLMap({
  sx,
  additionalContributions,
  selfManagedLayers,
  mapConfigId,
}: DeckGLMapProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const mapConfig = useAppSelector((state) =>
    selectMapConfigById(state, mapConfigId)
  );
  const selectedLayers = useAppSelector(
    (state) => selectAllSelectedLayersByMapConfigId(state, mapConfigId),
    shallowEqual
  );
  const selectedLayerData = useAppSelector(
    (state) =>
      selectLayerDataByIds(
        state,
        Object.values(selectedLayers).map((layer) => layer.id)
      ),
    shallowEqual
  );

  const handleChangeViewState = (event: ViewStateChangeEvent): void => {
    const viewStateUpdate: ViewStateUpdate = {
      id: mapConfigId,
      viewState: {
        ...event.viewState,
        transitionEasing: undefined,
        transitionInterpolator: undefined,
      },
    };
    dispatch(updateViewState(viewStateUpdate));
  };

  const handleDeckGLError = (error: Error, source: any) => {
    Logger.error(`Error occured at source:`, source);
    Logger.error(error);
    if (
      error.message &&
      error.message.toLowerCase() === "webgl context is lost"
    ) {
      Logger.info("WebGL context lost. Reloading page!");
      window.location.reload();
    }
  };

  const tileLayer = createTileLayer(mapConfig?.usePublicOsmTiles);
  const renderedLayers = createRenderedLayers(
    selectedLayers,
    selectedLayerData
  );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        backgroundColor: (theme) => theme.palette.grey[200],
        ...sx,
      }}
    >
      {mapConfig?.tooltipData && (
        <HoverTooltip
          sx={{ zIndex: (theme) => theme.zIndex.modal - 1 }}
          data={mapConfig.tooltipData}
        />
      )}
      {mapConfig && (
        <DeckGL
          views={[new MapView({ repeat: true })]}
          viewState={mapConfig.viewState}
          onViewStateChange={handleChangeViewState}
          useDevicePixels={false}
          controller
          layers={[tileLayer, ...renderedLayers, ...(selfManagedLayers || [])]}
          onError={handleDeckGLError}
        >
          <BaseContributions
            additionalContributions={additionalContributions}
          />
        </DeckGL>
      )}
    </Box>
  );
}

DeckGLMap.defaultProps = {
  sx: undefined,
  additionalContributions: undefined,
  selfManagedLayers: [],
};
