import { Box } from "@mui/material";
import { IconProps } from "./types";

export function DeleteIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 18, height: 20, ...sx }}
      component="svg"
      viewBox="0 0 18 20"
    >
      <path
        d="M2.84 20c-.45 0-.843-.167-1.18-.5-.338-.333-.507-.722-.507-1.167V2.5H0V.833h5.287V0h7.426v.833H18V2.5h-1.153v15.833c0 .445-.169.834-.506 1.167-.338.333-.732.5-1.182.5H2.841zM15.16 2.5H2.84v15.833h12.32V2.5zM5.821 15.944h1.687V4.861H5.822v11.083zm4.669 0h1.687V4.861h-1.687v11.083zM2.84 2.5v15.833V2.5z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

DeleteIcon.defaultProps = {
  sx: undefined,
};
