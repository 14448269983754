/* eslint-disable @typescript-eslint/no-explicit-any */
import { BitmapLayer, TileLayer } from "deck.gl";
import { TileLayerProps } from "@deck.gl/geo-layers/tile-layer/tile-layer";
import { WmsTileDataFunction } from "./types";

interface WmsLayerProps {
  id: string;
  getTileData?: WmsTileDataFunction;
  onClick?: (clickData: any) => void;
}

export default class WmsLayer {
  private readonly props;

  private getTileData?: WmsTileDataFunction;

  constructor(props: WmsLayerProps) {
    this.props = props;
    this.getTileData = props.getTileData;
  }

  render = (visible?: boolean): TileLayer<any, TileLayerProps<any>> => {
    const { id, onClick } = this.props;
    return new TileLayer({
      id,
      visible: visible || false,
      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,
      pickable: true,
      getTileData: this.getTileData,
      onClick,
      renderSubLayers: (props) => {
        const {
          bbox: { west, south, east, north },
        } = props.tile;
        return new BitmapLayer(props, {
          data: null,
          image: Array.isArray(props.data) ? props.data[0] : props.data,
          bounds: [west, south, east, north],
        });
      },
    });
  };
}
