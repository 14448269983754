import { Box } from "@mui/material";
import { IconProps } from "./types";

export function MagnifyingGlassIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 17, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 17 16"
    >
      <path
        d="M7.144 0c-3.74 0-6.8 3.058-6.8 6.8 0 3.74 3.06 6.799 6.8 6.799 1.44 0 2.78-.448 3.881-1.219l3.269 3.269a1.204 1.204 0 0 0 1.7 0 1.196 1.196 0 0 0 0-1.694l-3.275-3.275a6.756 6.756 0 0 0 1.225-3.88c0-3.742-3.059-6.8-6.8-6.8zm0 2.4c2.444 0 4.4 1.955 4.4 4.4 0 2.444-1.956 4.4-4.4 4.4a4.382 4.382 0 0 1-4.4-4.4c0-2.445 1.956-4.4 4.4-4.4z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

MagnifyingGlassIcon.defaultProps = {
  sx: undefined,
};
