import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ChevronOpenIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 15, height: 9, ...sx }}
      component="svg"
      viewBox="0 0 15 9"
    >
      <path
        d="M6.795.378.598 6.923a1 1 0 0 0 .726 1.688h12.394a1 1 0 0 0 .726-1.688L8.247.378a1 1 0 0 0-1.452 0z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Box>
  );
}

ChevronOpenIcon.defaultProps = {
  sx: undefined,
};
