import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectMapConfigById,
  updateMapStyle,
} from "../../../store/slices/maps/slice";
import { MapConfigId, MapStyle } from "../../../store/slices/maps/types";
import { equalMapConfigStyle } from "../../../store/slices/maps/utils";
import StyleSelectorButton from "./StyleSelectorButton";

interface StyleSelectorProps {
  mapConfigId: MapConfigId;
}

export default function StyleSelector({
  mapConfigId,
}: StyleSelectorProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const mapConfig = useAppSelector(
    (state) => selectMapConfigById(state, mapConfigId),
    equalMapConfigStyle
  );

  const handleUpdateStyle = (
    event: React.MouseEvent<HTMLButtonElement>,
    newStyle: MapStyle
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(updateMapStyle({ id: mapConfigId, style: newStyle }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: (theme) => theme.spacing(0.25),
      }}
    >
      <StyleSelectorButton
        label="1"
        backgroundImageSrc={`${process.env.PUBLIC_URL}/imgs/map-style-1.jpg`}
        active={mapConfig.style === "1"}
        onClick={(event) => handleUpdateStyle(event, "1")}
      />
      <StyleSelectorButton
        label="2"
        backgroundImageSrc={`${process.env.PUBLIC_URL}/imgs/map-style-2.jpg`}
        active={mapConfig.style === "2"}
        onClick={(event) => handleUpdateStyle(event, "2")}
      />
      <StyleSelectorButton
        label="3"
        backgroundImageSrc={`${process.env.PUBLIC_URL}/imgs/map-style-3.jpg`}
        active={mapConfig.style === "3"}
        onClick={(event) => handleUpdateStyle(event, "3")}
      />
    </Box>
  );
}
