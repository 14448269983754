import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import Logger from "../../../tech/utils/Logger";

const Input = styled("input")({
  display: "none",
});

interface FileUploadButtonProps {
  file: File | undefined;
  setFile: (value: React.SetStateAction<File | undefined>) => void;
}

export default function FileUploadButton({
  file,
  setFile,
}: FileUploadButtonProps): React.ReactElement {
  const getFileFromInput = (
    inputFile: File
  ): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result);
      reader.readAsBinaryString(inputFile);
    });

  const manageUploadedFile = (binary: string, inputFile: File): void => {
    setFile(inputFile);
    Logger.debug(`The file '${inputFile.name}' has size ${binary.length}`);
  };

  const handleFileChange = (fileList: FileList | null): void => {
    if (fileList)
      Array.from(fileList).forEach((fileFromList) => {
        getFileFromInput(fileFromList)
          .then((binary) => {
            if (binary && typeof binary === "string") {
              manageUploadedFile(binary, fileFromList);
            }
          })
          .catch((error) => {
            Logger.error(error);
          });
      });
  };

  return (
    <div>
      <Input
        accept=".osc,.gz"
        id="file"
        type="file"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleFileChange(event.target.files)
        }
      />
      <label htmlFor="file">
        <Button
          variant="contained"
          component="span"
          fullWidth
          color={file ? "secondary" : "primary"}
        >
          {file ? `Datei > ${file.name} < ausgewählt` : "OSC Datei auswählen"}
        </Button>
      </label>
    </div>
  );
}
