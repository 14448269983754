import { Box } from "@mui/material";
import { IconProps } from "./types";

export function ChevronAllOpenIcon({ sx }: IconProps): React.ReactElement {
  return (
    <Box
      sx={{ width: 18, height: 16, ...sx }}
      component="svg"
      viewBox="0 0 18 16"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="m8.285 6.778-6.197 6.545a1 1 0 0 0 .726 1.688h12.394a1 1 0 0 0 .726-1.688L9.737 6.778a1 1 0 0 0-1.452 0z" />
        <path d="m8.285 1.167-6.932 7.32a.54.54 0 0 0 .393.913h.827a1 1 0 0 0 .725-.312l4.986-5.253a1 1 0 0 1 1.452.001l4.968 5.251a1 1 0 0 0 .727.313h.835a.545.545 0 0 0 .396-.92L9.737 1.168a1 1 0 0 0-1.452 0z" />
      </g>
    </Box>
  );
}

ChevronAllOpenIcon.defaultProps = {
  sx: undefined,
};
