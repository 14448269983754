/* eslint-disable @typescript-eslint/no-explicit-any */
import { HeatmapLayer as DeckGLHeatmapLayer } from "@deck.gl/aggregation-layers";
import { HeatmapLayerProps as DeckGLHeatmapLayerProps } from "@deck.gl/aggregation-layers/heatmap-layer/heatmap-layer";
import { Heatmap, HeatmapData } from "../../../api/layers/types";
import { HeatmapSettings } from "./types";

interface HeatmapLayerProps {
  id: string;
  data?: HeatmapData;
  dataIndex?: number;
}

const EMPTY_DATA: HeatmapData = {
  heatmaps: [{ data: [] }],
};

export const defaultHeatmapSettings: HeatmapSettings = {
  radiusPixels: 30,
  intensity: 1,
  threshold: 0.05,
};

export default class HeatmapLayer {
  private readonly props;

  private renderData?: HeatmapData;

  private dataIndex: number;

  constructor(props: HeatmapLayerProps) {
    this.props = props;
    this.dataIndex = 0;
    this.renderData = props.data || EMPTY_DATA;
    this.setDataIndex(props.dataIndex || 0);
  }

  private setDataIndex = (dataIndex: number | undefined): void => {
    const newIndex = dataIndex || 0;
    const maxIndex = this.renderData?.heatmaps
      ? this.renderData.heatmaps.length - 1
      : 0;
    if (newIndex < 0) {
      this.dataIndex = 0;
    } else if (newIndex > maxIndex) {
      this.dataIndex = maxIndex;
    } else {
      this.dataIndex = newIndex;
    }
  };

  render = (
    heatmapSettings: HeatmapSettings | null,
    visible?: boolean
  ): DeckGLHeatmapLayer<any, DeckGLHeatmapLayerProps<any>> => {
    const { id } = this.props;
    const { radiusPixels, intensity, threshold } =
      heatmapSettings || defaultHeatmapSettings;

    const currentHeatmap: Heatmap = { data: [] };
    const heatmaps = this.renderData?.heatmaps;
    if (heatmaps && heatmaps.length > 0) {
      currentHeatmap.data = heatmaps[this.dataIndex].data;
    }

    return new DeckGLHeatmapLayer({
      id,
      data: currentHeatmap.data,
      visible: visible || false,
      opacity: 0.4,
      pickable: false,
      getPosition: (dataEntry: number[]) => [dataEntry[0], dataEntry[1]],
      getWeight: (dataEntry: number[]) => dataEntry[2],
      radiusPixels,
      intensity,
      threshold,
    });
  };
}
